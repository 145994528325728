<template>
    <div>
        <unicom-linha espacamento v-if="isColaborador">
            <unicom-coluna>
                <h3 class="fonte-primaria titulo negrito">
                    DADOS PROFISSIONAIS <span class="texto">(BASE DE PRESTADORES)</span>
                </h3>
            </unicom-coluna>
        </unicom-linha>
        <unicom-linha espacamento class="linha-conteudo" v-if="isColaborador">
            <unicom-coluna :md="4">
                <unicom-autocomplete
                    v-model="dadosUsuario.tipoColaborador.descricao"
                    placeholder="Insira o cargo do colaborador"
                    titulo="Cargo do usuário"
                    textoDoItem="descricao"
                    :items="listaCargo"
                    :usarTamanhoAutomatico="true"
                    :desativado="disableFields()"
                    @input="el => setIdTipoColaborador(el)"
                />
            </unicom-coluna>
            <unicom-coluna :md="4">
                <unicom-autocomplete
                    v-model="dadosUsuario.conselhoProfissional.estado"
                    titulo="UF do conselho"
                    placeholder=""
                    :items="listaUf"
                    :usarTamanhoAutomatico="true"
                    :desativado="disableFields()"
                />
            </unicom-coluna>
            <unicom-coluna :md="4">
                <unicom-campo-texto
                    v-model="dadosUsuario.conselhoProfissional.numeroConselho"
                    :titulo="tituloConselhoProfissional"
                    :placeholder="''"
                    :desativado="disableFields()"
                />
            </unicom-coluna>
        </unicom-linha>

        <unicom-linha espacamento>
            <unicom-coluna>
                <h3 class="fonte-primaria titulo negrito">PERFIL DO USUÁRIO</h3>
            </unicom-coluna>
        </unicom-linha>

        <unicom-linha espacamento class="linha-conteudo" v-for="(unimed, index) in dadosUsuario.unimeds" :key="index">
            <unicom-coluna class="cartao">
                <unicom-linha espacamento :justificarItem="'end'" class="primeira-linha">
                    <unicom-coluna :md="1">
                        <small
                            :class="`fonte-secundaria texto ${removerDesabilitado}`"
                            @click="removeUnimedPerfil(unimed, index)"
                            v-if="!disableFields(unimed)"
                        >
                            remover
                        </small>
                    </unicom-coluna>
                </unicom-linha>

                <unicom-linha :espacamento="false" class="linha-conteudo">
                    <unicom-coluna>
                        <unicom-autocomplete
                            v-model="unimed.unimed.codigoNome"
                            :valorData="unimed.unimed.codigoNome"
                            titulo="Unimed do usuário"
                            textoDoItem="codigoNome"
                            :items="listaUnimedsDisponiveis"
                            :desativado="!unimed.nova"
                        />
                    </unicom-coluna>
                </unicom-linha>

                <unicom-linha :espacamento="false">
                    <unicom-coluna>
                        <p class="fonte-secundaria texto">Perfil</p>
                    </unicom-coluna>

                    <RadioButton
                        label="Administrador"
                        :valorRadio="'Administrador'"
                        :name="'tipoPerfil_' + index"
                        :value="tipoPerfilSelecionado[index]"
                        :disabled="!isColaborador || disableFields()"
                        @change="el => atualizaTipoPerfil(el, index)"
                    ></RadioButton>

                    <RadioButton
                        label="Colaborador"
                        :valorRadio="'Colaborador'"
                        :name="'tipoPerfil_' + index"
                        :value="tipoPerfilSelecionado[index]"
                        :disabled="disableFields()"
                        @change="el => atualizaTipoPerfil(el, index)"
                    ></RadioButton>
                </unicom-linha>
            </unicom-coluna>
        </unicom-linha>

        <unicom-linha espacamento class="linha-conteudo">
            <span
                class="fonte-secundaria texto"
                @click="adicionarUnimed()"
                v-if="sessaoUsuario.usuarioUnimed.perfil.id === -1"
                >+ Adicionar outra unimed</span
            >
        </unicom-linha>

        <unicom-modal :value="loading" :largura="320">
            <div slot="body">
                <unicom-loading v-model="loading" style="margin-top:25px;" :mensagem="mensagemLoading" />
            </div>
        </unicom-modal>

        <unicom-modal-sucesso v-model="modalSucesso" :titulo="mensagemSucesso" @fechar="modalSucesso = false" />
    </div>
</template>

<script>
import { sortBy } from "lodash";

import UsuarioUnimedServiceMixin from "../../../../mixins/services/usuarioUnimed/UsuarioUnimedServiceMixin";

import RadioButton from "../../../shared/radioButton/RadioButton";

export default {
    mixins: [UsuarioUnimedServiceMixin],
    components: {
        RadioButton
    },
    props: {
        value: {
            type: Object,
            required: true
        },
        isColaborador: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data() {
        return {
            usuarioLogado: this.getSession(),
            dadosUsuario: this.value,
            listaUnimeds: [],
            listaUnimedsDisponiveis: [],
            listaUnimedsUsuario: [],
            administrador: false,
            colaborador: false,
            unimed: "",
            tituloConselhoProfissional: "Outros (Conselho Profissional)",

            listaCargo: [],
            listaUf: [],

            tipoPerfilSelecionado: [],

            mensagemLoading: "",
            loading: false,

            mensagemSucesso: "",
            modalSucesso: false,
            sessaoUsuario: this.getSession()
        };
    },
    beforeMount() {
        this.getCargoUsuario();
        this.getEstadosConselho();

        this.listaUnimeds = this.getUnimedsList();
        this.listaUnimedsUsuario = this.dadosUsuario.unimeds.map(el => el.unimed);

        this.updateUnimedDisponiveis();
        this.setTipoPerfil();

        this.dadosUsuario.unimeds = this.unimedsSort();

        if (this.isColaborador) this.tituloConselhoProfissional = this.nomeConselhoProfissional();
    },
    methods: {
        updateUnimedDisponiveis() {
            this.listaUnimedsDisponiveis = this.listaUnimeds.filter(
                filter => !this.listaUnimedsUsuario.some(some => some.codigo === filter.codigo)
            );
        },

        adicionarUnimed() {
            this.dadosUsuario.unimeds.push({ unimed: { codigoNome: "" }, perfil: { nome: "" }, nova: true });
            this.atualizaTipoPerfil("Colaborador", this.dadosUsuario.unimeds.length - 1);
            this.setTipoPerfil();
        },

        removeUnimedPerfil(unimed, index) {
            if (this.dadosUsuario.unimeds.length > 1) {
                this.loading = true;
                this.mensagemLoading = "Removendo unimed do usuário...";

                const idUsuario = this.dadosUsuario.id;
                const codigoUnimed = unimed.unimed.id;

                if (!codigoUnimed) {
                    const indexUnimed = this.listaUnimedsUsuario.findIndex(
                        unimed => unimed.nome == this.dadosUsuario.unimeds[index].unimed.nome
                    );

                    this.listaUnimedsUsuario.splice(indexUnimed, 1);
                    this.dadosUsuario.unimeds.splice(index, 1);
                    this.loading = false;
                    this.setTipoPerfil();
                } else
                    this.deleteUnimedUsuario(idUsuario, codigoUnimed)
                        .then(() => {
                            const indexUnimed = this.listaUnimedsUsuario.findIndex(
                                unimed => unimed.nome == this.dadosUsuario.unimeds[index].unimed.nome
                            );

                            this.listaUnimedsUsuario.splice(indexUnimed, 1);
                            this.dadosUsuario.unimeds.splice(index, 1);

                            this.modalSucesso = true;
                            this.mensagemSucesso = "Unimed removida do usuário com sucesso!";
                        })
                        .finally(() => {
                            this.loading = false;
                            this.updateUnimedDisponiveis();
                            this.setTipoPerfil();
                        });

                this.dadosUsuario.unimeds = this.unimedsSort();
            }
        },

        unimedsSort() {
            const sort = sortBy(this.dadosUsuario.unimeds, [i => i.unimed.codigo]);

            return sort;
        },

        disableFields() {
            return this.usuarioLogado.usuarioUnimed.perfil.id !== -1;
        },

        setTipoPerfil() {
            this.dadosUsuario.unimeds.forEach((it, i) => {
                this.tipoPerfilSelecionado[i] =
                    it.perfil.id === 1 || it.perfil.id === -1 ? "Administrador" : "Colaborador";
            });
        },

        setIdTipoColaborador(el) {
            const cargo = this.listaCargo.find(it => it.descricao == el);

            this.dadosUsuario.tipoColaborador.codigo = cargo.codigo;

            this.dadosUsuario.conselhoProfissional.tipoConselhoProfissional.codigo =
                cargo.tipoConselhoProfissionalResponse.codigo;

            this.dadosUsuario.conselhoProfissional.tipoConselhoProfissional.descricao =
                cargo.tipoConselhoProfissionalResponse.descricao;

            this.tituloConselhoProfissional = this.nomeConselhoProfissional();
        },

        atualizaTipoPerfil(newValue, idx) {
            if (newValue === "Colaborador") {
                this.dadosUsuario.unimeds[idx].perfil.id = 2;
                this.dadosUsuario.unimeds[idx].perfil.nome = "Colaborador";
            } else {
                this.dadosUsuario.unimeds[idx].perfil.id = 1;
                this.dadosUsuario.unimeds[idx].perfil.nome = "Admin";
            }
        },

        nomeConselhoProfissional() {
            const conselho = this.dadosUsuario.conselhoProfissional.tipoConselhoProfissional.descricao;

            if (conselho == "OUTROS") return conselho;

            return conselho + " (Conselho Profissional)";
        },

        getCargoUsuario() {
            this.getTiposColaboradores().then(({ data }) => (this.listaCargo = data));
        },
        getEstadosConselho() {
            this.getTodosEstados()
                .then(({ data }) => (this.listaUf = data))
                .catch(() => (this.listaUf = []));
        }
    },
    watch: {
        value() {
            this.dadosUsuario = this.value;
            this.$emit("input", this.dadosUsuario);
        }
    },
    computed: {
        removerDesabilitado() {
            return this.dadosUsuario.unimeds.length <= 1 ? "remover-desabilitado" : "";
        }
    }
};
</script>

<style lang="scss" scoped>
h3 {
    margin-left: 12px;
    color: #00995d;

    span {
        color: #60656c;
    }
}

.linha-conteudo {
    margin: 24px 0;

    .cartao {
        width: 100%;
        height: 175px;
        border: 1px solid #dee2e6;
        border-radius: 4px;
        background-color: #f1f2f1;

        .primeira-linha {
            padding-top: 8px;

            small {
                color: #1492e6;
                cursor: pointer;

                &.remover-desabilitado {
                    color: #707070;
                    cursor: default;
                }
            }

            & ~ .linha-conteudo {
                margin-top: 0;
            }
        }
    }

    span {
        color: #1492e6;
        cursor: pointer;
    }
}
</style>
