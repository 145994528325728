<template>
    <label class="radio" :style="cursor(disabled)">
        <span class="checkmark">
            <input :disabled="disabled" type="radio" :value="valorRadio" :name="name" v-model="radioButtonValue" />
            <div class="form-icon"></div>
        </span>

        <span class="checkbox-texto fonte-secundaria texto">
            <div style="display: flex;">
                {{ label }}
            </div>
        </span>
    </label>
</template>

<script>
export default {
    props: {
        value: { required: true },
        valorRadio: { type: String, required: false, default: "" },
        name: { type: String, required: true },
        label: { type: String, required: true },
        disabled: { type: Boolean, required: false, default: false }
    },
    methods: {
        cursor(disabled) {
            return disabled ? { cursor: "not-allowed !important" } : { cursor: "pointer" };
        }
    },
    computed: {
        radioButtonValue: {
            get: function() {
                return this.value;
            },
            set: function() {
                this.$emit("change", this.label);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.radio {
    position: relative;
    display: block;
    margin: 0;
    padding: 0.1rem 0.2rem 0.1rem 0.2rem;
    min-height: 1.4rem;
    line-height: 1.2rem;

    margin-top: 5px;
    margin-right: 5%;

    input {
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        position: absolute;
        width: 1px;

        &:checked + .form-icon {
            border-color: #00995d;

            &:after {
                content: "";
                display: none;
                position: absolute;
                top: 5px;
                left: 5px;
                width: 9px;
                height: 9px;
                background: #00995d;
            }
        }
    }

    .form-icon {
        background: #fff;
        height: 19px;
        width: 19px;
        left: 0;
        top: 0.3rem;
        border: 2px solid #ebebeb;
        border-radius: 4px;
        display: inline-block;
        border-radius: 50%;
        position: absolute;
        transition: background 0.2s, border 0.2s, box-shadow 0.2s, color 0.2s;
    }

    .checkmark {
        display: inline-block;
        margin-right: 6px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border-color: (--va);

        input:checked ~ .form-icon:after {
            transition: display 0.2s;
            display: block;
            border-radius: 50%;
        }
    }

    .checkbox-texto {
        position: relative;
        display: inline-block;
        padding: 6px 8px;
        vertical-align: middle;
        color: #60656c;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }
}
</style>
