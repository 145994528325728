<template>
    <div>
        <ValidationObserver ref="buscaUsuario">
            <unicom-linha :espacamento="true" class="linha-cadastro primeira-linha">
                <unicom-coluna :md="4" style="padding-top: 24px">
                    <unicom-checkbox v-model="estrangeiro" texto="Usuário estrangeiro" unico />
                </unicom-coluna>

                <unicom-coluna :md="4">
                    <validation-provider rules="cpf|required" mode="eager" v-slot="{ errors }" v-if="!estrangeiro">
                        <unicom-campo-texto
                            :mask="['###.###.###-##']"
                            apenasNumero
                            v-model="usuario.documento.numeroDocumento"
                            :titulo="'CPF *'"
                            :placeholder="'Insira o CPF'"
                            :mensagemErro="errors[0]"
                        />
                    </validation-provider>

                    <validation-provider rules="required" mode="eager" v-slot="{ errors }" v-else>
                        <unicom-campo-texto
                            v-model="usuario.documento.numeroDocumento"
                            :mask="['A######-A']"
                            :titulo="'RNE *'"
                            :placeholder="'Insira o RNE'"
                            :mensagemErro="errors[0]"
                        />
                    </validation-provider>
                </unicom-coluna>

                <unicom-coluna :md="4">
                    <unicom-botao
                        :tipo="'primario'"
                        bloco
                        contornado
                        class="fonte-secundaria texto botao"
                        @click="buscarUsuario()"
                    >
                        <div class="botao-interno">
                            <unicom-linha :espacamento="false" :justificarItem="'center'">
                                <unicom-coluna :md="2">
                                    <unicom-icone :nome="'search'" :tamanho="18" />
                                </unicom-coluna>
                                <unicom-coluna :md="10">
                                    <p>{{ campoBotao }}</p>
                                </unicom-coluna>
                            </unicom-linha>
                        </div>
                    </unicom-botao>
                </unicom-coluna>
            </unicom-linha>
        </ValidationObserver>

        <unicom-loading v-if="loading" v-model="loading" style="margin-top:65px;" mensagem="Por favor aguarde..." />

        <div v-else>
            <ValidationObserver ref="dadosBasicos">
                <unicom-linha :espacamento="true" class="linha-cadastro">
                    <unicom-coluna :md="8">
                        <validation-provider rules="required|validacaoNomeSobrenome" mode="eager" v-slot="{ errors }">
                            <unicom-campo-texto
                                v-model="usuario.nome"
                                apenasTexto
                                :titulo="'Nome completo *'"
                                :placeholder="'Insira o nome do colaborador'"
                                :desativado="camposBloqueados"
                                :mensagemErro="errors[0]"
                                :maxCaracteres="'50'"
                            />
                        </validation-provider>
                    </unicom-coluna>
                    <unicom-coluna :md="4">
                        <unicom-campo-calendario
                            posicao="bottom"
                            tituloCampo="Data de Nascimento *"
                            v-model="usuario.dataNascimento"
                            regrasValidacao="validacaoDataPassada|required"
                            :desativado="camposBloqueados"
                            placeHolder="Insira a data"
                        />
                    </unicom-coluna>
                </unicom-linha>
                <unicom-linha :espacamento="true" class="linha-cadastro">
                    <unicom-coluna :md="8">
                        <validation-provider rules="email|required" mode="eager" v-slot="{ errors }">
                            <unicom-campo-texto
                                v-model="usuario.email"
                                :titulo="'Email *'"
                                :placeholder="'Insira o email'"
                                :desativado="camposBloqueados"
                                :mensagemErro="errors[0]"
                                :maxCaracteres="'60'"
                            />
                        </validation-provider>
                    </unicom-coluna>
                    <unicom-coluna :md="4">
                        <validation-provider rules="required|telRequired" mode="eager" v-slot="{ errors }">
                            <unicom-campo-texto
                                v-model="usuario.telefone"
                                apenasNumero
                                :mask="['(##) ####-####', '(##) #####-####']"
                                :titulo="'Telefone *'"
                                :placeholder="'Insira o telefone'"
                                :desativado="camposBloqueados"
                                :mensagemErro="errors[0]"
                            />
                        </validation-provider>
                    </unicom-coluna>
                </unicom-linha>
                <unicom-linha :espacamento="true" class="linha-cadastro">
                    <unicom-coluna>
                        <p class="fonte-secundaria texto">Gênero do usuário *</p>
                    </unicom-coluna>
                    <unicom-coluna :md="4">
                        <unicom-checkbox
                            v-model="masculino"
                            :texto="'Masculino'"
                            :arredondado="true"
                            :inativo="camposBloqueados"
                        />
                    </unicom-coluna>
                    <unicom-coluna :md="4">
                        <unicom-checkbox
                            v-model="feminino"
                            :texto="'Feminino'"
                            :arredondado="true"
                            :inativo="camposBloqueados"
                        />
                    </unicom-coluna>
                </unicom-linha>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
// SERVICES
import PrestadorServiceMixin from "../services/PrestadorServiceMixin";

import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    mixins: [PrestadorServiceMixin],
    data() {
        return {
            loading: false,
            estrangeiro: false,
            camposBloqueados: true,
            usuario: {
                documento: { numeroDocumento: "", tipoDocumento: "CPF" },
                nome: "",
                dataNascimento: "",
                email: "",
                telefone: "",
                genero: ""
            },

            masculino: false,
            feminino: false
        };
    },
    computed: {
        campoBotao() {
            return this.loading ? "BUSCANDO..." : "BUSCAR USUÁRIO";
        }
    },
    methods: {
        getColaborador(documento) {
            this.loading = true;
            this.getPrestador(documento)
                .then(({ data }) => {
                    let prestador = {};
                    let validou = false;

                    if (data) {
                        const dados = data;

                        this.usuario.genero = dados.sexo;
                        dados.sexo === "M"
                            ? ((this.masculino = true), (this.feminino = false))
                            : ((this.feminino = true), (this.masculino = false));

                        this.usuario.nome = dados.nome;
                        this.usuario.dataNascimento = dados.dataNascimento ? dados.dataNascimento : "";
                        this.usuario.email = dados.email;
                        this.usuario.telefone = dados.telefone;

                        prestador = {
                            conselhoProfissional: {
                                numeroConselho: dados.conselhoProfissionalResponse.numeroConselho,
                                siglaEstado: dados.conselhoProfissionalResponse.estado,
                                tipoConselho: dados.conselhoProfissionalResponse.tipoConselhoProfissional.descricao
                            },
                            tipoColaborador: dados.tipoColaboradorResponse.descricao,
                            tipoColaboradorModel: dados.tipoColaboradorResponse.descricao,
                            unimeds: [{ codigo: "", unimed: "", idPerfil: null }]
                        };

                        validou = true;
                    } else {
                        this.masculino = false;
                        this.feminino = false;

                        this.usuario = {
                            documento: {
                                numeroDocumento: this.usuario.documento.numeroDocumento,
                                tipoDocumento: this.usuario.documento.tipoDocumento
                            },
                            nome: "",
                            dataNascimento: "",
                            email: "",
                            telefone: "",
                            genero: ""
                        };

                        prestador = {
                            conselhoProfissional: { numeroConselho: "", siglaEstado: "", tipoConselho: "" },
                            tipoColaborador: "",
                            tipoColaboradorModel: "",
                            unimeds: [{ codigo: "", unimed: "", idPerfil: null }]
                        };

                        validou = false;
                    }

                    this.$emit("buscou", { validou, prestador });
                })
                .finally(() => {
                    this.camposBloqueados = false;
                    this.loading = false;
                });
        },
        async buscarUsuario() {
            let validacaoBusca = await this.$refs.buscaUsuario.validate();

            if (validacaoBusca) {
                let documento = this.usuario.documento.numeroDocumento.replace(/([.-])/g, "");
                await this.getColaborador(documento);
            }
        },
        limparBusca() {
            this.usuario = {
                documento: {
                    numeroDocumento: "",
                    tipoDocumento: "CPF"
                },
                nome: "",
                dataNascimento: "",
                email: "",
                telefone: "",
                genero: ""
            };
        },
        async validarEtapa() {
            return await this.$refs.dadosBasicos.validate();
        },
        montarUsuario() {
            return this.usuario;
        }
    },
    watch: {
        masculino() {
            if (this.masculino) {
                this.feminino = false;
                this.usuario.genero = "M";
            } else if (!this.feminino) {
                this.usuario.genero = "";
            }
        },
        feminino() {
            if (this.feminino) {
                this.masculino = false;
                this.usuario.genero = "F";
            } else if (!this.masculino) {
                this.usuario.genero = "";
            }
        },
        usuario: {
            deep: true,
            immediate: true,
            handler() {
                let bool = Object.values(this.usuario).every(el => !["", "", null, undefined].includes(el));
                this.$emit("habilita", !bool);
            }
        },

        estrangeiro() {
            this.usuario.documento.numeroDocumento = "";
            if (this.estrangeiro) {
                this.usuario.documento.tipoDocumento = "RNE";
            } else {
                this.usuario.documento.tipoDocumento = "CPF";
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.linha-cadastro {
    margin-top: 24px;

    &.primeira-linha {
        margin-top: 24px;
    }

    & .limpar-busca {
        display: inline-block;
        margin-top: 34px;
        box-sizing: border-box;
        width: 80px;
        color: #1492e6;
        cursor: pointer;
    }

    & .botao {
        display: block;
        margin-top: 25px;
        & .botao-interno {
            box-sizing: border-box;
            color: #9e9e9e;

            &:hover {
                color: #00995d;
            }

            & p {
                margin-top: 2px;
            }
        }
    }
}

.linha-exibicao {
    box-sizing: border-box;
    padding: 24px 0 16px 0;
    border-top: 1px solid #dee2e6;
    height: 80px;

    &:first-child {
        margin-top: 16px;
    }

    & .coluna-exibicao {
        height: 40px;
        color: #60656c;

        & p {
            line-height: 16px;

            &.linha-subtitulo {
                margin-bottom: 8px;
            }
        }
    }
}
</style>
