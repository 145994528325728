<template>
    <div>
        <Auditoria />
    </div>
</template>

<script>
import Auditoria from "../components/auditoria/Auditoria.vue";
export default {
    components: {
        Auditoria
    }
};
</script>

<style lang="scss" scoped></style>
