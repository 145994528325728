<template>
    <div>
        <unicom-filtro
            titulo="FILTRAR SERVIÇOS WEB"
            :value="mostrar"
            @fechar="fecharFiltro"
            @filtrar="filtrar"
            @limpar-filtro="limparFiltro"
        >
            <div slot="corpo-filtro">
                <unicom-linha :espacamento="false" justificarItem="start" class="fonte-secundaria bordaCampo">
                    <unicom-coluna :md="12" class="campoFiltro">
                        <unicom-autocomplete
                            v-model="filtroWebService.aplicacaoModel"
                            :valorData="filtroWebService.aplicacaoModel"
                            :items="listaAplicacoes"
                            titulo="Aplicação"
                            textoDoItem="nome"
                            placeholder="Todas aplicações"
                        />
                    </unicom-coluna>
                </unicom-linha>

                <unicom-linha :espacamento="false" justificarItem="start" class="fonte-secundaria bordaCampo">
                    <unicom-coluna :md="12" class="campoFiltro">
                        <unicom-autocomplete
                            v-model="filtroWebService.operacaoModel"
                            :valorData="filtroWebService.operacaoModel"
                            :items="listaOperacoes"
                            titulo="Operação"
                            textoDoItem="descricao"
                            placeholder="Todas operações"
                        />
                    </unicom-coluna>
                </unicom-linha>

                <unicom-linha :espacamento="false" justificarItem="start" class="fonte-secundaria bordaCampo">
                    <unicom-coluna :md="12" class="campoFiltro">
                        <unicom-autocomplete
                            v-model="filtroWebService.unimedUsuarioModel"
                            :valorData="filtroWebService.unimedUsuarioModel"
                            :items="listaUnimeds"
                            titulo="Unimed do usuário"
                            textoDoItem="codigoNome"
                            placeholder="Todos usuários"
                        />
                    </unicom-coluna>
                </unicom-linha>

                <h2 class="fonte-secundaria negrito camposDataHora">Data e hora</h2>
                <unicom-linha :espacamento="false" justificarItem="start" class="fonte-secundaria">
                    <unicom-coluna :md="5" style="margin-bottom:16px;">
                        <unicom-campo-calendario
                            tituloCampo="De"
                            v-model="filtroWebService.dataInicial"
                            placeHolder="Insira a data"
                        />
                    </unicom-coluna>
                    <unicom-coluna :md="5" class="campoHora">
                        <unicom-campo-texto
                            v-model="filtroWebService.horaInicial"
                            titulo="Hora"
                            :mask="'##:##:##'"
                            :apenasAlfanumerico="true"
                            placeholder="00:00:00"
                        />
                    </unicom-coluna>
                </unicom-linha>

                <unicom-linha :espacamento="false" justificarItem="start" class="fonte-secundaria bordaCampo">
                    <unicom-coluna :md="5" style="margin-bottom:24px;">
                        <unicom-campo-calendario
                            tituloCampo="Até"
                            v-model="filtroWebService.dataFinal"
                            placeHolder="Insira a data"
                        />
                    </unicom-coluna>
                    <unicom-coluna :md="5" class="campoHora">
                        <unicom-campo-texto
                            v-model="filtroWebService.horaFinal"
                            titulo="Hora"
                            :mask="'##:##:##'"
                            :apenasAlfanumerico="true"
                            placeholder="23:59:59"
                        />
                    </unicom-coluna>
                </unicom-linha>

                <h2 class="fonte-secundaria negrito campoStatus">Status</h2>
                <unicom-linha :espacamento="false">
                    <unicom-coluna :md="4">
                        <unicom-checkbox v-model="filtroWebService.status" texto="Todos" value="" arredondado unico />
                    </unicom-coluna>
                    <unicom-coluna :md="4">
                        <unicom-checkbox
                            v-model="filtroWebService.status"
                            texto="Sucesso"
                            value="SUCESSO"
                            arredondado
                            unico
                        />
                    </unicom-coluna>
                    <unicom-coluna :md="4">
                        <unicom-checkbox
                            v-model="filtroWebService.status"
                            texto="Falha"
                            value="FALHA"
                            arredondado
                            unico
                        />
                    </unicom-coluna>
                </unicom-linha>
            </div>
        </unicom-filtro>
    </div>
</template>

<script>
import ListaOperacoesServiceMixin from "@/mixins/services/dominios/ListaOperacoesServiceMixin";
import Aplicacao from "../../../../services/gerenciarAplicacoes/Aplicacao";

export default {
    mixins: [ListaOperacoesServiceMixin],

    props: {
        value: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            mostrar: this.value,
            aplicacao: "",
            filtroWebService: {
                aplicacaoModel: "",
                operacaoModel: "",
                unimedUsuarioModel: "",
                operacao: "",
                unimedUsuario: "",
                aplicacao: "",
                dataInicial: "",
                horaInicial: "",
                dataFinal: "",
                horaFinal: "",
                status: [""]
            },
            listaAplicacoes: [],
            listaOperacoes: [],
            listaUnimeds: [],
            item: []
        };
    },
    beforeMount() {
        this.listarOperacoes();
        this.getListaAplicacoes();
        this.getListarUnimeds();
    },

    methods: {
        fecharFiltro() {
            this.$emit("fechar");
        },
        limparFiltro() {
            this.filtroWebService = {
                aplicacaoModel: "",
                operacaoModel: "",
                unimedUsuarioModel: "",
                operacao: "",
                unimedUsuario: "",
                aplicacao: "",
                dataInicial: "",
                horaInicial: "",
                dataFinal: "",
                horaFinal: "",
                status: [""]
            };
            this.$emit("limpar-filtro");
        },
        montarFiltro() {
            let filtro = this.filtroWebService;

            if (filtro.aplicacaoModel) {
                let aplicacao = this.listaAplicacoes.find(aplicacao => aplicacao.nome === filtro.aplicacaoModel).id;
                filtro.aplicacao = aplicacao;
            }

            if (filtro.operacaoModel) {
                let operacao = this.listaOperacoes.find(operacao => operacao.descricao === filtro.operacaoModel).codigo;
                filtro.operacao = operacao;
            }

            if (filtro.unimedUsuarioModel) {
                let unimed = this.listaUnimeds.find(unimed => unimed.codigoNome === filtro.unimedUsuarioModel).codigo;
                filtro.unimedUsuario = unimed;
            }
            return filtro;
        },
        filtrar() {
            this.$emit("filtrar", this.montarFiltro());
        },

        listarOperacoes() {
            this.loading = true;
            this.getListaOperacoesServicos()
                .then(response => {
                    this.listaOperacoes = response.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        async getListaAplicacoes() {
            let aplicacao = new Aplicacao(this.$appConfig.config.API);

            try {
                let listaAplicacoes = await aplicacao.getComboAplicacoes();
                listaAplicacoes.data.forEach(item => {
                    this.listaAplicacoes.push({
                        id: item.id,
                        nome: item.nome
                    });
                });
            } catch (err) {
                console.error(err);
            }
        },
        getListarUnimeds() {
            this.listaUnimeds = this.getUnimedsList();
        }
    },

    watch: {
        value() {
            this.mostrar = this.value;
        }
    }
};
</script>

<style lang="scss" scoped>
.campoFiltro {
    margin-top: 20px;
    margin-bottom: 24px;
}
.campoStatus {
    margin-top: 20px;
}
.camposDataHora {
    margin-top: 20px;
    margin-bottom: 8px;
}
.bordaCampo {
    border-bottom: 1px solid #ebebeb;
}

.campoHora {
    margin-left: 40px;
}
</style>
