<template>
    <div>
        <ValidationObserver ref="dadosBasicos">
            <unicom-linha :espacamento="true" class="linha-cadastro primeira-linha">
                <unicom-coluna>
                    <validation-provider rules="cnpj|required" mode="eager" v-slot="{ errors }">
                        <unicom-campo-texto
                            :mask="'##.###.###/####-##'"
                            apenasNumero
                            v-model="usuario.documento.numeroDocumento"
                            :desativado="false"
                            :titulo="'CNPJ *'"
                            :placeholder="'Insira o CNPJ'"
                            :mensagemErro="errors[0]"
                        />
                    </validation-provider>
                </unicom-coluna>
            </unicom-linha>
            <div>
                <unicom-linha :espacamento="true" class="linha-cadastro">
                    <unicom-coluna>
                        <validation-provider rules="required" mode="eager" v-slot="{ errors }">
                            <unicom-campo-texto
                                v-model="usuario.nome"
                                apenasTextoNumero
                                :maxCaracteres="'60'"
                                :titulo="'Razão social *'"
                                :placeholder="'Insira a razão social'"
                                :desativado="camposBloqueados"
                                :mensagemErro="errors[0]"
                            />
                        </validation-provider>
                    </unicom-coluna>
                </unicom-linha>
                <unicom-linha :espacamento="true" class="linha-cadastro">
                    <unicom-coluna>
                        <validation-provider rules="required|email" mode="eager" v-slot="{ errors }">
                            <unicom-campo-texto
                                v-model="usuario.email"
                                :maxCaracteres="'60'"
                                :titulo="'Email *'"
                                :placeholder="'Insira o email'"
                                :desativado="camposBloqueados"
                                :mensagemErro="errors[0]"
                            />
                        </validation-provider>
                    </unicom-coluna>
                </unicom-linha>
                <unicom-linha :espacamento="true" class="linha-cadastro">
                    <unicom-coluna>
                        <validation-provider rules="required|telRequired" mode="eager" v-slot="{ errors }">
                            <unicom-campo-texto
                                v-model="usuario.telefone"
                                apenasNumero
                                :mask="['(##) ####-####', '(##) #####-####']"
                                :titulo="'Telefone comercial *'"
                                :placeholder="'Insira o telefone'"
                                :desativado="camposBloqueados"
                                :mensagemErro="errors[0]"
                            />
                        </validation-provider>
                    </unicom-coluna>
                </unicom-linha>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            camposBloqueados: false,
            usuario: {
                documento: {
                    numeroDocumento: "",
                    tipoDocumento: "CNPJ"
                },
                fornecedor: true,
                nome: "",
                email: "",
                telefone: ""
            }
        };
    },
    methods: {
        async validarEtapa() {
            let validacaoBusca = await this.$refs.dadosBasicos.validate();
            return validacaoBusca;
        },
        montarUsuario() {
            this.usuario.documento.numeroDocumento = this.usuario.documento.numeroDocumento.replace(/([./-])/g, "");
            return this.usuario;
        }
    },
    watch: {
        usuario: {
            deep: true,
            immediate: true,
            handler() {
                let bool = Object.values(this.usuario).every(el => !["", "", null, undefined].includes(el));
                this.$emit("habilita", !bool);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.linha-cadastro {
    margin-top: 18px;

    &.primeira-linha {
        margin-top: 24px;
    }
}
</style>
