<template>
    <unicom-linha :espacamento="false">
        <unicom-coluna :lg="12">
            <unicom-cartao :class="`cartao-base ${tamanhoTela}`">
                <div slot="header" class="container-cabecalho">
                    <unicom-linha :espacamento="false">
                        <unicom-coluna>
                            <unicom-step :abas="abas" :tamanhoTela="tamanhoTela" @click="index => trocaStep(index)" />
                        </unicom-coluna>
                    </unicom-linha>
                </div>
                <div slot="body" style="padding:28px;">
                    <OperacoesDeAdministracoes v-if="index === 0" />
                    <WebServices v-if="index === 1" />
                </div>
            </unicom-cartao>
        </unicom-coluna>
    </unicom-linha>
</template>

<script>
import WebServices from "./webServices/WebServices";
import OperacoesDeAdministracoes from "./operacoesDeAdministradores/OperacoesDeAdministradores";

export default {
    components: {
        OperacoesDeAdministracoes,
        WebServices
    },
    data() {
        return {
            tamanhoJanela: 0,
            index: 0,
            abas: [
                {
                    nome: "OPERAÇÕES DE USUÁRIOS",
                    ativo: true,
                    atual: true
                },
                {
                    nome: "WEB SERVICES",
                    ativo: true,
                    atual: false
                }
            ]
        };
    },
    created() {
        window.addEventListener("resize", this.redimensionar);
        this.redimensionar();
    },
    destroyed() {
        window.removeEventListener("resize", this.redimensionar);
    },
    computed: {
        tamanhoTela() {
            if (this.tamanhoJanela < 576) {
                return "ativo mobile";
            } else {
                return "web";
            }
        }
    },
    methods: {
        redimensionar() {
            this.tamanhoJanela = window.innerWidth;
        },
        trocaStep(index) {
            this.abas[this.index].atual = false;
            this.index = index;
            this.abas[index].atual = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.cartao-base {
    min-height: 400px;
    border-radius: 20px;
    padding: 0px;
}
</style>
