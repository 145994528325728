<template>
    <unicom-modal v-model="mostrar" :largura="881" fechar @input="valor => fecharModal(valor)" class="modal">
        <div slot="header">
            <h3 class="fonte-primaria titulo negrito">DETALHES DA OPERAÇÃO</h3>
        </div>
        <div slot="body" class="container-corpo" v-if="objetoDaOperacao">
            <unicom-linha :espacamento="false" :justificarItem="'end'" class="fonte-secundaria">
                <unicom-coluna :md="12">
                    <div class="left">
                        Operação
                    </div>
                    <div class="right">
                        Data request:
                        <p class="respostaLog negrito fonte-secundaria" style="margin-left:7px;">{{ dataRequest() }}</p>
                    </div>

                    <!-- TODO ADICIONAR DOWNLOAD -->
                </unicom-coluna>
            </unicom-linha>

            <unicom-linha :espacamento="false" :justificarItem="'end'" class="fonte-secundaria headerLogs">
                <unicom-coluna :md="12">
                    <div class="negrito left">
                        {{ tipoOperacaoRequest() }}
                    </div>
                    <div class="right">
                        Data response:
                        <div class="respostaLog negrito">{{ dataResponse() }}</div>
                    </div>
                </unicom-coluna>
            </unicom-linha>
            <unicom-linha
                :espacamento="false"
                :justificarItem="'start'"
                style="padding-top:20px"
                class="fonte-secundaria headerDetalhes"
            >
                <unicom-coluna :md="3">
                    Parâmetros
                </unicom-coluna>
            </unicom-linha>
            <unicom-linha :espacamento="false" :justificarItem="'start'" class="fonte-secundaria headerLogs">
                <unicom-coluna :md="12" class="respostaLog negrito">
                    {{ pathRequest() }}
                </unicom-coluna>
            </unicom-linha>

            <unicom-linha :espacamento="false" style="padding-top:10px" v-if="possuiArquivo()">
                <unicom-coluna :md="12">
                    <p class="fonte-secundaria link negrito" @click="downloadArquivo()">Download Arquivo...</p>
                </unicom-coluna>
            </unicom-linha>

            <unicom-linha :espacamento="false" :justificarItem="'end'" class="primeiroCard">
                <unicom-coluna :md="12">
                    <CartaoTap :textoHeader="'Request'" :largura="781" :texto="conteudoRequest()" />
                </unicom-coluna>
                <unicom-coluna :md="12" class="segundoCard">
                    <CartaoTap :textoHeader="'Response'" :largura="781" :texto="conteudoResponse()" />
                </unicom-coluna>
            </unicom-linha>
        </div>
        <div slot="footer" class="footer">
            <unicom-linha :espacamento="false" :justificarItem="'end'" style="margin-right:20px;">
                <unicom-coluna :md="2">
                    <unicom-botao class="fonte-secundaria texto" :bloco="true" @click="mostrar = false"
                        >FECHAR</unicom-botao
                    >
                </unicom-coluna>
            </unicom-linha>
        </div>
    </unicom-modal>
</template>

<script>
import CartaoTap from "./CartaoTap";
import { saveAs } from "file-saver";
import AuditoriaServiceMixin from "../services/AuditoriaServiceMixin";


export default {
    mixins: [AuditoriaServiceMixin],
    components: {
        CartaoTap
    },

    props: {
        value: {
            type: Boolean,
            required: true
        },
        objetoDaOperacao: {
            type: Array,
            required: false,
            default: () => []
        },
        objAuditoria: {
            type: Object,
            required: false
        }
    },

    data() {
        return {
            mostrar: this.value,
            objetoRequest: {},
            objetoResponse: {},
            objetoOperacaoVazio: {
                conteudo: "",
                dataHora: "",
                tipoOperacao: {
                    codigo: null,
                    descricao: ""
                },
                codigo: null,
                descricao: "",
                tipoTransacao: {
                    codigo: null,
                    descricao: ""
                }
            }
        };
    },
    watch: {
        value() {
            this.mostrar = this.value;
            if (this.value) {
                this.objetoDaOperacao.forEach(it => {
                    if (it.tipoTransacao.descricao === "REQUEST") this.objetoRequest = it;

                    if (it.tipoTransacao.descricao === "RESPONSE") this.objetoResponse = it;
                });
            }
        }
    },
    methods: {
        fecharModal(valor) {
            if (!valor) {
                this.$emit("fechar");
            }
        },
        tipoOperacaoRequest() {
            return this.objetoDaOperacao[0] ? this.objetoDaOperacao[0].tipoOperacao.descricao : "";
        },
        pathRequest() {
            return this.objetoDaOperacao[0] ? this.objetoDaOperacao[0].path : "";
        },
        dataRequest() {
            return this.objetoRequest.dataHora ? this.objetoRequest.dataHora : "";
        },
        dataResponse() {
            return this.objetoResponse.dataHora ? this.objetoRequest.dataHora : "";
        },
        conteudoRequest() {
            return this.objetoRequest.conteudo ? this.objetoRequest.conteudo : "";
        },
        conteudoResponse() {
            return this.objetoResponse.conteudo ? this.objetoResponse.conteudo : "";
        },
        possuiArquivo() {
            return this.objetoDaOperacao[0] ? this.objetoDaOperacao[0].possuiArquivo : false;
        },
        downloadArquivo() {
            this.getArquivoDownload(this.objAuditoria).then(res => {
                const content = res.data;
                const contentType = res.request.getResponseHeader("Content-Type");

                const blob = new Blob([content], { type: contentType });

                var filename = res.request.getResponseHeader("Content-Disposition").split("filename=")[1];
                filename = filename.replace(/(["])/g, "");

                saveAs(blob, filename);
            });
        }
    }
};
</script>

<style lang="scss" scoped>
h3 {
    color: #00995d;
    margin-bottom: 30px;
}

.left {
    width: 30%;
    float: left;
}

.right {
    width: 32%;
    float: right;
}

.headerLogs {
    margin-top: 6px;
}

.primeiroCard {
    margin-top: 30px;
}

.segundoCard {
    margin-top: 14px;
}

.footer {
    margin-bottom: 30px;
}

.logOperacao {
    margin-top: 5px;
    background: #f1f2f1 0% 0% no-repeat padding-box;
    width: 540px;
    max-height: 280px;
    border-radius: 5px;
    opacity: 1;
    padding: 24px;
    overflow-y: auto;
}

.respostaLog {
    display: inline;
}

.link {
    margin-top: 8px;
    margin-bottom: 24px;
    color: #1492e6;
    cursor: pointer;
}
</style>
