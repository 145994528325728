import Axios from "axios";

var PapeisServiceMixin = {
    methods: {
        getPapeisUsuario(body, mock = false) {
            if (mock) {
                //URL do mock
                var url = "https://37536626-bcab-46af-bb11-3f43309a4177.mock.pstmn.io/papeisAplicacoes";
                return Axios.get(url);
            }

            return Axios.post(
                this.CriarUrl({
                    resource: `/api/aplicacoes/listar-aplicacoes-do-usuario-unimed-com-merge-papeis-vinculados-ao-usuario`
                }),
                body,
                { withCredentials: true }
            );
        },

        postListarAplicacoesVinculadas(body) {
            return Axios.post(
                this.CriarUrl({ resource: `/api/aplicacoes/listar-aplicacoes-vinculadas-ao-usuario` }),
                body,
                { withCredentials: true }
            );
        },

        getAplicacoesDisponiveis(body, mock = false) {
            if (mock) {
                //URL do mock
                var url = "https://37536626-bcab-46af-bb11-3f43309a4177.mock.pstmn.io/aplicacoesDisponiveis";
                return Axios.get(url);
            }

            return Axios.post(
                this.CriarUrl({ resource: `/api/aplicacoes/listar-aplicacoes-nao-vinculadas-ao-usuario` }),
                body,
                { withCredentials: true }
            );
        },

        getAplicacoesVisiveisDisponiveis(body, mock = false) {
            if (mock) {
                //URL do mock
                var url = "https://37536626-bcab-46af-bb11-3f43309a4177.mock.pstmn.io/aplicacoesDisponiveis";
                return Axios.get(url);
            }

            return Axios.post(
                this.CriarUrl({ resource: `/api/aplicacoes/listar-aplicacoes-visiveis-nao-vinculadas-ao-usuario` }),
                body,
                { withCredentials: true }
            );
        }
    }
};

export default PapeisServiceMixin;
