<template>
    <div>
        <unicom-modal
            v-model="mostrar"
            :largura="912"
            fechar
            @input="valor => fecharModal(valor)"
            class="modal-container"
        >
            <div slot="header">
                <div class="outer">
                    <h3 class="fonte-primaria titulo negrito">ADICIONAR APLICAÇÃO AO USUÁRIO</h3>
                </div>

                <div v-if="!loading" class="outer">
                    <unicom-linha :espacamento="false" justificarItem="center" class="linha-steps">
                        <unicom-coluna :md="12">
                            <button :class="`step ${stepAtivo(0)}`" @click="trocaStep(0)"></button>
                            <button :class="`step ${stepAtivo(1)} ${stepBloqueado}`" @click="trocaStep(1)"></button>
                        </unicom-coluna>
                    </unicom-linha>
                </div>

                <div v-if="!loading" class="outer">
                    <h3 class="fonte-primaria titulo negrito corSecundaria">{{ textoStep }}</h3>
                </div>
            </div>

            <div slot="body">
                <unicom-loading
                    v-if="loading"
                    v-model="loading"
                    style="margin-top:15px;"
                    mensagem="Carregando aplicações disponível..."
                />

                <div v-else>
                    <div v-if="index === 0">
                        <TabelaAplicacaoModal />
                    </div>
                    <div v-if="index === 1">
                        <TabelaAdicionarPapel />
                    </div>
                </div>
            </div>

            <div slot="footer">
                <unicom-linha :espacamento="true" justificarItem="between" v-if="index === 0">
                    <unicom-coluna :md="3">
                        <unicom-botao
                            tipo="contornado-primario"
                            class="fonte-secundaria texto"
                            :bloco="true"
                            :altura="40"
                            @click="fecharModal(false)"
                            >CANCELAR
                        </unicom-botao>
                    </unicom-coluna>
                    <unicom-coluna :md="3">
                        <unicom-botao
                            class="fonte-secundaria texto"
                            :desabilitado="!stepBloqueado"
                            :bloco="true"
                            :altura="40"
                            @click="trocaStep(1)"
                            >CONTINUAR
                        </unicom-botao>
                    </unicom-coluna>
                </unicom-linha>
                <unicom-linha :espacamento="true" justificarItem="start" v-if="index === 1">
                    <unicom-coluna :md="3">
                        <unicom-botao
                            tipo="contornado-primario"
                            class="fonte-secundaria texto"
                            :bloco="true"
                            :altura="40"
                            @click="fecharModal(false)"
                            >CANCELAR
                        </unicom-botao>
                    </unicom-coluna>
                    <unicom-coluna :md="2" />
                    <unicom-coluna :md="3">
                        <unicom-botao
                            class="fonte-secundaria texto"
                            :bloco="true"
                            :altura="40"
                            tipo="secundario"
                            @click="trocaStep(0)"
                            >VOLTAR
                        </unicom-botao>
                    </unicom-coluna>
                    <unicom-coluna :md="4">
                        <unicom-botao class="fonte-secundaria texto" :bloco="true" :altura="40" @click="adicionarPapeis"
                            >ADICIONAR AO USUÁRIO
                        </unicom-botao>
                    </unicom-coluna>
                </unicom-linha>
            </div>
        </unicom-modal>

        <unicom-modal-confirmacao
            v-model="mostrarModalConfirmacao"
            textoBotaoCancelar="CANCELAR AGORA"
            textoBotaoConfirmar="CONTINUAR ADICIONANDO"
            :tipoBotaoCancelar="true"
            mensagem="Todas as configurações feitas até agora serão perdidas."
            @fechar="mostrarModalConfirmacao = false"
        >
        </unicom-modal-confirmacao>
    </div>
</template>

<script>
import TabelaAplicacaoModal from "./TabelaAplicacoes";
import TabelaAdicionarPapel from "./TabelaAdicionarPapel";
import PapeisServiceMixin from "../../services/PapeisServiceMixin";
import UsuarioUnimedServiceMixin from "../../../../../mixins/services/usuarioUnimed/UsuarioUnimedServiceMixin";

export default {
    mixins: [PapeisServiceMixin, UsuarioUnimedServiceMixin],

    components: {
        TabelaAplicacaoModal,
        TabelaAdicionarPapel
    },

    props: {
        value: {
            type: Boolean,
            required: true
        },
        idUnimed: {
            type: Number,
            required: true
        },
        idUsuario: {
            type: Number,
            required: true
        },
        idPerfil: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            mostrar: this.value,
            index: 0,
            aplicacoesSelecionadas: [],
            mostrarModalConfirmacao: false,
            mostrarModalSucesso: false,
            loading: true
        };
    },
    methods: {
        getAplicacoes(data) {
            this.loading = true;

            this.getAplicacoesVisiveisDisponiveis(data, false)
                .then(response => {
                    if (response) {
                        this.$store.commit("adicionarListaAplicacoesPapeis", response.data);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        papeis() {
            var papeis = [];
            this.$store.getters.filtrarAplicacoesSelecionadas.forEach(item => {
                if (item.papeisAtivos) {
                    item.papeisAtivos.forEach(papel => {
                        papeis.push(papel.id);
                    });
                }
            });

            return papeis;
        },

        adicionarPapeis() {
            this.$store.commit("setLoading", true);
            this.postAdicionarPapel(this.objetoPapeis())
                .then(response => {
                    if (response.status === 200) {
                        this.$emit("salvar");
                        this.fecharModal(false);
                    }
                })
                .finally(() => {
                    this.$store.commit("setLoading", false);
                });
        },

        objetoPapeis() {
            return {
                papeis: this.papeis(),
                usuarioUnimeds: [
                    {
                        idUnimed: this.idUnimed,
                        idUsuario: this.idUsuario
                    }
                ]
            };
        },

        fecharModal(valor) {
            if (!valor) {
                this.$emit("fechar");
                this.index = 0;
                this.$store.commit("aplicacacoesSelecionadas", []);
            }
        },
        async trocaStep(index) {
            if (this.$store.getters.filtrarAplicacoesSelecionadas.length > 0) {
                this.index = index;
            }
        },

        stepAtivo(index) {
            return this.index === index ? "ativo" : "";
        }
    },
    watch: {
        value() {
            this.mostrar = this.value;
        }
    },

    computed: {
        textoStep() {
            return this.index === 0
                ? "Selecione as aplicações para o usuário"
                : "Selecione os papéis do usuário nas aplicações";
        },

        stepBloqueado() {
            return this.$store.getters.filtrarAplicacoesSelecionadas.length > 0;
        },

        habilitarProximoStep() {
            return true;
        }
    }
};
</script>

<style lang="scss" scoped>
h3 {
    color: #00995d;
    margin-bottom: 8px;
}

.corSecundaria {
    color: #60656c;
}

.outer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner {
    width: 52%;
}

.modal-container {
    height: 96%;

    & .linha-steps {
        margin: 24px 0;

        & .step {
            float: left;
            box-sizing: border-box;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #cccccc;
            cursor: pointer;

            &:first-child {
                margin-right: 16px;
            }

            &.ativo {
                background-color: #00995d;
            }

            &.inativo {
                background-color: #cccccc;
                cursor: not-allowed;
            }
        }
    }
}
</style>
