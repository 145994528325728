<template>
    <unicom-filtro
        :titulo="tituloFiltro"
        :value="mostrar"
        @fechar="fecharFiltro"
        @filtrar="filtrar"
        @limpar-filtro="limparFiltro"
    >
        <div slot="corpo-filtro">
            <!-- <unicom-linha :espacamento="false" justificarItem="start" class="fonte-secundaria texto linha-filtro">
                <unicom-coluna :md="12" class="campoFiltro">
                    <unicom-autocomplete
                        v-model="filtroUsuarios.usuario"
                        :valorData="filtroUsuarios.usuario"
                        :items="listaUsuario"
                        :titulo="tituloNomeRazao"
                        textoDoItem="nome"
                    />
                </unicom-coluna>
            </unicom-linha> -->
            <unicom-linha
                :espacamento="false"
                justificarItem="start"
                class="fonte-secundaria texto linha-filtro"
                v-if="sessaoUsuario.usuarioUnimed.perfil.id === -1"
            >
                <unicom-coluna :md="12" class="campoFiltro">
                    <unicom-autocomplete
                        v-model="filtroUsuarios.unimed"
                        :valorData="filtroUsuarios.unimed"
                        :items="listaUnimeds"
                        textoDoItem="codigoNome"
                        titulo="Unimed do usuário"
                    />
                </unicom-coluna>
            </unicom-linha>
            <unicom-linha :espacamento="false" class="linha-filtro" v-if="index === 0">
                <unicom-coluna>
                    <p class="fonte-secundaria texto">Tipo da conta</p>
                </unicom-coluna>
                <unicom-coluna :md="6">
                    <unicom-checkbox v-model="administrador" :texto="'ADMINISTRADOR'" :arredondado="true" />
                </unicom-coluna>
                <unicom-coluna :md="6">
                    <unicom-checkbox v-model="colaborador" :texto="'COLABORADOR'" :arredondado="true" />
                </unicom-coluna>
            </unicom-linha>
            <unicom-linha :espacamento="false" class="linha-filtro">
                <unicom-coluna>
                    <p class="fonte-secundaria texto">Status da conta</p>
                </unicom-coluna>
                <unicom-coluna md="6">
                    <unicom-checkbox v-model="ativa" :texto="'ATIVA'" :arredondado="true" />
                </unicom-coluna>
                <unicom-coluna md="6">
                    <unicom-checkbox v-model="inativa" :texto="'INATIVA'" :arredondado="true" />
                </unicom-coluna>
                <unicom-coluna md="6">
                    <unicom-checkbox v-model="pendente" :texto="'PENDENTE'" :arredondado="true" />
                </unicom-coluna>
            </unicom-linha>
        </div>
    </unicom-filtro>
</template>

<script>
// SERVICES
import ColaboradorServiceMixin from "../../../../mixins/services/gerenciarUsuarios/ColaboradorServiceMixin";
import UnimedServiceMixin from "../../../../mixins/services/dominios/UnimedServiceMixin";
import PessoaJuridicaMixin from "../services/PessoaJuridicaMixinService";

export default {
    props: {
        value: {
            type: Boolean,
            required: true
        },
        index: {
            type: Number,
            required: false,
            default: 0
        }
    },
    mixins: [PessoaJuridicaMixin, ColaboradorServiceMixin, UnimedServiceMixin],
    data() {
        return {
            mostrar: this.value,

            filtroUsuarios: {
                usuario: "",
                unimed: "",
                tipoConta: "",
                statusConta: ""
            },

            listaUsuario: [],
            listaUnimeds: [],
            administrador: false,
            colaborador: false,
            ativa: false,
            inativa: false,
            pendente: false,
            sessaoUsuario: this.getSession()
        };
    },
    async beforeMount() {
        await this.getDominios();
    },
    methods: {
        // DOMINIOS
        getDominios() {
            //this.getListaUsuarios();
            this.getListaUnimeds();
        },
        getListaUsuarios() {
            if (this.sessaoUsuario.usuarioUnimed.perfil.id !== -1) {
                this.getAllColaborador({ unimedId: this.sessaoUsuario.usuarioUnimed.unimed.id })
                    .then(response => {
                        this.listaUsuario = [];
                        this.montarListaUsuarios(response.data);
                    })
                    .catch(error => {
                        console.error(error);
                        this.listaUsuario = [];
                    });
            } else {
                this.getAllColaborador()
                    .then(response => {
                        this.listaUsuario = [];
                        this.montarListaUsuarios(response.data);
                    })
                    .catch(error => {
                        console.error(error);
                        this.listaUsuario = [];
                    });
            }
        },
        getListaJuridicos() {
            if (this.sessaoUsuario.usuarioUnimed.perfil.id !== -1) {
                this.getAllJuridicos({ unimedId: this.sessaoUsuario.usuarioUnimed.unimed.id })
                    .then(response => {
                        this.listaUsuario = [];
                        this.montarListaJuridicos(response.data);
                    })
                    .catch(error => {
                        console.error(error);
                        this.listaUsuario = [];
                    });
            } else {
                this.getAllJuridicos()
                    .then(response => {
                        this.listaUsuario = [];
                        this.montarListaJuridicos(response.data);
                    })
                    .catch(error => {
                        console.error(error);
                        this.listaUsuario = [];
                    });
            }
        },
        getListaUnimeds() {
            let unimeds = this.getUnimedsList();

            if (this.sessaoUsuario.usuarioUnimed.perfil.id !== -1) {
                unimeds.forEach(item => {
                    if (item.codigo === this.sessaoUsuario.usuarioUnimed.unimed.codigo) {
                        this.listaUnimeds.push(item);
                    }
                });
            } else {
                this.listaUnimeds = unimeds;
            }
        },
        montarListaUsuarios(lista) {
            this.listaUsuario = lista;
        },
        montarListaJuridicos(lista) {
            this.listaUsuario = lista;
        },
        // FILTRO
        fecharFiltro() {
            this.$emit("fechar");
        },
        filtrar() {
            this.$emit("filtrar", this.montarFiltro());
            this.$emit("fechar");
        },
        montarFiltro() {
            return {
                nome: this.filtroUsuarios.usuario,
                codigoUnimed: this.montaUnimed(),
                tipoConta: this.montaTipoConta(),
                statusUsuario: this.montaStatusConta()
            };
        },
        montaUnimed() {
            if (this.filtroUsuarios.unimed) {
                return this.listaUnimeds.find(item => item.codigoNome === this.filtroUsuarios.unimed).codigo;
            } else {
                return "";
            }
        },
        montaTipoConta() {
            if (this.administrador) {
                return 1;
            } else if (this.colaborador) {
                return 2;
            } else {
                return "";
            }
        },
        montaStatusConta() {
            if (this.ativa) {
                return "ATIVO";
            } else if (this.inativa) {
                return "INATIVO";
            } else if (this.pendente) {
                return "PENDENTE";
            } else {
                return "";
            }
        },
        limparFiltro() {
            this.filtroUsuarios = { usuario: "", unimed: "", tipoConta: "", statusConta: "" };
            this.administrador = false;
            this.colaborador = false;
            this.ativa = false;
            this.inativa = false;
            this.pendente = false;
            this.$emit("limpar-filtro");
            this.$emit("fechar");
        }
    },
    computed: {
        tituloFiltro() {
            return this.index === 0 ? "FILTRAR USUÁRIOS" : "FILTRAR FORNECEDORES";
        },
        tituloNomeRazao() {
            return this.index === 0 ? "Nome do usuário" : "Razão Social";
        }
    },
    watch: {
        value() {
            this.mostrar = this.value;
        },
        index() {
            this.limparFiltro();
        },
        administrador() {
            if (this.administrador) {
                this.colaborador = false;
            }
        },
        colaborador() {
            if (this.colaborador) {
                this.administrador = false;
            }
        },
        ativa() {
            if (this.ativa) {
                this.inativa = false;
                this.pendente = false;
            }
        },
        inativa() {
            if (this.inativa) {
                this.ativa = false;
                this.pendente = false;
            }
        },
        pendente() {
            if (this.pendente) {
                this.ativa = false;
                this.inativa = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.linha-filtro {
    height: 117px;
    padding: 24px 0;
    border-bottom: 1px solid #ebebeb;

    &:first-child {
        margin-top: 11px;
    }

    &:last-child {
        border-bottom: none;
    }
}
</style>
