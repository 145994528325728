<template>
    <unicom-modal v-model="mostrar" :largura="736" fechar @input="valor => fecharModal(valor)" class="modal">
        <div slot="header">
            <h3 class="fonte-primaria titulo negrito corTextoPrimaria">{{ titulo }}</h3>
        </div>
        <div slot="body" class="container-corpo">
            <p class="corTextoSecundaria fonte-secundaria informacao-importacao">
                Para importar um arquivo é necessário que o documento esteja no padrão esperado.
            </p>
            <p class="fonte-secundaria link negrito" @click="$emit('download-exemplo')">Baixar .CSV de exemplo</p>
            <p class="fonte-secundaria negrito" style="margin-bottom: 24px;">
                Importante: O formato do arquivo a ser importado deve ser CSV
            </p>

            <unicom-linha :espacamento="false" alinhamento="center" justificarItem="center" class="caixa-importacao">
                <unicom-coluna :col="12" :md="12">
                    <unicom-importar
                        extensoesPermitidas="csv"
                        ref="importar"
                        @arquivo-recebido="arquivo => arquivoRecido(arquivo)"
                    >
                        <div slot="texto-informativo" class="fonte-secundaria">
                            <p class="corTextoSecundaria">Arraste e solte o .CSV aqui ou</p>
                            <p class="negrito corTextoPrimaria">clique para selecionar o documento.</p>
                        </div>
                    </unicom-importar>
                </unicom-coluna>
            </unicom-linha>

            <p class="corTextoSecundaria fonte-secundaria paragrafo-limite-arquivo">
                O arquivo deve possuir no máximo <span>{{ linhasMaximas }}</span> linhas ou {{ tamanhoMaximo }}
                {{ tipoValor }} de tamanho.
            </p>
        </div>
        <div slot="footer" class="footer">
            <unicom-linha :espacamento="false" justificarItem="end">
                <unicom-coluna :md="9">
                    <unicom-botao
                        class="fonte-secundaria texto"
                        tipo="contornado-primario"
                        :bloco="false"
                        :largura="168"
                        :altura="40"
                        @click="mostrar = false"
                    >
                        CANCELAR
                    </unicom-botao>
                </unicom-coluna>
                <unicom-coluna :md="3">
                    <unicom-botao
                        class="fonte-secundaria texto"
                        :bloco="false"
                        :largura="168"
                        :altura="40"
                        :desabilitado="arquivo == null"
                        @click="enviarArquivo"
                    >
                        ENVIAR ARQUIVO
                    </unicom-botao>
                </unicom-coluna>
            </unicom-linha>
        </div>
    </unicom-modal>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true
        },
        titulo: {
            type: String,
            required: false,
            default: "",
            note: "Título da modal de anexo"
        },
        linhasMaximas: {
            type: String,
            required: false,
            default: "0",
            note: "Define o tamanho de linhas máximas do arquivo"
        },
        tamanhoMaximo: {
            type: String,
            required: false,
            default: "0",
            note: "Define o tamanho máximo do arquivo"
        },
        tipoValor: {
            type: String,
            required: false,
            default: "mb",
            note: "Define o tipo de tamanho junto ao tamanho maximo(exemplo: kb, mb, gb, ..)"
        }
    },

    data() {
        return {
            mostrar: this.value,
            arquivo: null
        };
    },

    methods: {
        fecharModal(valor) {
            if (!valor) {
                this.arquivo = null;
                this.$emit("fechar");
            }
        },

        arquivoRecido(arquivo) {
            this.arquivo = arquivo;
        },

        enviarArquivo() {
            this.$emit("arquivo-recebido", this.arquivo);
            this.arquivo = null;
        }
    },
    watch: {
        value() {
            this.mostrar = this.value;
        }
    }
};
</script>

<style lang="scss" scoped>
.corTextoSecundaria {
    color: #60656c;
}
.corTextoPrimaria {
    color: #00995d;
    cursor: pointer;
}

.informacao-importacao {
    margin-top: 32px;
}

.paragrafo-limite-arquivo {
    margin-bottom: 20px;
}

.caixa-importacao {
    margin-bottom: 24px;
}

.link {
    margin-top: 8px;
    margin-bottom: 8px;
    color: #1492e6;
    cursor: pointer;
}

.footer {
    margin-bottom: 30px;
}
</style>
