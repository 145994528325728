<template>
    <div>
        <unicom-linha :espacamento="false">
            <unicom-coluna>
                <div class="conteudo-principal-container">
                    <unicom-linha :espacamento="false">
                        <unicom-coluna>
                            <unicom-step :abas="abas" @click="index => trocaStep(index)" />
                        </unicom-coluna>
                    </unicom-linha>

                    <unicom-linha :espacamento="false">
                        <unicom-coluna>
                            <div class="container-aba">
                                <DadosBasicos
                                    v-show="index === 0"
                                    v-model="dados"
                                    :isColaborador="isColaborador"
                                    :somenteVisualizar="somenteVisualizar"
                                    ref="dadosBasicos"
                                    @atualizar="$emit('atualizar')"
                                />
                                <DadosProfissionais
                                    v-if="index === 1"
                                    v-model="dados"
                                    :isColaborador="isColaborador"
                                    ref="dadosProfissionais"
                                />
                                <tabela-papeis
                                    v-if="index === 2"
                                    :idUsuario="dados.id"
                                    :listaUnimeds="unimedsPorUsuario()"
                                    :somenteVisualizar="somenteVisualizar"
                                />
                            </div>
                        </unicom-coluna>
                    </unicom-linha>

                    <unicom-linha espacamento class="linha-rodape" v-if="index !== 2">
                        <unicom-coluna :md="2">
                            <unicom-botao
                                :tipo="'primario'"
                                :contornado="true"
                                bloco
                                class="fonte-secundaria texto"
                                @click="$emit('fechar')"
                            >
                                {{ somenteVisualizar ? "VOLTAR" : "CANCELAR" }}
                            </unicom-botao>
                        </unicom-coluna>

                        <unicom-coluna :md="10" v-if="!somenteVisualizar">
                            <unicom-linha :espacamento="true" :justificarItem="'end'">
                                <unicom-coluna :md="3">
                                    <unicom-botao
                                        :tipo="'primario'"
                                        :bloco="true"
                                        class="fonte-secundaria texto"
                                        @click="salvar"
                                    >
                                        SALVAR ALTERAÇÕES
                                    </unicom-botao>
                                </unicom-coluna>
                            </unicom-linha>
                        </unicom-coluna>
                    </unicom-linha>
                </div>
            </unicom-coluna>
        </unicom-linha>

        <unicom-modal-loading v-model="carregamento" />

        <unicom-modal :value="loading" :largura="320">
            <div slot="body">
                <unicom-loading v-model="loading" style="margin-top:25px;" :mensagem="mensagemLoading" />
            </div>
        </unicom-modal>

        <unicom-modal-sucesso v-model="modalSucesso" :titulo="mensagemSucesso" @fechar="fecharSucesso()" />
    </div>
</template>

<script>
import Axios from "axios";

import DadosBasicos from "./DadosBasicos";
import DadosProfissionais from "./DadosProfissionais";
import TabelaPapeis from "./TabelaPapeis";

import PessoaJuridicaMixinService from "../services/PessoaJuridicaMixinService";
import ColaboradorMixinService from "../../../../mixins/services/gerenciarUsuarios/ColaboradorServiceMixin";
import UsuarioUnimedServiceMixin from "../../../../mixins/services/usuarioUnimed/UsuarioUnimedServiceMixin";
import UsuarioServiceMixin from "../../../../mixins/services/usuario/UsuarioServiceMixin";

export default {
    mixins: [PessoaJuridicaMixinService, ColaboradorMixinService, UsuarioServiceMixin, UsuarioUnimedServiceMixin],

    components: {
        DadosBasicos,
        DadosProfissionais,
        TabelaPapeis
    },
    props: {
        dadosUsuario: {
            type: Object,
            default: () => {
                return {
                    conselhoProfissional: {
                        estado: "",
                        numeroConselho: 0,
                        tipoConselhoProfissional: {
                            codigo: 0,
                            descricao: ""
                        }
                    },
                    dataNascimento: "",
                    documento: {
                        numeroDocumento: "",
                        tipoDocumento: {
                            codigo: 0,
                            descricao: ""
                        }
                    },
                    email: "",
                    genero: "",
                    id: 0,
                    login: {},
                    nome: "",
                    status: "",
                    telefone: "",
                    tipoColaborador: {
                        codigo: 0,
                        descricao: ""
                    },
                    unimeds: []
                };
            }
        },

        isColaborador: {
            type: Boolean,
            required: true,
            note: "Verifico se usuário é colaborador ao editar"
        },

        somenteVisualizar: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            index: 0,
            dados: this.dadosUsuario,
            abas: [
                { nome: "DADOS BÁSICOS", ativo: true, atual: true },
                { nome: "DADOS PROFISSIONAIS", ativo: true, atual: false },
                { nome: "APLICAÇÕES E PAPÉIS", ativo: true, atual: false }
            ],

            storageUnimeds: JSON.parse(localStorage.getItem("unimeds")),

            unimedCodigo: this.getSession().usuarioUnimed.unimed.codigo,

            carregamento: false,

            mensagemLoading: "",
            loading: false,

            mensagemSucesso: "Os dados foram alterados com sucesso!",
            modalSucesso: false,

            mostrarModalConfirmarInativacao: false
        };
    },
    methods: {
        unimedsPorUsuario() {
            let sessao = this.getSession();
            if (sessao.usuarioUnimed.perfil.id === -1) {
                return this.dados.unimeds;
            } else {
                let idUnimed = sessao.usuarioUnimed.unimed.id;
                let listaPorUsuario = [];
                this.dados.unimeds.forEach(item => {
                    if (item.unimed.id === idUnimed) {
                        listaPorUsuario.push(item);
                    }
                });
                return listaPorUsuario;
            }
        },
        trocaStep(index) {
            this.abas[this.index].atual = false;
            this.index = index;
            this.abas[index].atual = true;
        },
        tipoColaboradorSelecionado() {
            if (this.dados.tipoColaborador.descricao === "Médico") return "MEDICO";
            if (this.dados.tipoColaborador.descricao === "Enfermeiro") return "ENFERMEIRO";
            if (this.dados.tipoColaborador.descricao === "Colaborador") return "COLABORADOR";
        },
        getColaboradorID() {
            this.carregamento = true;
            this.getColaboradorPeloId(this.dados.id)
                .then(response => {
                    if (response) {
                        this.dados = response.data;
                    }
                })
                .finally(() => {
                    this.carregamento = false;
                });
        },
        async salvar() {
            var validarDadosBasicos = await this.$refs.dadosBasicos.$refs.observer.validate();

            if (validarDadosBasicos) {
                this.loading = true;
                this.mensagemLoading = "Atualizando dados...";

                let data;

                switch (this.index) {
                    case 0: // Aba de Dados Básicos
                        data = {
                            idUsuario: this.dados.id,
                            status: this.dados.status !== "INATIVA" ? this.dados.status : "INATIVO",
                            telefone: this.dados.telefone.replace(/[^0-9]+/g, ""),
                            email: this.dados.email,
                            nomeCompleto: this.isColaborador ? this.dados.nome : this.dados.razaoSocial,
                            dataNascimento: this.dados.dataNascimento,
                            genero: this.dados.genero
                        };

                        this.putAtualizarDadosBasicos(data)
                            .then(() => {
                                this.modalSucesso = true;
                                this.mensagemSucesso = "Alteração dos dados realizada com sucesso!";
                                var usuarioSessao = this.getSession();
                                if (usuarioSessao.userId === data.idUsuario) {
                                    usuarioSessao.email = data.email;
                                    localStorage.setItem("session", JSON.stringify(usuarioSessao));
                                    this.$emit("atualizar");
                                }
                            })
                            .finally(() => (this.loading = false));

                        break;

                    case 1: // Aba de Dados Profissionais
                        var dataEdicao = [];
                        var dadosProfissionais = {};

                        this.dados.unimeds.forEach(el => {
                            let uni = {};

                            if (!el.unimed.codigo) {
                                uni = this.$refs.dadosProfissionais.listaUnimeds.find(
                                    it => it.codigoNome == el.unimed.codigoNome
                                );
                            } else {
                                uni = this.storageUnimeds.find(it => it.codigo == el.unimed.codigo);
                            }

                            el.unimed.id = uni.codigo;
                        });

                        this.dados.unimeds.forEach(el => {
                            dataEdicao.push({
                                codigo: el.unimed.id,
                                idPerfil: el.perfil.id === -1 ? 1 : el.perfil.id,
                                idUsuario: this.dados.id
                            });
                        });

                        if (this.isColaborador) {
                            dadosProfissionais = {
                                conselhoProfissional: {
                                    numeroConselho: this.dados.conselhoProfissional.numeroConselho,
                                    siglaEstado: this.dados.conselhoProfissional.estado,
                                    tipoConselho: this.dados.conselhoProfissional.tipoConselhoProfissional.descricao
                                },
                                tipoColaborador: this.dados.tipoColaborador.descricao
                            };

                            this.putDadosProfissionais(this.dados.id, dadosProfissionais);
                        }

                        Axios.all([this.putUnimedUsuario(this.dados.id, dataEdicao)])
                            .then(
                                Axios.spread((/* ...responses */) => {
                                    this.modalSucesso = true;
                                    this.mensagemSucesso = "Alteração dos dados realizada com sucesso!";
                                })
                            )
                            .finally(() => (this.loading = false));

                        break;

                    default:
                        break;
                }
            }
        },
        fecharSucesso() {
            this.modalSucesso = false;
            this.$emit("fechar");
        }
    }
};
</script>

<style lang="scss" scoped>
.conteudo-principal-container {
    margin-top: 24px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 20px;
    padding-bottom: 35px;

    & .container-aba {
        box-sizing: border-box;
        width: 100%;
        min-height: 400px;
        padding: 24px 32px;
    }

    .linha-rodape {
        padding: 0 24px;
    }
}
</style>
