<template>
    <tabela-busca
        :carregamento="loading"
        :estruturaPadrao="false"
        :rows="configuracoesTabela.rows"
        valorCheckboxTodos
        desativarCheckBox
        :cabecalho="cabecalho"
        ref="config"
    >
        <div slot="header">
            <unicom-campo-texto
                :valor="buscarAplicacao"
                @input="pesquisar"
                placeholder="Buscar aplicação na lista..."
                icone="search"
                ladoIcone="esquerdo"
                style="input { border: 1px solid #DEE2E6;} "
            />
        </div>
        <template v-slot:rows>
            <tr v-for="(row, index) in configuracoesTabela.rows" :key="index">
                <td>
                    <div class="corpo-texto" style="width:360px; white-space: nowrap;">
                        <unicom-checkbox v-model="checkbox" :texto="row.nomeAplicacao.texto" :inativo="true" />
                    </div>
                </td>

                <td>
                    <div class="corpo-texto" style="width:290px;">
                        <unicom-lista-pilulas
                            v-if="row.papeisAtivos"
                            ref="pilula"
                            :tasks="row.papeisAtivos"
                            itemTextFiltro="nome"
                            :itensMaximos="2"
                            @filtradas="lista => removerPilula(lista, index)"
                            @mostrar-mais="acao({ id: index })"
                            :key="row.id"
                        />
                    </div>
                </td>

                <td style="width:240px; padding-left:33px;">
                    <unicom-tooltip textoTooltip="Atribuir/Remover do usuário" tooltipLocalizacao="left-bottom">
                        <div slot="itemTooltip">
                            <div class="icone-acao" @click="acao(row.id)">
                                <unicom-icone nome="plus" :tamanho="15" textoComIcone />
                            </div>
                        </div>
                    </unicom-tooltip>

                    <ModalPapeis
                        v-if="row.id === idModal"
                        style="left: 50%; top: 15%;"
                        v-model="mostrarModal"
                        @fechar="fecharModal"
                        @papeis-selecionados="lista => adicionarPapeisSelecionados(lista, row.id)"
                        :papeis="row.papeis"
                        :papeisSelecionados="row.papeisAtivos ? Object.values(row.papeisAtivos) : []"
                        :indexColuna="index"
                        :key="row.id"
                    />
                </td>
            </tr>
        </template>
    </tabela-busca>
</template>

<script>
import TabelaBusca from "../TabelaBuscarLista/TabelaBusca";
import ModalPapeis from "../ModalPapeis";
import FiltroMixin from "./FiltroMixin";

export default {
    mixins: [FiltroMixin],

    components: {
        TabelaBusca,
        ModalPapeis
    },

    data() {
        return {
            aplicacoesSelecionadas: [],
            loading: false,
            buscarAplicacao: "",
            checkbox: true,
            indexModal: null,
            mostrarModal: false,
            carregamento: false,

            cabecalho: [
                { texto: "Nome da aplicação", icone: "maximize-2", largura: "540px" },
                { texto: "Papel do usuário", largura: "380px" },
                { texto: "Definir papéis", largura: "220px" }
            ],
            configuracoesTabela: { rows: [] },
            textoDoItem: "nome",
            idModal: null
        };
    },

    mounted() {
        this.montarTabela();
    },

    methods: {
        montarTabela() {
            this.configuracoesTabela.rows = [];
            this.$store.getters.filtrarAplicacoesSelecionadas.forEach(item => {
                this.configuracoesTabela.rows.push({
                    nomeAplicacao: {
                        texto: item.nome
                    },
                    papeisAtivos: item.papeisAtivos,
                    papeis: item.papeisAplicacao,
                    id: item.id
                });
            });
        },

        adicionarPapeisSelecionados(lista, id) {
            this.$store.commit("inserirPapeisAplicacao", { lista: lista, id: id });
            this.pesquisar(this.buscarAplicacao);
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async pesquisar(valor) {
            this.buscarAplicacao = valor;
            if (valor !== "") {
                this.configuracoesTabela.rows = [];
                let filtro = this.$store.getters.filtrarAplicacoesSelecionadas.filter(i =>
                    this.filtro(i, valor, this.pegaTextoDoItem(i))
                );

                this.$refs.config.$forceUpdate();

                filtro.forEach(item => {
                    this.configuracoesTabela.rows.push({
                        id: item.id,
                        nomeAplicacao: {
                            checkbox: true,
                            texto: item.nome,
                            qtdMaxCaracteres: 25
                        },
                        descricao: item.descricao,
                        papeisAtivos: item.papeisAtivos,
                        papeis: item.papeisAplicacao
                    });
                });
            } else {
                this.montarTabela();
            }
        },

        removerPilula(lista, index) {
            this.adicionarPapeisSelecionados({ papeis: lista, index: index });
        },

        acao(id) {
            this.mostrarModal = true;
            this.idModal = id;
        },

        fecharModal() {
            this.idModal = null;
            this.mostrarModal = false;
        }
    }
};
</script>

<style lang="scss"></style>
