<template>
    <div>
        <unicom-tabela
            :carregamento="loading"
            :icones="iconesUsadosTabela"
            :itensPorPagina="['5', '10', '15', '20']"
            :ordem="ordem"
            :pagina="pagina"
            :qtPaginas="qtPaginas"
            :qtResultados="qtResultados"
            :cabecalho="cabecalho"
            :rows="rows"
            :textoExibindo="'registros'"
            @troca-elemento-pagina="item => trocaElementoPagina(item)"
            @ordena="item => ordenar(item)"
            @troca-pagina="pagina => trocaPagina(pagina)"
            @acao="item => acao(item)"
        >
            <div slot="header">
                <unicom-linha :espacamento="true" :justificarItem="'end'">
                    <unicom-coluna :md="10">
                        <unicom-barra-pesquisa
                            :value="campoPesquisa"
                            placeholder="Buscar aplicação ou operação..."
                            @pesquisar="valor => pesquisar(valor)"
                        />
                    </unicom-coluna>
                    <unicom-coluna :md="2">
                        <unicom-botao :bloco="true" class="fonte-secundaria texto" @click="mostrarFiltro = true">
                            FILTRAR
                        </unicom-botao>
                    </unicom-coluna>
                </unicom-linha>
            </div>
        </unicom-tabela>
        <!-- TODO: TIRE POIS ESTAVA CRASHANDO MAS RETIRE ASSIM QUE -->
        <ModalWebService
            v-if="objetoOperacao"
            v-model="mostrarModal"
            :objetoDaOperacao="objetoOperacao"
            :objAuditoria="objAuditoria"
            @fechar="mostrarModal = false"
        />

        <FiltroWebService
            :value="mostrarFiltro"
            @fechar="mostrarFiltro = false"
            @filtrar="item => filtrar(item)"
            @limpar-filtro="limparFiltro()"
        />
    </div>
</template>

<script>
import FiltroWebService from "./FiltroWebService";
import ModalWebService from "./ModalWebServices";
//import Auditoria from '@/services/auditoria/Auditoria'
import AuditoriaServiceMixin from "../services/AuditoriaServiceMixin";

export default {
    mixins: [AuditoriaServiceMixin],

    components: {
        FiltroWebService,
        ModalWebService
    },

    data() {
        return {
            //Filtro
            campoPesquisa: "",
            ordem: "desc",
            pagina: 1,
            campoOrdenado: "dataHora",
            qtPaginas: 1,
            qtResultados: 0,
            descricao: "",
            tipoOperacao: "",
            unimedUsuario: "",
            status: '',
            aplicacaoPesquisada: "",
            iconesUsadosTabela: [
                {
                    nome: "eye",
                    tooltipNome: "Visualizar"
                }
            ],
            cabecalho: [
                {
                    texto: "Data e hora",
                    icone: "maximize-2"
                },
                {
                    texto: "Aplicação",
                    icone: "maximize-2"
                },
                {
                    texto: "Operação"
                },
                {
                    texto: "Usuário da operação"
                },
                {
                    texto: "Login do usuário"
                },
                {
                    texto: "IP"
                },
                {
                    texto: "Unimed do usuário"
                },
                {
                    texto: "Status",
                    icone: "maximize-2"
                },
                {
                    texto: "Detalhe"
                }
            ],
            rows: [],
            listaOperacoes: [],
            objetoOperacao: [],
            mostrarModal: false,
            mostrarFiltro: false,
            loading: false,
            objAuditoria: {},
            loginUsuario: ""
        };
    },
    async beforeMount() {
        this.getListaWebServices(this.getArgs());
    },
    methods: {
        montarTabela(objeto) {
            this.listaOperacoes = [];
            this.rows = [];

            this.listaOperacoes = objeto.conteudo;
            this.listaOperacoes.forEach(item => {
                this.rows.push({
                    dataHora: item.dataHora ? item.dataHora : "-",
                    aplicacao: item.nomeAplicacao ? item.nomeAplicacao : "-",
                    operacao: item.tipoOperacao ? item.tipoOperacao.descricao : "-",
                    usuarioOperacao: item.nomeUsuario ? item.nomeUsuario : "-",
                    loginUsuario: item.loginUsuario ? item.loginUsuario : "-",
                    ipUsuario: item.ip,
                    unimedUsuario: item.unimedUsuario ? item.unimedUsuario : "-",
                    status: {
                        tipo: "verdadeiroOuFalso",
                        valor: item.sucesso
                    }
                });
            });
            this.pagina = objeto.pagina;
            this.qtPaginas = objeto.qtPaginas;
            this.qtResultados = objeto.qtResultados;
        },

        trocaElementoPagina(item) {
            this.itensPorPagina = item;
            this.pagina = 1;
            this.paginar();
        },

        ordenar(item) {
            switch (item.index) {
                case 0:
                    this.campoOrdenado = "dataHora";
                    break;
                case 1:
                    this.campoOrdenado = "nomeAplicacao";
                    break;
                case 7:
                    this.campoOrdenado = "sucesso";
                    break;
            }
            this.ordem = item.ordem;
            this.paginar();
        },

        trocaPagina(item) {
            this.pagina = item.pagina;
            this.paginar();
        },

        acao(item) {
            let args = {
                idAuditoria: this.listaOperacoes[item.id].id
            };
            this.objAuditoria = args;
            this.getTransacao(args).then(response => {
                this.objetoOperacao = response.data;
                this.loginUsuario = this.listaOperacoes[item.id].loginUsuario;
                this.mostrarModal = true;
            });
        },

        paginar() {
            this.getListaWebServices();
        },

        filtrar(item) {
            let status = ''
            if(item.status[0] === 'SUCESSO'){
                status = true
            }else if(item.status[0] === 'FALHA'){
                status = false
            }

            this.pagina = 1;
            this.mostrarFiltro = false;
            this.aplicacao = item.aplicacaoModel;
            this.tipoOperacao = item.operacaoModel;
            this.unimedUsuario = item.unimedUsuario;
            this.status = status
            this.dataInicial = item.dataInicial
                ? `${item.dataInicial} ${item.horaInicial ? item.horaInicial : "00:00:00"}`
                : "";
            this.dataFinal = item.dataFinal ? `${item.dataFinal} ${item.horaFinal ? item.horaFinal : "23:59:59"}` : "";
            this.paginar();
        },

        pesquisar(valor) {
            this.pagina = 1
            this.campoPesquisa = valor;
            this.paginar();
        },

        limparFiltro() {
            this.mostrarFiltro = false;
            this.aplicacao = "";
            this.tipoOperacao = "";
            this.unimedUsuario = "";
            this.dataInicial = "";
            this.dataFinal = "";
            this.paginar();
        },

        getListaWebServices() {
            this.loading = true;
            this.getAuditoriaFiltro(this.getArgs())
                .then(response => {
                    this.montarTabela(response.data);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        getArgs() {
            return {
                direcaoOrdenacao: this.ordem,
                ordenacao: this.campoOrdenado,
                pagina: this.pagina,
                tamanhoPagina: this.itensPorPagina,
                codOrDescTipoAuditoria: 1,
                descricao: this.campoPesquisa,
                codOrDescTipoOperacao: this.tipoOperacao,
                codigoUnimedUsuario: this.unimedUsuario,
                dataHoraInicial: this.dataInicial,
                dataHoraFinal: this.dataFinal,
                sucesso: this.status,
                nomeAplicacao: this.aplicacao
            };
        }
    }
};
</script>

<style lang="scss" scoped></style>
