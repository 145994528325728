<template>
    <div class="gerenciar-usuarios-container">
        <unicom-linha :espacamento="true" :justificarItem="'end'">
            <unicom-coluna :md="colExportar">
                <h2 class="fonte-primaria negrito titulo">{{ tituloPagina }}</h2>

                <small class="fonte-secundaria texto">
                    Início >
                    <span class="negrito">Gerenciar Usuários</span>
                    <span v-if="edicao"> > Editar usuário</span>
                </small>
            </unicom-coluna>

            <unicom-coluna v-if="!edicao" :md="1" @click="mostrarModalExportar = true" class="icone">
                <unicom-icone nome="download" tooltipNome="Exportar" :tamanho="22" />
            </unicom-coluna>

            <unicom-coluna md="2">
                <unicom-botao
                    :tipo="'primario'"
                    :bloco="true"
                    @click="mostrarModal = true"
                    class="fonte-secundaria medio botao"
                    v-if="!edicao"
                >
                    CADASTRAR USUÁRIO
                </unicom-botao>
            </unicom-coluna>
        </unicom-linha>

        <template v-if="!edicao">
            <unicom-linha :espacamento="false">
                <unicom-coluna>
                    <div class="conteudo-principal-container">
                        <unicom-linha :espacamento="false">
                            <unicom-coluna>
                                <unicom-step :abas="abas" @click="index => trocaStep(index)" />
                            </unicom-coluna>
                        </unicom-linha>

                        <unicom-linha :espacamento="false">
                            <unicom-coluna>
                                <div class="container-aba">
                                    <TabelaColaboradores
                                        v-show="index === 0"
                                        ref="tabelaColaboradores"
                                        @enviar-carga="mostrarCarga = true"
                                        @filtrar="mostrarFiltro = true"
                                        @editar="usuario => editarUsuario(usuario, true)"
                                    />

                                    <TabelaFornecedoresJuridicos
                                        v-show="index === 1"
                                        ref="tabelaFornecedoresJuridicos"
                                        @enviar-carga="mostrarCarga = true"
                                        @filtrar="mostrarFiltro = true"
                                        @editar="usuario => editarUsuario(usuario, false)"
                                        @visualizar="usuario => visualizarUsuario(usuario, false)"
                                    />
                                </div>
                            </unicom-coluna>
                        </unicom-linha>
                    </div>
                </unicom-coluna>
            </unicom-linha>
        </template>

        <template v-else>
            <EditarUsuario
                :dadosUsuario="dadosUsuario"
                :isColaborador="colaborador"
                :somenteVisualizar="visualizar"
                @fechar="fecharEdicao"
                @atualizar="$emit('atualizar')"
            />
        </template>

        <ModalCadastrarUsuario
            v-model="mostrarModal"
            @fechar="mostrarModal = false"
            @sucesso-usuario="objeto => usuarioAdicionadoComSucesso(objeto)"
        />

        <ModalExportarUsuario
            v-if="mostrarModalExportar"
            v-model="mostrarModalExportar"
            @fechar="mostrarModalExportar = false"
            :abaSelecionada="index"
        />

        <ModalAnexo
            v-model="mostrarCarga"
            titulo="IMPORTAR USUÁRIOS"
            :linhasMaximas="'1.048.576'"
            :tamanhoMaximo="'500'"
            :tipoValor="'kb'"
            @fechar="mostrarCarga = false"
            @arquivo-recebido="importarArquivo"
            @download-exemplo="downloadArquivoExemplo()"
        />

        <FiltroUsuarios
            ref="filtroUsuarios"
            v-model="mostrarFiltro"
            :index="index"
            @fechar="mostrarFiltro = false"
            @filtrar="filtro => filtrarUsuario(filtro)"
            @limpar-filtro="limparFiltro()"
        />

        <unicom-modal-sucesso-acao
            v-model="mostrarModalSucesso"
            textoBotaoAcao="Atribuir aplicações"
            textoSucesso="O usuário foi cadastrado!"
            @fechar="mostrarModalSucesso = false"
            @evento-acao="atribuirAplicacoes"
        />

        <unicom-modal-sucesso
            v-model="mostrarModalSucessoCarga"
            titulo="Carga de usuários cadastrada com sucesso"
            @fechar="mostrarModalSucessoCarga = false"
        />

        <unicom-loading v-model="mostrarLoading" style="margin-top:15px;" mensagem="Enviando carga de usuários..." />

        <unicom-modal-loading :value="modalLoading" />
    </div>
</template>

<script>
import EditarUsuario from "./shared/EditarUsuario/EditarUsuario";
import ModalCadastrarUsuario from "./shared/CadastrarUsuario/ModalCadastrarUsuario";
import ModalExportarUsuario from "./shared/componentes/ModalExportarUsuario";
import ModalAnexo from "../shared/modalAnexo/ModalAnexo";
import FiltroUsuarios from "./shared/ListarUsuario/FiltroUsuarios";
import TabelaColaboradores from "./shared/ListarUsuario/TabelaColaboradores";
import TabelaFornecedoresJuridicos from "./shared/ListarUsuario/TabelaFornecedoresJuridicos";
import ColaboradorServiceMixin from "../../mixins/services/gerenciarUsuarios/ColaboradorServiceMixin";
import PessoaJuridicaMixinService from "./shared/services/PessoaJuridicaMixinService";

export default {
    components: {
        EditarUsuario,
        ModalCadastrarUsuario,
        ModalExportarUsuario,
        ModalAnexo,
        FiltroUsuarios,
        TabelaColaboradores,
        TabelaFornecedoresJuridicos
    },
    mixins: [ColaboradorServiceMixin, PessoaJuridicaMixinService],
    data() {
        return {
            colaborador: false,
            edicao: false,
            index: 0,
            abas: [
                {
                    nome: "COLABORADORES",
                    ativo: true,
                    atual: true
                },
                {
                    nome: "JURÍDICOS / FORNECEDORES",
                    ativo: true,
                    atual: false
                }
            ],
            dadosUsuario: {},
            mostrarLoading: false,
            modalLoading: false,
            mostrarCarga: false,
            mostrarFiltro: false,
            mostrarModal: false,
            mostrarModalSucesso: false,
            mostrarModalSucessoCarga: false,
            mostrarModalExportar: false,
            visualizar: false
        };
    },
    methods: {
        trocaStep(index) {
            this.abas[this.index].atual = false;
            this.index = index;
            this.abas[index].atual = true;
        },
        fecharEdicao() {
            this.edicao = false;
        },
        editarUsuario(usuario, isColaborador) {
            this.verificaEstadoConselho(usuario, isColaborador);
            this.colaborador = isColaborador;
            this.dadosUsuario = usuario;
            this.edicao = true;
            this.visualizar = false;
        },
        visualizarUsuario(usuario, isColaborador) {
            this.verificaEstadoConselho(usuario);
            this.colaborador = isColaborador;
            this.dadosUsuario = usuario;
            this.edicao = true;
            this.visualizar = true;
        },
        verificaEstadoConselho(usuario, isColaborador) {
            if (isColaborador) usuario.conselhoProfissional.estado = usuario.conselhoProfissional.estado || "";
        },

        usuarioAdicionadoComSucesso(objeto) {
            this.mostrarModalSucesso = true;
            this.colaborador = objeto.isColaborador;
            this.dadosUsuario = objeto.usuario;
            this.atualizarListagem();
        },
        atualizarListagem() {
            if (this.index === 0) {
                this.$refs.tabelaColaboradores.getListaUsuarios("");
            } else {
                this.$refs.tabelaFornecedoresJuridicos.getListaJuridicos("");
            }
            this.$refs.filtroUsuarios.getDominios();
        },
        atribuirAplicacoes() {
            this.edicao = true;
        },
        filtrarUsuario(filtro) {
            if (this.index === 0) {
                this.$refs.tabelaColaboradores.filtrar(filtro);
            } else if (this.index === 1) {
                this.$refs.tabelaFornecedoresJuridicos.filtrar(filtro);
            }
        },
        limparFiltro() {
            if (this.index === 0) {
                this.$refs.tabelaColaboradores.limparFiltro();
            } else if (this.index === 1) {
                this.$refs.tabelaFornecedoresJuridicos.limparFiltro();
            }
        },
        importarArquivo(arquivo) {
            this.modalLoading = true;

            let formData = new FormData();
            formData.append("arquivo", arquivo);

            switch (this.index) {
                case 0:
                    this.putImportarCargaColaborador(formData)
                        .then(() => {
                            this.mostrarModalSucessoCarga = true;
                            this.mostrarCarga = false;
                            this.atualizarListagem();
                        })
                        .catch(() => (this.mostrarCarga = false))
                        .finally(() => (this.modalLoading = false));

                    break;

                case 1:
                    this.putImportarCargaPessoaJuridica(formData)
                        .then(() => {
                            this.mostrarModalSucessoCarga = true;
                            this.mostrarCarga = false;
                        })
                        .catch(() => (this.mostrarCarga = false))
                        .finally(() => (this.modalLoading = false));

                    break;

                default:
                    this.modalLoading = false;
                    this.mostrarCarga = false;
                    console.error("Err: importação do arquivo -", "index inválido");
                    break;
            }
        },
        downloadArquivoExemplo() {
            let a = document.createElement("a");

            if (this.index === 0) {
                a.href = "assets/exemplos/Importar_Colaborador.csv";
                a.download = "Importar_Colaborador.csv";
            }

            if (this.index === 1) {
                a.href = "assets/exemplos/Importar_Pessoa_Juridica.csv";
                a.download = "Importar_Pessoa_Juridica.csv";
            }

            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    },
    watch: {
        index(i) {
            if (i == 0) this.$refs.filtroUsuarios.getListaUsuarios();

            if (i == 1) this.$refs.filtroUsuarios.getListaJuridicos();
        }
    },
    computed: {
        tituloPagina() {
            return this.edicao ? "EDITAR USUÁRIO" : "GERENCIAR USUÁRIOS";
        },
        colExportar() {
            return this.edicao ? 10 : 9;
        }
    }
};
</script>

<style lang="scss" scoped>
.gerenciar-usuarios-container {
    margin: 35px;
    box-sizing: border-box;
    width: calc(100% - 70px);
    color: #60656c;

    h2 {
        margin-bottom: 8px;
    }

    .botao {
        display: block;
        margin-top: 3px;
    }

    .icone {
        display: block;
        margin-top: 10px;
        cursor: pointer;
        text-align: end;
    }

    .conteudo-principal-container {
        margin-top: 24px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 20px;

        & .container-aba {
            box-sizing: border-box;
            width: 100%;
            min-height: 400px;
            padding: 24px 32px;
        }
    }
}
</style>
