<template>
    <unicom-modal v-model="mostrar" :largura="720" fechar @input="fecharModal" class="modal">
        <div slot="header">
            <h3 class="fonte-primaria titulo negrito">Exportar Aplicações</h3>
        </div>

        <div slot="body" class="body">
            <unicom-loading class="loading" v-model="loading" v-if="loading" :mensagem="message" />

            <div v-else>
                <ValidationObserver ref="observer">
                    <unicom-linha :espacamento="true" justificarItem="center">
                        <unicom-coluna :md="6" alinhamento="center">
                            <unicom-autocomplete
                                v-model="unimedSelecionada"
                                :items="unimeds"
                                titulo="Unimed"
                                textoDoItem="codigoNome"
                                placeholder="Listagem de Unimeds"
                                larguraMenuProp="90"
                                :desativado="!isUBRAdmin"
                            />
                        </unicom-coluna>

                        <unicom-coluna :md="6" alinhamento="center">
                            <validation-provider rules="required" mode="eager" v-slot="{ errors }">
                                <unicom-autocomplete
                                    v-model="aplicacaoSelecionada"
                                    :items="aplicacoes"
                                    titulo="Aplicação *"
                                    textoDoItem="nome"
                                    placeholder="Listagem de Aplicações"
                                    larguraMenuProp="90"
                                    :mensagemErro="errors[0]"
                                />
                            </validation-provider>
                        </unicom-coluna>
                    </unicom-linha>

                    <unicom-linha style="padding-top:30px; padding-left: 15px" justificarItem="start">
                        <unicom-coluna style="padding: 0px" md="12">
                            <p class="fonte-secundaria texto">Status dos Usuários</p>
                        </unicom-coluna>

                        <RadioButton
                            label="Todos"
                            valorRadio="Todos"
                            name="statusUsuario"
                            :value="statusUsuario"
                            @change="e => (statusUsuario = e)"
                        />

                        <RadioButton
                            label="Ativo"
                            valorRadio="Ativo"
                            name="statusUsuario"
                            :value="statusUsuario"
                            @change="e => (statusUsuario = e)"
                        />

                        <RadioButton
                            label="Inativo"
                            valorRadio="Inativo"
                            name="statusUsuario"
                            :value="statusUsuario"
                            @change="e => (statusUsuario = e)"
                        />
                    </unicom-linha>

                    <unicom-linha style="padding-top:30px; padding-left: 15px" justificarItem="start">
                        <unicom-coluna style="padding: 0px" md="12">
                            <p class="fonte-secundaria texto">Tipo de Usuário</p>
                        </unicom-coluna>

                        <RadioButton
                            label="Colaboradores"
                            valorRadio="Colaboradores"
                            name="tipoUsuario"
                            :value="tipoUsuario"
                            @change="e => (tipoUsuario = e)"
                        />

                        <RadioButton
                            label="Jurídicos"
                            valorRadio="Jurídicos"
                            name="tipoUsuario"
                            :value="tipoUsuario"
                            @change="e => (tipoUsuario = e)"
                        />
                    </unicom-linha>
                </ValidationObserver>
            </div>
        </div>

        <div slot="footer">
            <unicom-linha :espacamento="false" justificarItem="end">
                <unicom-coluna :md="3">
                    <unicom-botao tipo="secundario" @click="limpar()">LIMPAR</unicom-botao>
                </unicom-coluna>

                <unicom-coluna :md="3">
                    <unicom-botao tipo="primario" @click="exportar()">EXPORTAR</unicom-botao>
                </unicom-coluna>
            </unicom-linha>
        </div>
    </unicom-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { saveAs } from "file-saver";

import Aplicacao from "../../../../services/gerenciarAplicacoes/Aplicacao";

import ColaboradorServiceMixin from "@/mixins/services/gerenciarUsuarios/ColaboradorServiceMixin";
import PessoaJuridicaServiceMixin from "@/mixins/services/gerenciarUsuarios/PessoaJuridicaServiceMixin";
import UnimedServiceMixin from "../../../../mixins/services/dominios/UnimedServiceMixin";

import RadioButton from "../../../shared/radioButton/RadioButton";

export default {
    mixins: [UnimedServiceMixin, ColaboradorServiceMixin, PessoaJuridicaServiceMixin],
    components: { RadioButton, ValidationObserver, ValidationProvider },
    props: {
        value: {
            type: Boolean,
            default: true
        },
        abaSelecionada: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            usuario: this.getSession(),

            mostrar: this.value,
            message: "Carregando dados...",
            loading: true,
            isUBRAdmin: false,

            unimeds: [],
            unimedSelecionada: "",
            aplicacoes: [],
            aplicacaoSelecionada: "",
            statusUsuario: "Todos",
            tipoUsuario: this.abaSelecionada == 0 ? "Colaboradores" : "Jurídico"
        };
    },
    beforeMount() {
        this.isUBRAdmin = this.usuario.usuarioUnimed.perfil.id === -1;

        this.listaUnimeds();
        this.listaAplicacoes();
    },
    methods: {
        fecharModal(valor) {
            if (!valor) {
                this.$emit("fechar");
            }
        },
        listaUnimeds() {
            this.unimeds = this.getUnimedsList();

            if (!this.isUBRAdmin) {
                const unimedUsuarioId = this.usuario.usuarioUnimed.unimed.codigo;

                this.unimeds = this.unimeds.filter(it => it.codigo == unimedUsuarioId);
                this.unimedSelecionada = this.unimeds[0].nome;
            }
        },
        async listaAplicacoes() {
            const aplicacao = new Aplicacao(this.$appConfig.config.API);

            await aplicacao
                .getComboAplicacoesAtivas()
                .then(({ data }) => (this.aplicacoes = data))
                .finally(() => (this.loading = false));
        },
        limpar() {
            this.$refs.observer.reset();

            if (this.isUBRAdmin) this.unimedSelecionada = "";
            this.aplicacaoSelecionada = "";
        },
        async exportar() {
            if (await this.$refs.observer.validate()) {
                this.message = "Baixando arquivo . . .";
                this.loading = true;
                const unimed = this.unimeds.find(it => it.nome == this.unimedSelecionada);
                const aplicacao = this.aplicacoes.find(it => it.nome == this.aplicacaoSelecionada);
                const args = {
                    aplicacaoId: aplicacao.id,
                    aplicacaoNome: aplicacao.nome,
                    statusUsuario: this.statusUsuario.toUpperCase()
                };

                if (unimed) args.unimedId = unimed.codigo;

                await this.exportarPromise(args)
                    .then(({ data, request }) => {
                        const contentType = request.getResponseHeader("Content-Type");

                        const blob = new Blob([data], { type: contentType });

                        const filename = request
                            .getResponseHeader("Content-Disposition")
                            .split("filename=")[1]
                            .replace(/(["])/g, "");

                        saveAs(blob, filename);
                    })
                    .finally(() => (this.loading = false));
            }
        },
        exportarPromise(args) {
            return this.tipoUsuario.toUpperCase() === "COLABORADORES"
                ? this.getColaboradoresExportar(args)
                : this.getPessoaJuridicaExportar(args);
        }
    },
    watch: {
        value() {
            this.mostrar = this.value;
        }
    }
};
</script>

<style lang="scss" scoped>
.modal {
    .body {
        min-height: 250px;

        .loading {
            padding-top: 50px;
        }
    }

    h3 {
        color: #00995d;
        font-size: 30px;
        margin-bottom: 24px;
        text-align: center;
    }
}
</style>
