import Axios from "axios";

var ListaOperacoesServiceMixin = {
    methods: {
        getListaOperacoes() {
            return Axios.get(this.CriarUrl({ resource: "api/dominio/tipos-operacoes" }));
        },
        getListaOperacoesServicos() {
            return Axios.get(this.CriarUrl({ resource: "api/dominio/tipos-operacoes/servico" }));
        },
        getListaOperacoesAdministrativas() {
            return Axios.get(this.CriarUrl({ resource: "api/dominio/tipos-operacoes/administrativas" }));
        }
    }
};

export default ListaOperacoesServiceMixin;
