import Axios from "axios";

var UsuarioUnimed = {
    methods: {
        putAtualizarDadosBasicos(body) {
            return Axios.put(this.CriarUrl({ resource: `api/usuario/alterar-dados-basicos` }), body, { withCredentials: true });
        },

        putUnimedUsuario(id, unimeds) {
            return Axios.put(this.CriarUrl({ resource: `api/usuario-unimed/${id}` }), unimeds, {
                withCredentials: true
            });
        },

        deleteUnimedUsuario(idUsuario, codigoUnimed) {
            return Axios.delete(this.CriarUrl({ resource: `api/usuario-unimed/${idUsuario}/unimed/${codigoUnimed}` }), {
                withCredentials: true
            });
        },

        postAdicionarPapel(data) {
            return Axios.post(this.CriarUrl({ resource: `api/usuario-unimed/adicionar-papel` }), data, {
                withCredentials: true
            });
        },

        deleteRemoverPapel(data) {
            return Axios.post(this.CriarUrl({ resource: `api/usuario-unimed/remover-papel` }), data, {
                withCredentials: true
            });
        },

        deleteRemoverTodosPapeisAplicacao(idAplicacao, idUnimed, idUsuario) {
            return Axios.delete(
                this.CriarUrl({
                    resource: `api/usuario-unimed/remover-todos-papeis-por-usuario-unimed-aplicacao`,
                    params: {
                        idUsuario: idUsuario,
                        idUnimed: idUnimed,
                        idAplicacao: idAplicacao
                    }
                }),
                { withCredentials: true }
            );
        }
    }
};

export default UsuarioUnimed;
