<template>
    <div class="auditoria-container">
        <unicom-linha :espacamento="false">
            <unicom-coluna>
                <h2 class="fonte-primaria negrito titulo">Auditoria</h2>
                <small class="fonte-secundaria texto">Minhas aplicações > <span class="negrito">Auditoria</span></small>
            </unicom-coluna>
        </unicom-linha>
        <unicom-linha :espacamento="false">
            <unicom-coluna>
                <div class="conteudo-principal-container">
                    <CartaoBase />
                </div>
            </unicom-coluna>
        </unicom-linha>
    </div>
</template>

<script>
import CartaoBase from "./shared/CartaoBase";

export default {
    components: {
        CartaoBase
    },
    data() {
        return {};
    },
    methods: {}
};
</script>

<style lang="scss" scoped>
.auditoria-container {
    margin: 35px;
    box-sizing: border-box;
    width: calc(100% - 70px);
    color: #60656c;

    h2 {
        margin-bottom: 8px;
    }

    .conteudo-principal-container {
        margin-top: 24px;
    }
}
</style>
