<template>
    <tabela-busca
        :carregamento="loading"
        :rows="configuracoesTabela.rows"
        :rowsSemFiltro="rowsSemFiltro"
        :cabecalho="cabecalho"
        novoIndex="id"
        :checkSelecionadosIndex="false"
        @aplicacoes-selecionadas="item => aplicacaoSelecionada(item)"
        @ordenar="index => ordenarTabela(index)"
        :valoresSelecionados="$store.getters.aplicacacoesSelecionadas"
    >
        <div slot="header">
            <unicom-campo-texto
                :valor="buscarAplicacao"
                placeholder="Buscar aplicação na lista..."
                icone="search"
                ladoIcone="esquerdo"
                style="input { border: 1px solid #DEE2E6;} "
                @input="pesquisar"
            />
        </div>
    </tabela-busca>
</template>

<script>
import TabelaBusca from "../TabelaBuscarLista/TabelaBusca";
import FiltroMixin from "./FiltroMixin";

export default {
    mixins: [FiltroMixin],

    components: {
        TabelaBusca
    },

    data() {
        return {
            aplicacoesSelecionadas: [],
            loading: false,
            buscarAplicacao: "",

            cabecalho: [
                {
                    texto: "Nome da aplicação",
                    icone: "maximize-2",
                    largura: "248px"
                },
                {
                    texto: "Descrição da aplicação",
                    largura: "565px"
                }
            ],
            textoDoItem: "nome",

            configuracoesTabela: {
                lista: [],
                rows: []
            },
            inx: [],
            rowsSemFiltro: []
        };
    },

    mounted() {
        this.montarTabela();
    },

    methods: {
        ordenarTabela(index) {
            if (index === 0) {
                let novoArray = [];
                for (let i = this.configuracoesTabela.rows.length - 1; i >= 0; i--) {
                    novoArray.push(this.configuracoesTabela.rows[i]);
                }
                this.configuracoesTabela.rows = novoArray;
            }
        },
        aplicacaoSelecionada(item) {
            this.inx = [];
            this.inx = item;
            this.$store.commit("aplicacacoesSelecionadas", this.inx);
        },

        pesquisar(valor) {
            this.buscarAplicacao = valor;
            if (valor !== "") {
                this.configuracoesTabela.rows = [];
                this.$store.getters.listaAplicacoesPapeis.forEach(item => {
                    this.configuracoesTabela.rows.push({
                        nomeAplicacao: {
                            checkbox: true,
                            texto: item.nome,
                            qtdMaxCaracteres: 25
                        },
                        descricao: item.descricao,
                        naoMostrar: {
                            id: item.id,
                            naoMostrar: true,
                            mostrar: this.filtro(item, this.buscarAplicacao, this.pegaTextoDoItem(item))
                        }
                    });
                });
            } else {
                this.montarTabela();
            }
        },
        montarTabela() {
            this.configuracoesTabela.rows = [];
            this.$store.getters.listaAplicacoesPapeis.forEach(item => {
                this.configuracoesTabela.rows.push({
                    nomeAplicacao: {
                        checkbox: true,
                        texto: item.nome,
                        qtdMaxCaracteres: 25
                    },
                    descricao: item.descricao,
                    naoMostrar: {
                        id: item.id,
                        naoMostrar: true,
                        mostrar: true
                    }
                });
            });
            this.rowsSemFiltro = this.configuracoesTabela.rows.map(e => e);
        }
    }
};
</script>

<style lang="scss"></style>
