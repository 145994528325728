<template>
    <div class="cartaoMobile" v-if="mostrar">
        <div class="header">
            <p class="fonte-secundaria negrito corTextoSecundaria tituloHeader">Papéis da aplicação</p>
            <unicom-icone class="icone-fechar" :nome="'x'" :tamanho="18" @click="fecharModal" />
        </div>

        <div class="body">
            <unicom-linha v-for="(papel, index) in papeis" :key="index" class="papeis">
                <unicom-coluna md="1">
                    <unicom-checkbox
                        class="checkbox"
                        v-model="checkBoxSelecionado"
                        :value="papel.id"
                        :inativo="liberarCheckBox(papel)"
                    />
                </unicom-coluna>

                <unicom-coluna md="10">
                    <div class="texto">{{ papel.nome }}</div>
                </unicom-coluna>
            </unicom-linha>
        </div>

        <div class="footer">
            <p class="fonte-secundaria corTextoSecundaria">
                papéis do usuário {{ quantidadePapeisSelecionados }}/{{ quantidadePapeis }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true
        },
        papeis: {
            type: Array,
            required: false,
            default: () => []
        },
        papeisSelecionados: {
            type: Array,
            default: () => [],
            required: true
        },
        indexColuna: {
            type: Number,
            default: -1,
            required: true
        }
    },
    data() {
        return {
            mostrar: this.value,
            sessaoUsuario: this.getSession(),
            checkBoxSelecionado: this.papeisSelecionados.map(el => el.id)
        };
    },
    methods: {
        liberarCheckBox(papel) {
            return this.sessaoUsuario.usuarioUnimed.perfil.id === -1 ? false : papel.adminOnly;
        },
        fecharModal() {
            this.$emit("fechar");
        }
    },
    watch: {
        value() {
            this.mostrar = this.value;
        },

        checkBoxSelecionado() {
            this.$emit("papeis-selecionados", {
                index: this.indexColuna,
                papeis: this.papeis.filter(item => {
                    return this.checkBoxSelecionado.includes(item.id);
                })
            });
        }
    },

    computed: {
        quantidadePapeis() {
            return this.papeis.length;
        },

        quantidadePapeisSelecionados() {
            return this.checkBoxSelecionado.length;
        }
    }
};
</script>

<style lang="scss" scoped>
.cartaoMobile {
    position: absolute;
    z-index: 9999;
    height: 250px;
    width: 250px;

    box-sizing: border-box;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.22);
    border-radius: 10px;
    border: none;
    background-color: #fff;

    .header {
        position: relative;
        display: inline-flex;
        padding: 15px 18px 8px 18px;
        margin: 0px;

        border-bottom: 1px solid #f1f2f1;

        .tituloHeader {
            text-align: start;
        }

        .icone-fechar {
            margin-left: 69px;
            box-sizing: border-box;
            color: #9e9e9e;
            &:hover {
                opacity: 0.9;
                color: grey;
                cursor: pointer;
            }
            &:active {
                opacity: 0.5;
            }
        }
    }

    .body {
        width: auto;
        height: 160px;
        overflow-y: scroll;

        padding: 15px;
        padding-top: 0px;

        .papeis {
            padding: 10px;
            border-bottom: 1px solid #f1f2f1;

            .texto {
                padding-left: 10px;
                padding-top: 2px;
            }
        }
    }

    .footer {
        position: absolute;
        bottom: 6px;
        right: 6px;

        p {
            text-align: end;
        }
    }
}
</style>
