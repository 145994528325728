<template>
    <div>
        <unicom-tabela
            :carregamento="loading"
            :icones="iconesUsadosTabela"
            :itensPorPagina="['5', '10', '15', '20']"
            :ordem="ordem"
            :pagina="pagina"
            :qtPaginas="qtPaginas"
            :qtResultados="qtResultados"
            :cabecalho="cabecalho"
            :rows="rows"
            :textoExibindo="'registros'"
            @troca-elemento-pagina="item => trocaElementoPagina(item)"
            @ordena="item => ordenar(item)"
            @troca-pagina="pagina => trocaPagina(pagina)"
            @acao="item => acao(item)"
        >
            <div slot="header">
                <unicom-linha :espacamento="true" :justificarItem="'end'">
                    <unicom-coluna :md="9">
                        <unicom-barra-pesquisa
                            :value="campoPesquisa"
                            :placeholder="'Buscar nome ou login do administrador...'"
                            @pesquisar="pesquisarLoginOuNome"
                        />
                    </unicom-coluna>
                    <unicom-coluna :md="2" style="margin-right:13px">
                        <unicom-botao :bloco="true" class="fonte-secundaria texto" @click="mostrarFiltro = true">
                            FILTRAR
                        </unicom-botao>
                    </unicom-coluna>
                </unicom-linha>
            </div>
        </unicom-tabela>

        <ModalOperacao
            v-if="objetoDetalhesOperacao"
            v-model="mostrarModal"
            :objetoDaOperacao="objetoDetalhesOperacao"
            :objAuditoria="objAuditoria"
            @fechar="mostrarModal = false"
        />

        <FiltroOperacao
            :value="mostrarFiltro"
            @fechar="mostrarFiltro = false"
            @filtrar="item => filtrar(item)"
            @limpar-filtro="limparFiltro()"
        />
    </div>
</template>

<script>
import FiltroOperacao from "./FiltroOperacoes";
import ModalOperacao from "./ModalOperacao";
import AuditoriaServiceMixin from "../services/AuditoriaServiceMixin";

export default {
    mixins: [AuditoriaServiceMixin],

    components: {
        ModalOperacao,
        FiltroOperacao
    },

    data() {
        return {
            loading: false,
            mostrarModal: false,
            mostrarFiltro: false,

            // FILTRO
            campoPesquisa: "",
            tipoOperacao: "",
            unimedUsuario: "",
            dataHoraInicial: "",
            dataHoraFinal: "",

            // TABELA
            ordem: "desc",
            pagina: 1,
            qtPaginas: 1,
            qtResultados: 0,
            itensPorPagina: 5,
            campoOrdenado: "dataHora",
            iconesUsadosTabela: [
                {
                    nome: "eye",
                    tooltipNome: "Visualizar"
                }
            ],
            cabecalho: [
                {
                    texto: "Data e hora",
                    icone: "maximize-2"
                },
                {
                    texto: "Operação",
                    icone: "maximize-2"
                },
                {
                    texto: "Nome do Usuário"
                },
                {
                    texto: "Login do Usuário"
                },
                {
                    texto: "IP do Usuário"
                },
                {
                    texto: "Unimed do Usuário"
                },
                {
                    texto: "Status",
                    icone: "maximize-2"
                },
                {
                    texto: "Detalhes"
                }
            ],
            rows: [],
            listaOperacoes: [],
            objetoDetalhesOperacao: [],
            loginUsuario: "",

            objAuditoria: {}
        };
    },

    async beforeMount() {
        this.getListaOperacoes(this.getArgs());
    },

    methods: {
        montarTabela(objeto) {
            this.listaOperacoes = [];
            this.rows = [];

            this.listaOperacoes = objeto.conteudo;
            this.listaOperacoes.forEach(item => {
                this.rows.push({
                    dataHora: item.dataHora ? item.dataHora : "-",
                    operacao: item.tipoOperacao ? item.tipoOperacao.descricao : "-",
                    nomeAdministrador: item.nomeUsuario ? item.nomeUsuario : "-",
                    loginAdministrador: item.loginUsuario ? item.loginUsuario : "-",
                    ipAdministrador: item.ip,
                    unimedAdministrador: item.unimedUsuario,
                    status: {
                        tipo: "verdadeiroOuFalso",
                        valor: item.sucesso
                    }
                });
            });
            this.pagina = objeto.pagina;
            this.qtPaginas = objeto.qtPaginas;
            this.qtResultados = objeto.qtResultados;
        },

        trocaElementoPagina(item) {
            this.itensPorPagina = item;
            this.pagina = 1;
            this.paginar();
        },

        ordenar(item) {
            switch (item.index) {
                case 0:
                    this.campoOrdenado = "dataHora";
                    break;
                case 1:
                    this.campoOrdenado = "acaoRealizada.descricao";
                    break;
                case 6:
                    this.campoOrdenado = "sucesso";
                    break;
            }
            this.ordem = item.ordem;
            this.paginar();
        },

        trocaPagina(item) {
            this.pagina = item.pagina;
            this.itensPorPagina = item.itensPorPagina;
            this.paginar();
        },

        acao(item) {
            const args = { idAuditoria: this.listaOperacoes[item.id].id };
            this.objAuditoria = args;

            this.getTransacao(args).then(response => {
                this.objetoDetalhesOperacao = response.data;
                this.loginUsuario = this.listaOperacoes[item.id].loginUsuario;
                this.mostrarModal = true;
            });
        },

        paginar() {
            this.getListaOperacoes(this.getArgs());
        },

        pesquisarLoginOuNome(valor) {
            this.pagina = 1;
            this.campoPesquisa = valor;
            this.getListaOperacoes(this.getArgs());
        },

        filtrar(item) {
            this.mostrarFiltro = false;
            this.pagina = 1;
            this.tipoOperacao = item.operacao;
            this.unimedUsuario = item.unimedUsuario;
            if (item.dataInicial) {
                this.dataHoraInicial = `${item.dataInicial} ${item.horaInicial ? item.horaInicial : "00:00:00"}`;
            }
            if (item.dataFinal) {
                this.dataHoraFinal = `${item.dataFinal} ${item.horaFinal ? item.horaFinal : "23:59:59"}`;
            }
            this.paginar();
        },

        limparFiltro() {
            this.mostrarFiltro = false;
            this.tipoOperacao = "";
            this.unimedUsuario = "";
            this.dataHoraInicial = "";
            this.dataHoraFinal = "";
            this.paginar();
        },

        getListaOperacoes() {
            this.loading = true;
            this.getAuditoriaFiltro(this.getArgs())
                .then(response => {
                    this.montarTabela(response.data);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        getArgs() {
            return {
                direcaoOrdenacao: this.ordem,
                ordenacao: this.campoOrdenado,
                pagina: this.pagina,
                tamanhoPagina: this.itensPorPagina,
                codOrDescTipoAuditoria: 0,
                nomeAplicacao: this.aplicacao,
                codOrDescTipoOperacao: this.tipoOperacao,
                codigoUnimedUsuario: this.unimedUsuario,
                dataHoraInicial: this.dataHoraInicial,
                dataHoraFinal: this.dataHoraFinal,
                descricao: this.campoPesquisa
            };
        }
    }
};
</script>

<style lang="scss" scoped></style>
