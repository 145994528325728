import Axios from "axios";

var PessoaJuridicaviceMixin = {
    methods: {
        getPessoaJuridicaExportar(args) {
            return Axios.get(
                this.CriarUrl({
                    resource: "api/juridicos/exportar",
                    params: args
                }),
                { withCredentials: true, responseType: "blob" }
            );
        }
    }
};

export default PessoaJuridicaviceMixin;
