<template>
    <div>
        <ValidationObserver ref="buscaUsuario">
            <unicom-linha :espacamento="true" class="linha-cadastro primeira-linha">
                <unicom-coluna :md="8">
                    <validation-provider rules="required" mode="eager" v-slot="{ errors }">
                        <unicom-campo-texto
                            :mask="'##.###.###/####-##'"
                            apenasNumero
                            v-model="usuario.documento.numeroDocumento"
                            :titulo="'CNPJ *'"
                            :placeholder="'Insira o CNPJ'"
                            :mensagemErro="errors[0]"
                        />
                    </validation-provider>
                </unicom-coluna>
                <unicom-coluna :md="4">
                    <unicom-botao
                        :tipo="'primario'"
                        bloco
                        contornado
                        class="fonte-secundaria texto botao"
                        @click="buscarUsuario()"
                    >
                        <div class="botao-interno">
                            <unicom-linha :espacamento="false" :justificarItem="'center'">
                                <unicom-coluna :md="2">
                                    <unicom-icone :nome="'search'" :tamanho="18" />
                                </unicom-coluna>
                                <unicom-coluna :md="10">
                                    <p>{{ campoBotao }}</p>
                                </unicom-coluna>
                            </unicom-linha>
                        </div>
                    </unicom-botao>
                </unicom-coluna>
            </unicom-linha>
        </ValidationObserver>

        <unicom-loading v-if="loading" v-model="loading" style="margin-top:65px;" mensagem="Por favor aguarde..." />

        <div v-else>
            <ValidationObserver ref="pessoaJuridica">
                <unicom-linha :espacamento="true" class="linha-cadastro">
                    <unicom-coluna>
                        <validation-provider rules="required" mode="eager" v-slot="{ errors }">
                            <unicom-campo-texto
                                v-model="usuario.nome"
                                apenasTextoNumero
                                :maxCaracteres="'60'"
                                :titulo="'Razão social *'"
                                :placeholder="'Insira a razão social'"
                                :desativado="camposBloqueados"
                                :mensagemErro="errors[0]"
                            />
                        </validation-provider>
                    </unicom-coluna>
                </unicom-linha>

                <unicom-linha :espacamento="true" class="linha-cadastro">
                    <unicom-coluna>
                        <validation-provider rules="email|required" mode="eager" v-slot="{ errors }">
                            <unicom-campo-texto
                                v-model="usuario.email"
                                :maxCaracteres="'60'"
                                :titulo="'Email *'"
                                :placeholder="'Insira o email'"
                                :desativado="camposBloqueados"
                                :mensagemErro="errors[0]"
                            />
                        </validation-provider>
                    </unicom-coluna>
                </unicom-linha>

                <unicom-linha :espacamento="true" class="linha-cadastro">
                    <unicom-coluna>
                        <validation-provider rules="required|telRequired" mode="eager" v-slot="{ errors }">
                            <unicom-campo-texto
                                v-model="usuario.telefone"
                                apenasNumero
                                :mask="['(##) ####-####', '(##) #####-####']"
                                :titulo="'Telefone comercial *'"
                                :placeholder="'Insira o telefone'"
                                :desativado="camposBloqueados"
                                :mensagemErro="errors[0]"
                            />
                        </validation-provider>
                    </unicom-coluna>
                </unicom-linha>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PrestadorServiceMixin from "../services/PrestadorServiceMixin";

export default {
    mixins: [PrestadorServiceMixin],

    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            loading: false,
            camposBloqueados: true,
            usuario: {
                documento: {
                    numeroDocumento: "",
                    tipoDocumento: "CNPJ"
                },
                fornecedor: false,
                nome: "",
                email: "",
                telefone: ""
            }
        };
    },
    computed: {
        campoBotao() {
            return this.loading ? "BUSCANDO..." : "BUSCAR USUÁRIO";
        }
    },
    methods: {
        async buscarUsuario() {
            let validacaoBusca = await this.$refs.buscaUsuario.validate();

            if (validacaoBusca) {
                this.loading = true;
                await this.getUsuario();
            }
        },

        limparBusca() {
            this.usuario = {
                documento: {
                    numeroDocumento: "",
                    tipoDocumento: "CNPJ"
                },
                nome: "",
                email: "",
                telefone: ""
            };
        },

        getUsuario() {
            this.getPrestador(this.usuario.documento.numeroDocumento)
                .then(({ data }) => {
                    let prestador = {};
                    let validou = false;

                    if (data) {
                        const dados = data;

                        this.usuario.nome = dados.nome;
                        this.usuario.email = dados.email;
                        this.usuario.telefone = dados.telefone;

                        validou = true;
                    } else {
                        this.usuario = {
                            documento: {
                                numeroDocumento: this.usuario.documento.numeroDocumento,
                                tipoDocumento: "CNPJ"
                            },
                            nome: "",
                            email: "",
                            telefone: ""
                        };

                        validou = false;
                    }

                    prestador = {
                        conselhoProfissional: { numeroConselho: "", siglaEstado: "", tipoConselho: "" },
                        tipoColaborador: "",
                        tipoColaboradorModel: "",
                        unimeds: [{ codigo: "", unimed: "", idPerfil: null }]
                    };

                    this.$emit("buscou", { validou, prestador });
                })
                .finally(() => {
                    this.camposBloqueados = false;
                    this.loading = false;
                });
        },

        async validarEtapa() {
            return await this.$refs.pessoaJuridica.validate();
        },

        montarUsuario() {
            this.usuario.documento.numeroDocumento = this.usuario.documento.numeroDocumento.replace(/([./-])/g, "");
            return this.usuario;
        }
    },
    watch: {
        usuario: {
            deep: true,
            immediate: true,
            handler() {
                let bool = Object.values(this.usuario).every(el => !["", "", null, undefined].includes(el));
                this.$emit("habilita", !bool);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.linha-cadastro {
    margin-top: 18px;

    &.primeira-linha {
        margin-top: 24px;
    }

    & .limpar-busca {
        display: inline-block;
        margin-top: 34px;
        box-sizing: border-box;
        width: 80px;
        color: #1492e6;
        cursor: pointer;
    }

    & .botao {
        display: block;
        margin-top: 25px;

        & .botao-interno {
            box-sizing: border-box;
            color: #9e9e9e;

            &:hover {
                color: #00995d;
            }

            & p {
                margin-top: 2px;
            }
        }
    }
}

.linha-exibicao {
    box-sizing: border-box;
    padding: 12px 0 16px 0;
    border-top: 1px solid #dee2e6;
    height: 80px;

    &:first-child {
        margin-top: 16px;
    }

    & .coluna-exibicao {
        height: 40px;
        color: #60656c;

        & p {
            line-height: 16px;

            &.linha-subtitulo {
                margin-bottom: 8px;
            }
        }
    }
}
</style>
