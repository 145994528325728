<template>
    <div>
        <GerenciarUsuarios @atualizar="$emit('atualizar')"/>
    </div>
</template>

<script>
import GerenciarUsuarios from "../components/gerenciarUsuarios/GerenciarUsuarios";
export default {
    components: {
        GerenciarUsuarios
    }
};
</script>

<style lang="scss" scoped></style>
