import Axios from "axios";

var PrestadorServiceMixin = {
    methods: {
        getPrestador(documento) {
            documento = documento.replace(/([./-])/g, "");
            return Axios.get(
                this.CriarUrl({
                    resource: `api/prestador/${documento}`
                }),
                { withCredentials: true }
            );
        }
    }
};

export default PrestadorServiceMixin;
