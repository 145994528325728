<template>
    <div>
        <unicom-linha class="header" :espacamento="false">
            <unicom-coluna md="4" style="padding-top:10px">
                <h3 class="fonte-primaria titulo negrito">APLICAÇÕES DO USUÁRIO</h3>
            </unicom-coluna>
            <unicom-coluna md="4">
                <unicom-autocomplete
                    v-model="unimed"
                    :valorData="unimed"
                    placeholder="Selecione uma Unimed..."
                    textoDoItem="nome"
                    :items="unimeds"
                    @input="valor => adicionarIdUnimedSelecionada(valor)"
                />
            </unicom-coluna>
        </unicom-linha>
        <unicom-tabela
            :carregamento="loading"
            :estruturaPadrao="false"
            :icones="iconesUsadosTabela"
            :itensPorPagina="['5', '10', '15', '20']"
            :ordem="ordem"
            :pagina="pagina"
            :qtPaginas="qtPaginas"
            :qtResultados="qtResultados"
            :cabecalho="cabecalho"
            :rows="rows"
            @troca-elemento-pagina="item => trocaElementoPagina(item)"
            @ordena="item => ordenar(item)"
            @troca-pagina="item => trocaPagina(item)"
            @acao="item => acao(item)"
        >
            <div slot="header">
                <unicom-linha :espacamento="true" :justificarItem="'end'">
                    <unicom-coluna :md="somenteVisualizar ? 12 : 8">
                        <unicom-barra-pesquisa
                            :value="campoPesquisa"
                            :placeholder="'Buscar aplicação ou papel na tabela...'"
                            @pesquisar="valor => pesquisarAplicacao(valor)"
                        />
                    </unicom-coluna>
                    <unicom-coluna :md="3">
                        <unicom-botao
                            :bloco="true"
                            class="fonte-secundaria texto"
                            :desabilitado="!unimed"
                            @click="mostrarModalAdicionarAplicacao = true"
                            v-if="!somenteVisualizar"
                        >
                            ADICIONAR APLICAÇÃO
                        </unicom-botao>
                    </unicom-coluna>
                </unicom-linha>
            </div>

            <unicom-loading v-model="carregamento" style="margin-top:15px;" mensagem="Carregando dados..." />
            <template v-slot:rows>
                <tr v-for="(row, index) in rows" :key="index">
                    <td>
                        <div class="corpo-texto">{{ row.nome }}</div>
                    </td>
                    <td>
                        <div class="corpo-texto" v-if="row.descricao">
                            <div v-if="row.descricao.length < 35">
                                {{ row.descricao }}
                            </div>
                            <unicom-tooltip
                                v-else
                                :texto="`${row.descricao.substring(0, 35)}...`"
                                :textoTooltip="row.descricao"
                            />
                        </div>
                        <div v-else>
                            {{ " - " }}
                        </div>
                    </td>
                    <td>
                        <div class="corpo-texto">
                            <ModalPapeis
                                v-model="mostrarModal"
                                @fechar="fecharModal"
                                v-if="index === indexModal"
                                :papeis="row.papeis"
                                :papeisSelecionados="Object.values(row.papeisAtivos)"
                                style="right:145px"
                                @papeis-selecionados="lista => adicionarPapeisSelecionados(lista, index)"
                                :indexColuna="index"
                            />
                            <unicom-lista-pilulas
                                :tasks="papeisPorUsuario(row.papeisAtivos)"
                                itemTextFiltro="nome"
                                :itensMaximos="3"
                                @mostrar-mais="abrirModal(index)"
                                @removeu="valor => excluirPapel(valor, index)"
                            />
                        </div>
                    </td>

                    <td v-if="iconesUsadosTabela.length > 0">
                        <unicom-tooltip
                            v-for="(icone, indexIcone) in iconesUsadosTabela"
                            :key="indexIcone"
                            :textoTooltip="icone.tooltipNome"
                            tooltipLocalizacao="top"
                            :visualizando="true"
                            style="display:inline-block; margin-right:6px"
                        >
                            <div slot="itemTooltip">
                                <div class="icone-acao" @click="acao({ id: index, acao: indexIcone })">
                                    <unicom-icone :nome="icone.nome" :tamanho="15" textoComIcone />
                                </div>
                            </div>
                        </unicom-tooltip>
                    </td>
                </tr>
            </template>
        </unicom-tabela>

        <unicom-modal-confirmacao
            v-if="diferencaQueFoiRemovida.length > 0"
            v-model="mostrarModalRemoverPapel"
            textoBotaoCancelar="CANCELAR"
            textoBotaoConfirmar="REMOVER"
            @botao-cancelar="removerPapel"
            @botao-confirmar="removerPapeis(lista, diferencaQueFoiRemovida)"
            :tipoBotaoCancelar="false"
            @fechar="mostrarModalRemoverPapel = false"
        >
            <div slot="corpo-confirmacao">
                <p>
                    Deseja remover o
                    <span class="texto-vermelho negrito">{{ diferencaQueFoiRemovida[0].nome }}</span> de aplicação ao
                    usuário
                </p>
            </div>
        </unicom-modal-confirmacao>

        <unicom-modal-confirmacao
            v-model="mostrarModalRemoverAplicacao"
            textoBotaoCancelar="CANCELAR"
            textoBotaoConfirmar="REMOVER"
            @botao-cancelar="removerPapel"
            @botao-confirmar="removerAplicacao(idAplicacao)"
            :tipoBotaoCancelar="false"
            @fechar="mostrarModalRemoverAplicacao = false"
        >
            <div slot="corpo-confirmacao">
                <p>
                    Deseja remover a aplicação do sistema?
                </p>
            </div>
        </unicom-modal-confirmacao>

        <unicom-modal-confirmacao
            v-if="diferencaQueFoiAdicionada.length > 0"
            v-model="mostrarModalConfirmacaoPapel"
            textoBotaoCancelar="CANCELAR"
            textoBotaoConfirmar="ATRIBUIR"
            @botao-cancelar="removerPapel"
            @botao-confirmar="adicionarPapeis(lista, diferencaQueFoiAdicionada)"
            :tipoBotaoCancelar="false"
            @fechar="mostrarModalConfirmacaoPapel = false"
        >
            <div slot="corpo-confirmacao">
                <p>
                    Deseja atribuir o
                    <span class="texto-vermelho negrito">{{ diferencaQueFoiAdicionada[0].nome }}</span> de aplicação ao
                    usuário
                </p>
            </div>
        </unicom-modal-confirmacao>

        <ModalAdicionarAplicacao
            ref="modalAdicionarAplicacao"
            v-model="mostrarModalAdicionarAplicacao"
            @fechar="mostrarModalAdicionarAplicacao = false"
            @salvar="paginar"
            @loading="value => (loadingModal = value)"
            :idUsuario="idUsuario"
            :idUnimed="idUnimedSelecionada"
            :idPerfil="idPerfil"
        />
    </div>
</template>

<script>
import PapeisServiceMixin from "../services/PapeisServiceMixin";
import UsuarioUnimedService from "@/mixins/services/usuarioUnimed/UsuarioUnimedServiceMixin";
import ModalPapeis from "../componentes/ModalPapeis";
import ModalAdicionarAplicacao from "../componentes/ModalAdicionarAplicacoes/ModalAdicionarAplicacoes";

import { differenceBy, orderBy } from "lodash";

export default {
    props: {
        idUsuario: {
            type: Number,
            required: true
        },
        listaUnimeds: {
            type: Array,
            required: true
        },
        somenteVisualizar: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    components: {
        ModalPapeis,
        ModalAdicionarAplicacao
    },

    mixins: [PapeisServiceMixin, UsuarioUnimedService],

    data() {
        return {
            loadingModal: false,
            mostrarModal: false,
            indexModal: 0,
            mostrarModalConfirmacaoPapel: false,
            mostrarModalAdicionarAplicacao: false,
            mostrarModalRemoverPapel: false,
            mostrarModalRemoverAplicacao: false,
            idAplicacao: null,
            nomeAplicacao: "",
            idUnimedSelecionada: 0,
            idPerfil: 0,
            loading: false,
            carregamentoOverlay: false,
            campoPesquisa: "",
            unimed: "",
            iconesUsadosTabela: [],
            ordem: "asc",
            itensPorPagina: 5,
            pagina: 1,
            qtPaginas: 1,
            qtResultados: 0,
            cabecalho: [
                {
                    texto: "Aplicação",
                    icone: "maximize-2",
                    largura: this.somenteVisualizar ? "" : "243px"
                },
                {
                    texto: "Descrição",
                    largura: this.somenteVisualizar ? "" : "288px"
                },
                {
                    texto: "Papel do usuário",
                    largura: this.somenteVisualizar ? "" : "442px"
                }
            ],
            listasAplicacoes: [],
            rows: [],
            unimeds: this.listaUnimeds,
            diferencaQueFoiRemovida: [
                {
                    nome: ""
                }
            ],
            diferencaQueFoiAdicionada: [
                {
                    nome: ""
                }
            ],
            lista: [],
            index: 0,
            carregamento: false
        };
    },

    beforeMount() {
        this.loading = true;
        this.getListarUnimeds();

        if (!this.somenteVisualizar) {
            this.cabecalho.push({ texto: "Ações", largura: "110px" });

            this.iconesUsadosTabela = [
                { nome: "edit", tooltipNome: "Editar" },
                { nome: "trash", tooltipNome: "Excluir" }
            ];
        }

        this.loading = false;
    },

    methods: {
        papeisPorUsuario(papeis) {
            if (this.somenteVisualizar) {
                papeis.forEach(item => {
                    item.excluir = false;
                });
            }
            return papeis;
        },
        montarTabela(objeto) {
            this.listasAplicacoes = [];
            this.rows = [];

            this.listasAplicacoes = objeto.conteudo;
            if (this.listasAplicacoes.length > 0) {
                this.listasAplicacoes.forEach(item => {
                    item.papeis = item.papeisAplicacao;
                    item.papeisAtivos = item.papeisAplicacao.filter(papel => papel.existeParaUsuario === true);
                    this.rows.push({
                        nome: item.nome,
                        descricao: item.descricao,
                        papeis: this.montarPapeisAplicacao(item.papeisAplicacao),
                        papeisAtivos: item.papeisAplicacao.filter(papel => papel.existeParaUsuario === true)
                    });
                });
            }
            this.pagina = objeto.pagina;
            this.qtPaginas = objeto.qtPaginas;
            this.qtResultados = objeto.qtResultados;
        },

        montarPapeisAplicacao(papeis) {
            let sessao = this.getSession();
            let novaLista = [];
            if (sessao.usuarioUnimed.perfil.id === -1) {
                return papeis;
            } else {
                papeis.forEach(item => {
                    if (!item.adminOnly) {
                        novaLista.push(item);
                    }
                });
                return novaLista;
            }
        },

        trocaElementoPagina(item) {
            this.itensPorPagina = item;
            this.paginar();
        },

        ordenar(item) {
            switch (item.index) {
                case 0:
                    this.campoOrdenado = "nome";
                    break;
                case 3:
                    this.campoOrdenado = "ativa";
                    break;
            }
            this.ordem = item.ordem;
            this.paginar();
        },

        trocaPagina(item) {
            this.pagina = item.pagina;
            this.itensPorPagina = item.itensPorPagina;
            this.paginar();
        },

        acao(item) {
            if (item.acao === 0) {
                this.mostrarModal = true;
                this.indexModal = item.id;
            } else {
                this.idAplicacao = this.listasAplicacoes[item.id].id;
                this.mostrarModalRemoverAplicacao = true;
            }
        },

        paginar() {
            this.getListaPapeisUsuario(this.getArgs());
        },

        adicionarIdUnimedSelecionada(valor) {
            this.unimed = valor;

            let unimedSelecionada = this.listaUnimeds.filter(unimed => unimed.nome === valor)[0];
            this.idUnimedSelecionada = unimedSelecionada.unimed.id;
            this.idPerfil = unimedSelecionada.perfil.id;
            let data = {
                idUnimed: this.idUnimedSelecionada,
                idPerfil: this.idPerfil,
                idUsuario: this.idUsuario,
                sort: null
            };
            this.$refs.modalAdicionarAplicacao.getAplicacoes(data);
            this.getListaAplicacoes();
            this.paginar();
        },

        adicionarPapeisSelecionados(lista, index) {
            this.index = index;
            this.diferencaQueFoiRemovida = differenceBy(this.rows[lista.index].papeisAtivos, lista.papeis, "id");
            this.diferencaQueFoiAdicionada = differenceBy(lista.papeis, this.rows[lista.index].papeisAtivos, "id");
            this.lista = lista;

            if (this.diferencaQueFoiAdicionada.length > 0) {
                this.mostrarModalConfirmacaoPapel = true;
                this.objetoPapeis(this.diferencaQueFoiAdicionada);
            }

            if (this.diferencaQueFoiRemovida.length > 0) {
                this.objetoPapeis(this.diferencaQueFoiRemovida);
                this.mostrarModalRemoverPapel = true;
            }
        },

        pesquisarAplicacao(valor) {
            this.pagina = 1;
            this.campoPesquisa = valor;
            this.paginar();
        },

        removerPapel() {
            this.paginar();
        },

        abrirModal(index) {
            this.indexModal = index;
            this.mostrarModal = true;
        },

        fecharModal() {
            this.indexModal = null;
            this.mostrarModal = false;
        },

        getListarUnimeds() {
            this.unimeds.forEach(unimed => (unimed.nome = unimed.unimed.codigoNome));
            this.unimeds = orderBy(this.unimeds, ["unimed.codigo"], ["asc"]);
        },

        objetoPapeis(papeis) {
            return {
                papeis: papeis.map(el => el.id),
                usuarioUnimeds: [
                    {
                        idUnimed: this.idUnimedSelecionada,
                        idUsuario: this.idUsuario
                    }
                ]
            };
        },

        objetoUsuario() {
            return {
                idUnimed: this.idUnimedSelecionada,
                idUsuario: this.idUsuario,
                idPerfil: this.idPerfil
            };
        },

        getArgs() {
            return {
                direcaoOrdenacao: this.ordem,
                ordenacao: this.campoOrdenado,
                pagina: this.pagina,
                tamanhoPagina: this.itensPorPagina,
                idUnimed: this.idUnimedSelecionada,
                idUsuario: this.idUsuario,
                descricao: this.campoPesquisa
            };
        },

        excluirPapel(objeto, index) {
            this.deleteRemoverPapel(this.objetoPapeis([objeto.itemRemovido])).then(response => {
                if (response.status === 200) {
                    this.rows[index].papeisAtivos = objeto.itensAtuais;
                    this.$notify({
                        group: "GIU",
                        title: "Removido com sucesso!",
                        type: "error",
                        text: "O papel foi removido do usuário",
                        width: 300,
                        max: 3
                    });
                    this.paginar();
                }
            });
        },

        adicionarPapeis(objeto, lista) {
            this.postAdicionarPapel(this.objetoPapeis(lista)).then(response => {
                if (response.status === 200) {
                    this.$notify({
                        group: "GIU",
                        title: "Adicionado com sucesso!",
                        type: "success",
                        text: "O papel foi atribuído ao usuário",
                        width: 300,
                        max: 3
                    });
                    this.rows[objeto.index].papeisAtivos = objeto.papeis;
                }
            });
        },

        removerPapeis(objeto, lista) {
            this.deleteRemoverPapel(this.objetoPapeis(lista)).then(response => {
                if (response.status === 200) {
                    this.rows[objeto.index].papeisAtivos = objeto.papeis;
                    this.$notify({
                        group: "GIU",
                        title: "Removido com sucesso!",
                        type: "error",
                        text: "O papel foi removido do usuário",
                        width: 300,
                        max: 3
                    });
                }
            });
        },

        removerAplicacao(idAplicacao) {
            this.deleteRemoverTodosPapeisAplicacao(idAplicacao, this.idUnimedSelecionada, this.idUsuario)
                .then(response => {
                    if (response.status === 200) {
                        this.paginar();
                        this.idAplicacao = null;
                    }
                })
                .catch(error => {
                    console.error(error);
                    this.idAplicacao = null;
                });
        },

        getListaPapeisUsuario(args) {
            this.loading = true;
            this.getPapeisUsuario(args, false)
                .then(response => {
                    if (response) {
                        this.montarTabela(response.data);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        getListaAplicacoes() {
            this.postListarAplicacoesVinculadas(this.objetoUsuario()).then(() => {});
        }
    }
};
</script>

<style lang="scss" scoped>
.header {
    margin-left: 22px;
    margin-bottom: 34px;
    color: #00995d;

    span {
        color: #60656c;
    }
}

.sucess {
    background: green;
}
</style>
