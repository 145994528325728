<template>
    <unicom-tabela
        :carregamento="loading"
        :icones="iconesUsadosTabela"
        :itensPorPagina="['5', '10', '15', '20']"
        :ordem="ordem"
        :pagina="pagina"
        :qtPaginas="qtPaginas"
        :qtResultados="qtResultados"
        :cabecalho="cabecalho"
        :rows="rows"
        :textoExibindo="'resultados'"
        @troca-elemento-pagina="item => trocaElementoPaginaMixin(item)"
        @ordena="item => ordenar(item)"
        @troca-pagina="item => trocaPaginaMixin(item)"
        @acao="item => acao(item)"
    >
        <div slot="header">
            <unicom-linha :espacamento="true" :justificarItem="'end'">
                <unicom-coluna :md="7">
                    <unicom-barra-pesquisa
                        :value="campoPesquisa"
                        :placeholder="'Buscar Nome, Login do usuário, ou Código de Unimed'"
                        @pesquisar="valor => pesquisar(valor)"
                    />
                </unicom-coluna>
                <unicom-coluna :md="3">
                    <unicom-botao :bloco="true" class="fonte-secundaria texto" @click="$emit('enviar-carga')">
                        ENVIAR CARGA
                    </unicom-botao>
                </unicom-coluna>
                <unicom-coluna :md="2">
                    <unicom-botao :bloco="true" class="fonte-secundaria texto" @click="$emit('filtrar')">
                        FILTRAR
                    </unicom-botao>
                </unicom-coluna>
            </unicom-linha>
        </div>
    </unicom-tabela>
</template>

<script>
// SERVICES
import ColaboradorServiceMixin from "../../../../mixins/services/gerenciarUsuarios/ColaboradorServiceMixin";

export default {
    mixins: [ColaboradorServiceMixin],
    data() {
        return {
            loading: true,
            carregamentoOverlay: false,
            campoPesquisa: "",
            iconesUsadosTabela: [
                {
                    nome: "edit",
                    tooltipNome: "Editar"
                }
            ],
            ordem: "asc",
            campoOrdenado: "",
            itensPorPagina: 5,
            pagina: 1,
            qtPaginas: 1,
            qtResultados: 0,
            cabecalho: [
                {
                    texto: "Último login",
                    icone: "maximize-2",
                    largura: "140px"
                },
                {
                    texto: "Nome",
                    icone: "maximize-2",
                    largura: "245px"
                },
                {
                    texto: "Login do usuário",
                    largura: "136px"
                },
                {
                    texto: "Unimed",
                    largura: "363px"
                },
                {
                    texto: "Status da conta",
                    icone: "maximize-2",
                    largura: "138px"
                },

                {
                    texto: "Ações",
                    largura: "50px"
                }
            ],
            rows: [],
            listaUsuarios: [],
            filtro: {
                loginUsuario: "",
                nome: "",
                codigoUnimed: "",
                tipoConta: "",
                statusUsuario: ""
            },
            sessao: this.getSession()
        };
    },
    async beforeMount() {
        if(this.sessao.usuarioUnimed.perfil.id !== -1){
            this.filtro.codigoUnimed = this.sessao.usuarioUnimed.unimed.id
        }
        this.paginar();
    },
    methods: {
        getListaUsuarios(args) {
            this.loading = true;
            this.getColaborador(args)
                .then(response => {
                    this.montarTabela(response.data);
                    this.loading = false;
                })
                .catch(() => (this.loading = false))
                .finally(() => (this.loading = false));
        },

        // FILTRO
        filtrar(filtro) {
            this.pagina = 1
            this.filtro = filtro;
            this.paginar();
        },

        limparFiltro() {
            this.filtro = {
                nome: "",
                codigoUnimed: "",
                tipoConta: "",
                statusUsuario: ""
            };
            this.paginar();
        },

        pesquisar(valor) {
            const result = this.buscaColaboradores(valor);
            let filtro = {};

            if (result == null) {
                this.filtro.nome = "";
                this.filtro.loginUsuario = "";
                this.filtro.codigoUnimed = "";
            } else this.filtro[result] = valor;

            this.pagina = 1;
            let obj = { ...this.filtro, ...filtro };
            this.paginar(obj);
        },
        extrairLogin(valor) {
            return valor.replace(/([.-])/g, "");
        },
        // MONTAR TABELA
        montarTabela(objeto) {
            this.listaUsuarios = [];
            this.rows = [];

            this.listaAplicacoes = objeto.conteudo;

            this.listaAplicacoes.forEach(item => {
                let statusLinha = {
                    tipo: "padrao",
                    valor: "",
                    tooltipStatus: ""
                };

                switch (item.status) {
                    case "ATIVO":
                        statusLinha.valor = true;
                        statusLinha.tooltipStatus = "Ativo";
                        break;

                    case "INATIVO":
                        statusLinha.valor = false;
                        statusLinha.tooltipStatus = "Inativo";
                        break;

                    default:
                        statusLinha.valor = null;
                        statusLinha.tooltipStatus = "Pendente";
                        break;
                }

                const loginUsuario = this.formatarDocumento(item.login.usuario);
                const ultimoLogin = this.formatarData(item.ultimoAcesso);

                const unimedTexto = item.unimeds.map(el => el.unimed.nome).join();

                const row = {
                    ultimoLogin,
                    nome: item.nome,
                    login: item.login.usuario ? loginUsuario : "-",
                    unimeds: { texto: unimedTexto, qtdMaxCaracteres: 42 },
                    status: {
                        tooltipStatus: item.status,
                        tipo: "ativoInativoPendente",
                        valor: item.status === "ATIVO" ? true : item.status === "INATIVO" ? false : null
                    }
                };

                this.rows.push(row);
            });

            this.pagina = objeto.pagina;
            this.qtPaginas = objeto.qtPaginas;
            this.qtResultados = objeto.qtResultados;
        },
        formatarData(data) {
            if (!data) return "";

            const ano = data[0];
            const mes = data[1] < 10 ? `0${data[1]}` : data[1];
            const dia = data[2] < 10 ? `0${data[2]}` : data[2];

            return `${dia}/${mes}/${ano}`;
        },
        // PAGINAÇÃO
        trocaElementoPagina(item) {
            this.pagina = 1;
            this.itensPorPagina = item;
            this.paginar();
        },
        ordenar(item) {
            switch (item.index) {
                case 0:
                    this.campoOrdenado = "ultimoAcesso";
                    break;
                case 1:
                    this.campoOrdenado = "nome";
                    break;
                case 3:
                    this.campoOrdenado = "usuarioUnimeds.unimed.nome";
                    break;
                case 4:
                    this.campoOrdenado = "status";
                    break;
            }
            this.ordem = item.ordem;
            this.paginar();
        },

        acao(item) {
            this.$emit("editar", this.listaAplicacoes[item.id]);
        },
        paginar(filtro = this.filtro) {
            const usuario = filtro.loginUsuario ? filtro.loginUsuario.replace(/([.-])/g, "") : "";

            const args = this.getArgumentoMixin({
                loginUsuario: usuario,
                nome: filtro.nome,
                codigoUnimed: filtro.codigoUnimed,
                codigoTipoConta: filtro.tipoConta,
                statusUsuario: filtro.statusUsuario
            });

            this.getListaUsuarios(args);
        },

        formatarDocumento(numeroDocumento) {
            if (numeroDocumento.length <= 8) {
                return numeroDocumento.substr(0, 7) + "-" + numeroDocumento.substr(7, 8);
            } else if (numeroDocumento.length > 8) {
                numeroDocumento = numeroDocumento.replace(/[^\d]/g, "");
                return numeroDocumento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
            } else {
                return numeroDocumento;
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.tooltip {
    left: 3px;
}
</style>
