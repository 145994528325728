<template>
    <unicom-modal
        v-model="mostrar"
        :largura="705"
        fechar
        class="modal-cadastro-usuario"
        @input="valor => fecharModal(valor)"
    >
        <div slot="header">
            <h2 class="fonte-primaria titulo negrito">CADASTRAR NOVO USUÁRIO</h2>
            <unicom-linha :espacamento="false" :justificarItem="'center'" class="linha-steps">
                <unicom-coluna :md="1">
                    <button :class="`step ${stepAtivo(0)}`" @click="trocaStep(0)"></button>
                    <button :class="`step ${stepAtivo(1)} ${stepBloqueado}`" @click="trocaStep(1)"></button>
                </unicom-coluna>
            </unicom-linha>
            <h3 class="fonte-primaria titulo negrito">{{ textoStep }}</h3>
        </div>

        <div slot="body" class="container-corpo">
            <div v-show="index === 0">
                <unicom-linha :espacamento="false" style="padding-left:2px;">
                    <unicom-coluna style="margin-bottom:12px">
                        <p class="fonte-secundaria texto">Tipo de usuário</p>
                    </unicom-coluna>

                    <unicom-coluna :md="3">
                        <unicom-checkbox v-model="colaborador" :texto="'Colaborador'" :arredondado="true" />
                    </unicom-coluna>

                    <unicom-coluna :md="3" v-if="permitirCadastrar()">
                        <unicom-checkbox v-model="pessoaJuridica" :texto="'Pessoa Jurídica'" :arredondado="true" />
                    </unicom-coluna>

                    <unicom-coluna :md="3" :lg="3" style="margin-left:8px;" v-if="permitirCadastrar()">
                        <unicom-checkbox v-model="fornecedor" :texto="'Fornecedor'" :arredondado="true" />
                    </unicom-coluna>
                </unicom-linha>

                <div class="scroll-index-0">
                    <CadastroColaborador
                        v-if="colaborador"
                        ref="colaboradorComponente"
                        @habilita="value => habilitarColaborador(value)"
                        @buscou="valor => buscouDado(valor)"
                    />

                    <CadastroPessoaJuridica
                        v-if="pessoaJuridica"
                        ref="pessoaJuridicaComponente"
                        @habilita="value => habilitarJuridico(value)"
                        @buscou="valor => buscouDado(valor)"
                    />

                    <CadastroFornecedor
                        v-if="fornecedor"
                        @habilita="value => habilitarFornecedor(value)"
                        ref="fornecedorComponente"
                    />
                </div>
            </div>

            <div v-show="index === 1" class="scroll">
                <ValidationObserver ref="observer">
                    <unicom-linha :espacamento="false" class="linha-dados-profissionais" v-if="colaborador">
                        <unicom-coluna>
                            <validation-provider rules="required" mode="eager" v-slot="{ errors }">
                                <unicom-autocomplete
                                    v-model="usuario.tipoColaboradorModel"
                                    :valorData="usuario.tipoColaboradorModel"
                                    :items="listaCargo"
                                    titulo="Cargo do usuário *"
                                    textoDoItem="descricao"
                                    :mensagemErro="errors[0]"
                                    placeholder="Selecione o cargo"
                                    @input="defineConselhoUsuario"
                                />
                            </validation-provider>
                        </unicom-coluna>
                    </unicom-linha>
                    <div>
                        <unicom-linha
                            :espacamento="false"
                            class="linha-dados-profissionais"
                            v-if="!verificarCargoEstaVazio || colaborador"
                        >
                            <unicom-coluna :md="5">
                                <validation-provider rules="required" mode="eager">
                                    <unicom-autocomplete
                                        v-model="usuario.conselhoProfissional.siglaEstado"
                                        :valorData="usuario.conselhoProfissional.siglaEstado"
                                        :items="listaUf"
                                        titulo="UF do conselho *"
                                        placeholder="Selecione o estado"
                                    />
                                </validation-provider>
                            </unicom-coluna>
                            <unicom-coluna :md="2" />
                            <unicom-coluna :md="5">
                                <validation-provider rules="required" mode="eager">
                                    <unicom-campo-texto
                                        apenasNumero
                                        v-model="usuario.conselhoProfissional.numeroConselho"
                                        :titulo="tipoConselhoProfissional"
                                        :placeholder="''"
                                    />
                                </validation-provider>
                            </unicom-coluna>
                        </unicom-linha>
                        <unicom-linha
                            :espacamento="false"
                            class="linha-dados-profissionais cartao"
                            v-for="(unimed, index) in usuario.unimeds"
                            :key="index"
                        >
                            <unicom-coluna>
                                <unicom-linha
                                    :justificarItem="'end'"
                                    class="primeira-linha"
                                    v-if="usuario.unimeds.length > 1"
                                >
                                    <unicom-coluna :md="2">
                                        <small class="fonte-secundaria texto" @click="removerUnimed(index)">
                                            remover
                                        </small>
                                    </unicom-coluna>
                                </unicom-linha>
                                <unicom-linha :espacamento="false" class="linha-conteudo">
                                    <unicom-coluna>
                                        <unicom-autocomplete
                                            v-model="unimed.unimed"
                                            :valorData="unimed.unimed"
                                            :items="listaUnimedsDisponiveis"
                                            @input="valor => inserirUnimed(valor, index)"
                                            titulo="Unimed"
                                            textoDoItem="codigoNome"
                                            placeholder="Selecione a unimed"
                                        />
                                    </unicom-coluna>
                                </unicom-linha>
                                <unicom-linha :espacamento="false" class="cartao-linha">
                                    <unicom-coluna>
                                        <p class="fonte-secundaria texto">Perfil</p>
                                    </unicom-coluna>
                                    <unicom-coluna :md="4">
                                        <RadioButton
                                            label="Administrador"
                                            :valorRadio="'Administrador'"
                                            :name="'tipoPerfil_' + index"
                                            :value="unimed.idPerfil"
                                            :disabled="!colaborador || sessaoUsuario.usuarioUnimed.perfil.id !== -1"
                                            @change="el => atualizaTipoPerfil(el, index)"
                                        ></RadioButton>
                                    </unicom-coluna>
                                    <unicom-coluna :md="4">
                                        <RadioButton
                                            label="Colaborador"
                                            :valorRadio="'Colaborador'"
                                            :name="'tipoPerfil_' + index"
                                            :value="unimed.idPerfil"
                                            @change="el => atualizaTipoPerfil(el, index)"
                                        ></RadioButton>
                                    </unicom-coluna>
                                </unicom-linha>
                            </unicom-coluna>
                        </unicom-linha>
                        <unicom-linha :espacamento="true" class="linha-dados-profissionais">
                            <unicom-coluna>
                                <small
                                    class="fonte-secundaria texto"
                                    @click="adicionarPerfil()"
                                    v-if="sessaoUsuario.usuarioUnimed.perfil.id === -1"
                                >
                                    + Adicionar outra unimed
                                </small>
                            </unicom-coluna>
                        </unicom-linha>
                    </div>
                </ValidationObserver>
            </div>
        </div>

        <div slot="footer">
            <unicom-linha :espacamento="false" :justificarItem="'center'" class="linha-rodape">
                <unicom-coluna :md="6">
                    <unicom-linha :espacamento="false" :justificarItem="'start'">
                        <unicom-coluna :md="6">
                            <unicom-botao
                                :tipo="'primario'"
                                :contornado="true"
                                class="fonte-secundaria texto"
                                @click="cancelar()"
                            >
                                CANCELAR
                            </unicom-botao>
                        </unicom-coluna>
                    </unicom-linha>
                </unicom-coluna>
                <unicom-coluna :md="6">
                    <unicom-linha :espacamento="true" :justificarItem="'end'">
                        <unicom-coluna :md="6" v-if="index < 1">
                            <unicom-botao
                                :tipo="'primario'"
                                :desabilitado="habilitarProximoPasso"
                                class="fonte-secundaria texto botao"
                                @click="avancarEtapa()"
                            >
                                CONTINUAR
                            </unicom-botao>
                        </unicom-coluna>
                        <unicom-coluna :md="4" v-if="index > 0">
                            <unicom-botao :tipo="'secundario'" class="fonte-secundaria texto botao" @click="voltar">
                                VOLTAR
                            </unicom-botao>
                        </unicom-coluna>
                        <unicom-coluna :md="8" v-if="index > 0">
                            <unicom-botao
                                :tipo="'primario'"
                                :desabilitado="habilitarCadastrarFunc()"
                                :bloco="true"
                                class="fonte-secundaria texto"
                                @click="cadastrarUsuario()"
                            >
                                CADASTRAR USUÁRIO
                            </unicom-botao>
                        </unicom-coluna>
                    </unicom-linha>
                </unicom-coluna>
            </unicom-linha>
            <unicom-modal-loading :value="loadingModal" />
        </div>
    </unicom-modal>
</template>

<script>
import CadastroColaborador from "./CadastroColaborador";
import CadastroPessoaJuridica from "./CadastroPessoaJuridica";
import CadastroFornecedor from "./CadastroFornecedor";
import UnimedServiceMixin from "@/mixins/services/dominios/UnimedServiceMixin";
import ColaboradorServiceMixin from "../../../../mixins/services/gerenciarUsuarios/ColaboradorServiceMixin";
import PessoaJuridicaMixinService from "../services/PessoaJuridicaMixinService";
import RadioButton from "../../../shared/radioButton/RadioButton";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import Vue from "vue";

export default {
    components: {
        CadastroColaborador,
        CadastroPessoaJuridica,
        CadastroFornecedor,
        ValidationProvider,
        ValidationObserver,
        RadioButton
    },
    mixins: [UnimedServiceMixin, ColaboradorServiceMixin, PessoaJuridicaMixinService],
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            mostrar: this.value,
            index: 0,
            tipoUsuario: "colaborador",

            // TIPO DE CADASTRO
            colaborador: true,
            pessoaJuridica: false,
            fornecedor: false,

            dadosBuscado: false,
            usuario: {
                conselhoProfissional: {
                    numeroConselho: "",
                    siglaEstado: "",
                    tipoConselho: ""
                },
                tipoColaborador: "",
                tipoColaboradorModel: "",
                unimeds: [
                    {
                        codigo: "",
                        unimed: "",
                        idPerfil: null
                    }
                ]
            },

            // DADOS PROFISSIONAIS
            listaCargo: [],
            tipoConselhoProfissional: "Conselho Profissional *",
            conselho: {},
            listaUf: [],
            listaUnimeds: [],
            listaUnimedsDisponiveis: [],
            unimedsSelecionadas: [],

            habilitaColaborador: true,
            habilitaPessoaJuridica: false,
            habilitaFornecedor: false,

            loadingModal: false,
            sessaoUsuario: this.getSession()
        };
    },
    beforeMount() {
        this.getListarUnimeds();
        this.getEstadosConselho();
        this.getCargoUsuario();
    },
    methods: {
        getCargoUsuario() {
            this.getTiposColaboradores().then(({ data }) => (this.listaCargo = data));
        },

        permitirCadastrar() {
            return this.sessaoUsuario.usuarioUnimed.perfil.id === -1;
        },
        existeUnimed(unimed) {
            return unimed.unimed ? true : false;
        },
        existePerfil(unimed, habilitar) {
            return unimed.idPerfil && unimed.idPerfil != 0 && habilitar !== false ? true : false;
        },
        existeTipoColaborador() {
            return this.usuario.tipoColaboradorModel ? true : false;
        },
        necessitaConselhoProfissional() {
            return this.usuario.tipoColaboradorModel !== "Colaborador" ? true : false;
        },
        existeConselhoProfissional() {
            return this.usuario.conselhoProfissional.siglaEstado && this.usuario.conselhoProfissional.numeroConselho
                ? true
                : false;
        },
        habilitarCadastrarFunc() {
            let habilitar = null;
            this.usuario.unimeds.forEach(unimed => {
                if (this.colaborador) {
                    if (this.existeUnimed(unimed)) {
                        if (this.existePerfil(unimed, habilitar)) {
                            if (this.existeTipoColaborador()) {
                                if (this.necessitaConselhoProfissional()) {
                                    if (this.existeConselhoProfissional()) {
                                        habilitar = true;
                                    } else {
                                        habilitar = false;
                                    }
                                } else {
                                    habilitar = true;
                                }
                            } else {
                                habilitar = false;
                            }
                        } else {
                            habilitar = false;
                        }
                    } else {
                        habilitar = false;
                    }
                } else {
                    if (this.existePerfil(unimed, habilitar)) {
                        habilitar = true;
                    } else {
                        habilitar = false;
                    }
                }
            });
            return !habilitar;
        },
        async trocaStep(index) {
            Object.assign(this.usuario, this.montarUsuario(this.tipoUsuario));
            if (this.index !== 1) {
                if (!this.colaborador) {
                    this.usuario.unimeds[0].idPerfil = 2;
                }
            }
            if (await this.validarEtapaUsuario(this.tipoUsuario)) {
                this.index = index;
            }
        },

        async avancarEtapa() {
            Object.assign(this.usuario, this.montarUsuario(this.tipoUsuario));
            if (await this.validarEtapaUsuario(this.tipoUsuario)) {
                this.index = this.index + 1;
            }
            if (this.index === 1 && !this.colaborador) {
                this.usuario.unimeds[0].idPerfil = 2;
            }
        },

        voltar() {
            this.index = this.index - 1;
        },

        async cadastrarUsuario() {
            const numConselhoProfissional = parseInt(this.usuario.conselhoProfissional.numeroConselho);
            this.usuario.conselhoProfissional.numeroConselho = numConselhoProfissional || "";

            this.usuario.telefone = this.usuario.telefone.replace(/[^0-9]+/g, "");

            this.usuario.documento.numeroDocumento =
                this.usuario.documento.numeroDocumento.length < 17
                    ? this.usuario.documento.numeroDocumento.replace(/[^\w\s]/gi, "")
                    : this.usuario.documento.numeroDocumento.replace(/([./-])/g, "");

            const tipoUsuario = {
                colaborador: () => this.cadastrarColaborador(this.usuario),
                pessoaJuridica: () => this.cadastrarJuridico(this.usuario)
            };

            this.loadingModal = true;

            return (tipoUsuario[this.tipoUsuario] || tipoUsuario["pessoaJuridica"])();
        },

        montarUsuario(tipo) {
            if (Object.keys(this.$refs).length === 0) return {};

            const tipoUsuario = {
                colaborador: () => this.$refs.colaboradorComponente.montarUsuario(),
                pessoaJuridica: () => this.$refs.pessoaJuridicaComponente.montarUsuario(),
                fornecedor: () => this.$refs.fornecedorComponente.montarUsuario()
            };

            return (tipoUsuario[tipo] || tipoUsuario["colaborador"])();
        },

        validarEtapaUsuario(tipo) {
            if (this.dadosBuscado) return true;

            const tipoUsuario = {
                colaborador: () => this.$refs.colaboradorComponente.validarEtapa(),
                pessoaJuridica: () => this.$refs.pessoaJuridicaComponente.validarEtapa(),
                fornecedor: () => this.$refs.fornecedorComponente.validarEtapa()
            };
            return (tipoUsuario[tipo] || tipoUsuario["colaborador"])();
        },

        selecionarPerfil(index, valor) {
            Vue.set(this.usuario.unimeds[index], "idPerfil", valor);
        },

        adicionarPerfil() {
            this.usuario.unimeds.push({
                codigo: "",
                unimed: "",
                idPerfil: null
            });
        },

        removerUnimed(index) {
            this.usuario.unimeds.splice(index, 1);
        },

        inserirUnimed(valor, index) {
            let unimedEncontrada = this.listaUnimeds.filter(unimed => unimed.codigoNome === valor);
            if (valor && unimedEncontrada.length > 0) {
                this.usuario.unimeds[index].unimed = valor;
                this.usuario.unimeds[index].codigo = unimedEncontrada[0].codigo;
                this.unimedsSelecionadas = this.usuario.unimeds.map(el => el.unimed);
            } else {
                this.unimedsSelecionadas = [];
            }
            this.atualizarListaUnimedsDisponiveis();
        },

        defineConselhoUsuario(cargo) {
            this.conselho = this.listaCargo.find(it => it.descricao == cargo);

            if (cargo)
                this.tipoConselhoProfissional =
                    this.conselho.tipoConselhoProfissionalResponse.descricao + " (Conselho Profissional) *";
        },

        cancelar() {
            this.index = 0;
            this.colaborador = true;
            this.mostrar = false;
            this.limparSegundaEtapa();
        },

        buscouDado(valor) {
            this.dadosBuscado = valor.validou;
            this.usuario = valor.prestador;
            this.selecionarPerfil(0, null);
        },

        atualizarListaUnimedsDisponiveis() {
            this.listaUnimedsDisponiveis = this.listaUnimeds.filter(responseUnimed => {
                if (!this.unimedsSelecionadas.some(unimed => unimed === responseUnimed.codigoNome)) {
                    return true;
                }
            });
        },

        async validacao() {
            return await this.$refs.observer.validate();
        },

        fecharModal(valor) {
            if (!valor) {
                this.$emit("fechar");
                this.cancelar();
            }
        },

        limparSegundaEtapa() {
            this.usuario = {
                conselhoProfissional: {
                    numeroConselho: "",
                    siglaEstado: "",
                    tipoConselho: ""
                },
                tipoColaborador: "",
                tipoColaboradorModel: "",
                unimeds: [
                    {
                        codigo: "",
                        unimed: "",
                        idPerfil: 0
                    }
                ]
            };
        },

        stepAtivo(index) {
            return this.index === index ? "ativo" : "";
        },

        cadastrarColaborador(objeto) {
            objeto.conselhoProfissional.tipoConselho = this.conselho.tipoConselhoProfissionalResponse.descricao;
            objeto.tipoColaborador = this.conselho.enumerator;

            this.postCadastrarColaborador(objeto)
                .then(response => {
                    if (response) {
                        this.$emit("sucesso-usuario", { usuario: response.data, isColaborador: true });
                        this.fecharModal(false);
                    }
                })
                .finally(() => {
                    this.loadingModal = false;
                });
        },

        cadastrarJuridico(objeto) {
            objeto.conselhoProfissional.tipoConselho = "OUTROS";
            objeto.tipoColaborador = "Colaborador";

            this.postCadastrarJuridico(objeto)
                .then(response => {
                    if (response) {
                        this.$emit("sucesso-usuario", { usuario: response.data, isColaborador: false });
                        this.fecharModal(false);
                    }
                })
                .finally(() => {
                    this.loadingModal = false;
                });
        },

        getListarUnimeds() {
            const perfilUsuario = this.getSession();
            this.listaUnimeds = this.getUnimedsList();

            if (perfilUsuario.usuarioUnimed.perfil.id !== -1) {
                this.listaUnimeds = this.listaUnimeds.filter(
                    unimed => unimed.codigo === perfilUsuario.usuarioUnimed.unimed.codigo
                );
            }

            this.atualizarListaUnimedsDisponiveis();
        },

        getEstadosConselho() {
            this.getTodosEstados()
                .then(response => {
                    this.listaUf = response.data;
                })
                .catch(error => {
                    console.error(error);
                    this.listaUf = [];
                });
        },
        perfilUsuario() {
            let perfilUsuario = this.getSession();
            return perfilUsuario.usuarioUnimed.perfil.id !== -1;
        },
        atualizaTipoPerfil(newValue, idx) {
            this.usuario.unimeds[idx].idPerfil = newValue == "Colaborador" ? 2 : 1;
        },
        async habilitarColaborador(value) {
            if (value) {
                this.habilitaColaborador = false;
            } else {
                let validarColaborador = await this.$refs.colaboradorComponente.validarEtapa();
                validarColaborador ? (this.habilitaColaborador = true) : (this.habilitaColaborador = false);
            }
        },
        async habilitarJuridico(value) {
            if (value) {
                this.habilitaPessoaJuridica = false;
            } else {
                let validarJuridico = await this.$refs.pessoaJuridicaComponente.validarEtapa();
                validarJuridico ? (this.habilitaPessoaJuridica = true) : (this.habilitaPessoaJuridica = false);
            }
        },
        async habilitarFornecedor(value) {
            if (value) {
                this.habilitaFornecedor = false;
            } else {
                let validarFornecedor = await this.$refs.fornecedorComponente.validarEtapa();
                validarFornecedor ? (this.habilitaFornecedor = true) : (this.habilitaFornecedor = false);
            }
        }
    },
    watch: {
        ["usuario.unimeds"]: {
            deep: true,
            immediate: true,
            handler() {
                this.habilitarCadastrarFunc();
            }
        },
        value() {
            this.mostrar = this.value;
        },
        colaborador() {
            if (this.colaborador) {
                this.pessoaJuridica = false;
                this.fornecedor = false;
                this.dadosBuscado = false;
                this.tipoUsuario = "colaborador";
            }
        },
        pessoaJuridica() {
            if (this.pessoaJuridica) {
                this.colaborador = false;
                this.fornecedor = false;
                this.dadosBuscado = false;
                this.tipoUsuario = "pessoaJuridica";
            }
        },
        fornecedor() {
            if (this.fornecedor) {
                this.colaborador = false;
                this.pessoaJuridica = false;
                this.dadosBuscado = false;
                this.tipoUsuario = "fornecedor";
            }
        }
    },
    computed: {
        stepBloqueado() {
            return !this.desativado ? "" : "inativo";
        },
        textoStep() {
            return this.index === 0 ? "Insira os dados básicos" : "Insira os dados profissionais";
        },
        habilitarProximoPasso() {
            if (this.colaborador) {
                return !this.habilitaColaborador;
            }

            if (this.pessoaJuridica) {
                return !this.habilitaPessoaJuridica;
            }

            if (this.fornecedor) {
                return !this.habilitaFornecedor;
            }

            if (!this.colaborador && !this.pessoaJuridica && !this.fornecedor) return true;

            return false;
        },
        verificarCargoEstaVazio() {
            return this.usuario.tipoColaboradorModel === "";
        },
        mostrarConselhoProfissional() {
            return this.usuario.tipoColaboradorModel !== "Colaborador";
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-cadastro-usuario {
    & h2 {
        text-align: center;
        color: #00995d;
    }

    & h3 {
        text-align: center;
        color: #60656c;
    }

    & .linha-steps {
        margin: 24px 0;

        & .step {
            float: left;
            box-sizing: border-box;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #cccccc;
            cursor: pointer;

            &:first-child {
                margin-right: 16px;
            }

            &.ativo {
                background-color: #00995d;
            }

            &.inativo {
                background-color: #cccccc;
                cursor: not-allowed;
            }
        }
    }

    & .container-corpo {
        box-sizing: border-box;
        width: 100%;

        & .scroll {
            height: 392px;
            overflow-x: hidden;
            overflow-y: auto;
        }

        & .scroll-index-0 {
            height: 342px;
            overflow-x: hidden;
            overflow-y: auto;
        }

        & .linha-dados-profissionais {
            margin-top: 24px;

            &.cartao {
                padding: 16px;
                box-sizing: border-box;
                height: 185px;
                border-radius: 4px;
                border: 1px solid #dee2e6;
                background-color: #dee2e6;

                & .cartao-linha {
                    margin-top: 24px;
                }
            }

            & small {
                color: #1492e6;
                cursor: pointer;
            }
        }
    }

    & .linha-rodape {
        margin-top: 10px;

        .botao {
            float: right;
        }
    }
}
</style>
