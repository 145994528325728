import axios from "axios";

export default class Aplicacao {
    constructor(url) {
        this.url = `${url}`;
    }

    getListaAplicacoes(args) {
        return axios.get(`${this.url}/api/aplicacoes/filtra?${args}`, { withCredentials: true });
    }

    getComboAplicacoes() {
        return axios.get(`${this.url}/api/aplicacoes/combo`, { withCredentials: true });
    }

    getComboAplicacoesAtivas() {
        return axios.get(`${this.url}/api/aplicacoes/combo/ativas`, { withCredentials: true });
    }

    getAplicacao(id) {
        return axios.get(`${this.url}/api/aplicacoes/${id}`, { withCredentials: true });
    }

    postAplicacoes(data) {
        return axios.post(`${this.url}/api/aplicacoes`, data, { withCredentials: true });
    }

    putAplicacoes(id, data) {
        return axios.put(`${this.url}/api/aplicacoes/${id}`, data, { withCredentials: true });
    }

    patchNovoToken(id) {
        return axios.patch(`${this.url}/api/aplicacoes/atualizar-senha/${id}`, { withCredentials: true });
    }
}
