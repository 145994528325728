<template>
    <unicom-tabela
        :carregamento="loading"
        :icones="iconesUsadosTabela"
        :itensPorPagina="['5', '10', '15', '20']"
        :ordem="ordem"
        :pagina="pagina"
        :qtPaginas="qtPaginas"
        :qtResultados="qtResultados"
        :cabecalho="cabecalho"
        :rows="rows"
        :textoExibindo="'resultados'"
        @troca-elemento-pagina="item => trocaElementoPagina(item)"
        @ordena="item => ordenar(item)"
        @troca-pagina="item => trocaPagina(item)"
        @acao="item => acao(item)"
    >
        <div slot="header">
            <unicom-linha :espacamento="true" :justificarItem="'end'">
                <template v-if="exibirCargaUsuario()">
                    <unicom-coluna :md="7">
                        <unicom-barra-pesquisa
                            :value="campoPesquisa"
                            :placeholder="'Buscar Razão social, CNPJ, ou Código da Unimed'"
                            @pesquisar="valor => pesquisar(valor)"
                        />
                    </unicom-coluna>
                    <unicom-coluna :md="3">
                        <unicom-botao :bloco="true" class="fonte-secundaria texto" @click="$emit('enviar-carga')">
                            ENVIAR CARGA
                        </unicom-botao>
                    </unicom-coluna>
                    <unicom-coluna :md="2">
                        <unicom-botao :bloco="true" class="fonte-secundaria texto" @click="$emit('filtrar')">
                            FILTRAR
                        </unicom-botao>
                    </unicom-coluna>
                </template>
                <template v-else>
                    <unicom-coluna :md="10">
                        <unicom-barra-pesquisa
                            :value="campoPesquisa"
                            :placeholder="'Buscar Razão social, CNPJ, ou Código da Unimed'"
                            @pesquisar="valor => pesquisar(valor)"
                        />
                    </unicom-coluna>
                    <unicom-coluna :md="2">
                        <unicom-botao :bloco="true" class="fonte-secundaria texto" @click="$emit('filtrar')">
                            FILTRAR
                        </unicom-botao>
                    </unicom-coluna>
                </template>
            </unicom-linha>
        </div>
    </unicom-tabela>
</template>

<script>
import PessoaJuridicaMixinService from "../services/PessoaJuridicaMixinService";

export default {
    mixins: [PessoaJuridicaMixinService],

    data() {
        return {
            loading: false,
            carregamentoOverlay: false,
            campoPesquisa: "",
            iconesUsadosTabela: [],
            ordem: "asc",
            itensPorPagina: 5,
            pagina: 1,
            qtPaginas: 1,
            qtResultados: 0,
            campoOrdenado: "",
            cabecalho: [
                {
                    texto: "Último login",
                    icone: "maximize-2",
                    largura: "151px"
                },
                {
                    texto: "Razão social",
                    icone: "maximize-2",
                    largura: "243px"
                },
                {
                    texto: "CNPJ",
                    largura: "178px"
                },
                {
                    texto: "Unimed",
                    largura: "342px"
                },
                {
                    texto: "Status da conta",
                    icone: "maximize-2",
                    largura: "151px"
                },
            ],
            listaJuridicos: [],
            rows: [],
            filtro: {
                razaoSocial: "",
                loginUsuario: "",
                codigoUnimed: "",
                tipoConta: "",
                statusUsuario: "",
                numeroDocumento: ""
            }
        };
    },

    async beforeMount() {
        let sessaoUsuario = this.getSession();
        if(sessaoUsuario.usuarioUnimed.perfil.id === -1){
            this.iconesUsadosTabela.push({
                nome: "edit",
                tooltipNome: "Editar"
            })
            this.cabecalho.push({
                texto: "Ações"
            })
        }else{
            this.iconesUsadosTabela.push({
                nome: "eye",
                tooltipNome: "Visualizar"
            })
            this.cabecalho.push({
                texto: "Ações"
            })
        }
        
        this.paginar()
    },
    methods: {
        exibirCargaUsuario() {
            let sessaoUsuario = this.getSession();
            return sessaoUsuario.usuarioUnimed.perfil.id === -1;
        },
        // REQUESTS
        getListaJuridicos(args) {
            this.loading = true;
            this.getJuridicos(args, false)
                .then(response => {
                    this.montarTabela(response.data);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        // MONTAR TABELA
        montarTabela(objeto) {
            this.listaUsuarios = [];
            this.rows = [];

            this.listaJuridicos = objeto.conteudo;
            this.listaJuridicos.forEach(item => {
                this.rows.push({
                    ultimoLogin: item.ultimoAcesso ? this.formatarData(item.ultimoAcesso) : "",
                    razaoSocial: item.razaoSocial,
                    cnpj: item.login.usuario ? this.mascaraCnpj(item.login.usuario) : "-",
                    unimeds: {
                        texto: item.unimeds.map(el => el.unimed.nome).join(),
                        qtdMaxCaracteres: 42
                    },
                    status: {
                        tooltipStatus: item.status,
                        tipo: "ativoInativoPendente",
                        valor: item.status === "ATIVO" ? true : item.status === "INATIVO" ? false : null
                    }
                });
            });
            this.pagina = objeto.pagina;
            this.qtPaginas = objeto.qtPaginas;
            this.qtResultados = objeto.qtResultados;
        },
        formatarData(data) {
            let ano = data[0];
            let mes = data[1] < 10 ? `0${data[1]}` : data[1];
            let dia = data[2] < 10 ? `0${data[2]}` : data[2];
            return `${dia}/${mes}/${ano}`;
        },
        trocaElementoPagina(item) {
            this.pagina = 1
            this.itensPorPagina = item;
            this.paginar();
        },
        ordenar(item) {
            switch (item.index) {
                case 1:
                    this.campoOrdenado = "nome";
                    break;
                case 3:
                    this.campoOrdenado = "usuarioUnimeds.unimed.nome";
                    break;
                case 0:
                    this.campoOrdenado = "ultimoAcesso";
                    break;
                case 4:
                    this.campoOrdenado = "status";
                    break;
            }
            this.ordem = item.ordem;
            this.paginar();
        },
        trocaPagina(item) {
            this.pagina = item.pagina;
            this.itensPorPagina = item.itensPorPagina;
            this.paginar();
        },
        acao(item) {
            let sessao = this.getSession()
            if(sessao.usuarioUnimed.perfil.id === -1){
                this.$emit("editar", this.listaJuridicos[item.id]);
            }else{
                this.$emit("visualizar", this.listaJuridicos[item.id]);
            }
        },
        paginar() {
            let sessaoUsuario = this.getSession();
            if(sessaoUsuario.usuarioUnimed.perfil.id !== -1){
                this.filtro.codigoUnimed = sessaoUsuario.usuarioUnimed.unimed.id
            }
            this.getListaJuridicos(this.getArgs());
        },
        pesquisar(valor) {
            this.pagina = 1
            const result = this.buscaJuridicos(valor);
            if (result == null) {
                this.limparFiltro()
            } else this.filtro[result] = valor;
            this.paginar();
        },
        filtrar(filtro) {
            this.pagina = 1
            this.filtro = { ...filtro };
            this.paginar();
        },
        limparFiltro() {
            this.filtro = {
                razaoSocial: "",
                codigoUnimed: "",
                loginUsuario: "",
                tipoConta: "",
                statusUsuario: "",
                numeroDocumento: ""
            };
            this.paginar();
        },
        getArgs() {
            return {
                direcaoOrdenacao: this.ordem,
                ordenacao: this.campoOrdenado,
                pagina: this.pagina,
                tamanhoPagina: this.itensPorPagina,
                loginUsuario: this.filtro.loginUsuario,
                codigoUnimed: this.filtro.codigoUnimed,
                tipoConta: this.filtro.tipoConta,
                statusUsuario: this.filtro.statusUsuario,
                razaoSocial: this.filtro.nome,
                numeroDocumento: this.filtro.numeroDocumento ? this.filtro.numeroDocumento.replace(/[^\d]/g, "") : ""
            };
        },
        mascaraCnpj(valor) {
            //eslint-disable-next-line
            return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");
        }
    }
};
</script>

<style lang="scss" scoped></style>
