var FiltroMixin = {
    methods: {
        pegaTextoDoItem(item) {
            if (!item) return null;
            if (this.textoDoItem) return item[this.textoDoItem];
            const keys = Object.keys(item);
            if (keys.length === 1) {
                return item[keys[0]];
            }
            return item;
        },

        filtro(item, queryText, textoDoItem) {
            const hasValue = val => (val != null ? val : "");

            const text = hasValue(this.normalizeText(textoDoItem));
            const query = hasValue(this.normalizeText(queryText));

            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            );
        },
        normalizeText(str) {
            return str
                .toString()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "");
        }
    }
};

export default FiltroMixin;
