<template>
    <div>
        <unicom-filtro
            titulo="FILTRAR ALTERAÇÕES"
            :value="mostrar"
            @fechar="fecharFiltro"
            @filtrar="filtrar"
            @limpar-filtro="limparFiltro"
        >
            <div slot="corpo-filtro">
                <unicom-linha :espacamento="false" justificarItem="start" class="fonte-secundaria bordaCampo">
                    <unicom-coluna :md="12" class="campoFiltro">
                        <unicom-autocomplete
                            v-model="filtroOperacao.operacaoModel"
                            :valorData="filtroOperacao.operacaoModel"
                            :items="listaOperacoes"
                            titulo="Operação"
                            textoDoItem="descricao"
                            placeholder="Todas operações"
                        />
                    </unicom-coluna>
                </unicom-linha>

                <unicom-linha :espacamento="false" justificarItem="start" class="fonte-secundaria bordaCampo">
                    <unicom-coluna :md="12" class="campoFiltro">
                        <unicom-autocomplete
                            v-model="filtroOperacao.unimedUsuarioModel"
                            :valorData="filtroOperacao.unimedUsuarioModel"
                            :items="listaUnimeds"
                            titulo="Unimed do usuário"
                            textoDoItem="codigoNome"
                            placeholder="Todas Unimeds"
                        />
                    </unicom-coluna>
                </unicom-linha>

                <h2 class="fonte-secundaria negrito camposDataHora">Data e hora</h2>
                <unicom-linha :espacamento="false" justificarItem="start" class="fonte-secundaria">
                    <unicom-coluna :md="5" style="margin-bottom:16px;">
                        <unicom-campo-calendario
                            tituloCampo="De"
                            v-model="filtroOperacao.dataInicial"
                            :right="330"
                            :bottom="330"
                            placeHolder="Insira a data"
                        />
                    </unicom-coluna>

                    <unicom-coluna :md="5" class="campoHora">
                        <unicom-campo-texto
                            v-model="filtroOperacao.horaInicial"
                            :mask="'##:##:##'"
                            titulo="Hora"
                            :apenasAlfanumerico="true"
                            placeholder="00:00:00"
                        />
                    </unicom-coluna>
                </unicom-linha>

                <unicom-linha :espacamento="false" justificarItem="start" class="fonte-secundaria bordaCampo">
                    <unicom-coluna :md="5" style="margin-bottom:24px;">
                        <unicom-campo-calendario
                            tituloCampo="Até"
                            v-model="filtroOperacao.dataFinal"
                            :right="330"
                            :bottom="250"
                            placeHolder="Insira a data"
                        />
                    </unicom-coluna>

                    <unicom-coluna :md="5" class="campoHora">
                        <unicom-campo-texto
                            v-model="filtroOperacao.horaFinal"
                            :mask="'##:##:##'"
                            titulo="Hora"
                            :apenasAlfanumerico="true"
                            placeholder="23:59:59"
                        />
                    </unicom-coluna>
                </unicom-linha>
            </div>
        </unicom-filtro>
    </div>
</template>

<script>
import ListaOperacoesServiceMixin from "@/mixins/services/dominios/ListaOperacoesServiceMixin";

export default {
    mixins: [ListaOperacoesServiceMixin],

    props: {
        value: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            mostrar: this.value,
            filtroOperacao: {
                operacaoModel: "",
                unimedUsuarioModel: "",
                operacao: "",
                unimedUsuario: "",
                dataInicial: "",
                horaInicial: "",
                dataFinal: "",
                horaFinal: ""
            },
            listaOperacoes: [],
            listaUnimeds: []
        };
    },

    beforeMount() {
        this.listarOperacoes();
        this.getAllUnimeds();
    },

    methods: {
        fecharFiltro() {
            this.$emit("fechar");
        },

        limparFiltro() {
            this.filtroOperacao = {
                operacaoModel: "",
                unimedUsuarioModel: "",
                operacao: "",
                unimedUsuario: "",
                dataInicial: "",
                horaInicial: "",
                dataFinal: "",
                horaFinal: ""
            };
            this.$emit("limpar-filtro");
        },

        montarFiltro() {
            let filtro = this.filtroOperacao;

            if (filtro.operacaoModel) {
                let operacao = this.listaOperacoes.find(operacao => operacao.descricao === filtro.operacaoModel).codigo;
                filtro.operacao = operacao;
            }

            if (filtro.unimedUsuarioModel) {
                let unimed = this.listaUnimeds.find(unimed => unimed.codigoNome === filtro.unimedUsuarioModel).codigo;
                filtro.unimedUsuario = unimed;
            }
            return filtro;
        },
        filtrar() {
            this.$emit("filtrar", this.montarFiltro());
        },

        getAllUnimeds() {
            this.listaUnimeds = this.getUnimedsList();
        },

        listarOperacoes() {
            this.loading = true;
            this.getListaOperacoesAdministrativas()
                .then(response => {
                    this.listaOperacoes = response.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },

    watch: {
        value() {
            this.mostrar = this.value;
        }
    }
};
</script>

<style lang="scss" scoped>
.campoFiltro {
    margin-top: 20px;
    margin-bottom: 24px;
}
.camposDataHora {
    margin-top: 20px;
    margin-bottom: 14px;
}
.bordaCampo {
    border-bottom: 1px solid #ebebeb;
}

.campoHora {
    margin-left: 40px;
}
</style>
