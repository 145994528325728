import Axios from "axios";

var AuditoriaServiceMixin = {
    methods: {
        getAuditoriaFiltro(args) {
            return Axios.get(
                this.CriarUrlAuditoria({
                    resource: "api/auditorias/filtra",
                    params: args
                }),
                { withCredentials: true }
            );
        },
        getTransacao(args) {
            return Axios.get(
                this.CriarUrlAuditoria({
                    resource: "api/auditorias/transacoes",
                    params: args
                }),
                { withCredentials: true }
            );
        },
        getArquivoDownload(args) {
            return Axios.get(
                this.CriarUrlAuditoria({
                    resource: "api/auditorias/baixarArquivo",
                    params: args
                }),
                { withCredentials: true, responseType: "blob" }
            );
        }
    }
};

export default AuditoriaServiceMixin;
