import { render, staticRenderFns } from "./ModalAnexo.vue?vue&type=template&id=1e2c1e88&scoped=true&"
import script from "./ModalAnexo.vue?vue&type=script&lang=js&"
export * from "./ModalAnexo.vue?vue&type=script&lang=js&"
import style0 from "./ModalAnexo.vue?vue&type=style&index=0&id=1e2c1e88&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e2c1e88",
  null
  
)

export default component.exports