<template>
    <div>
        <unicom-linha :espacamento="false" class="linha-cabecalho" justificarItem="center">
            <unicom-coluna col="12" sm="11" class="slot-cabecalho">
                <slot name="header"></slot>
            </unicom-coluna>
        </unicom-linha>

        <unicom-linha style="margin-top:8px;">
            <unicom-coluna :col="12">
                <div class="container-tabela-busca fonte-secundaria texto">
                    <table>
                        <thead @mouseover="hoverRow(-1)" @mouseleave="downRow">
                            <tr>
                                <th v-for="(coluna, index) in cabecalho" :key="index" :style="larguraColuna(index)">
                                    <div @click="ordenaTabela(index)">
                                        <unicom-checkbox
                                            v-model="checkboxTodos"
                                            checkSemLabel
                                            class="cabecalho-checkbox"
                                            v-if="index === 0"
                                            @click="selecionarTodos"
                                            :inativo="desativarCheckBox"
                                        />

                                        <div class="cabecalho-texto">{{ coluna.texto }}</div>

                                        <div
                                            v-if="coluna.icone"
                                            class="cabecalho-icone"
                                            @click="$emit('ordenar', index)"
                                        >
                                            <unicom-icone class="icone" :nome="coluna.icone" :tamanho="10" />
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>

                        <tbody v-if="!carregamento">
                            <template v-if="estruturaPadrao">
                                <tr
                                    v-for="(row, index) in rows"
                                    :key="index"
                                    @mouseover="hoverRow(index)"
                                    @mouseleave="downRow"
                                    :name="'tabela_' + index"
                                    :style="estiloLinhaTabela(index)"
                                    v-show="row.naoMostrar.mostrar"
                                >
                                    <td v-for="(col, indexRow) in rowAtual(index)" :key="indexRow">
                                        <div class="corpo-texto-customizavel coluna-texto" v-if="verificarColuna(col)">
                                            {{ col }}
                                        </div>

                                        <div v-else-if="col.texto">
                                            <div v-if="col.checkbox">
                                                <unicom-linha :espacamento="false">
                                                    <unicom-coluna md="1" sm="auto" alinhamento="center">
                                                        <unicom-checkbox
                                                            :checked="checkBoxSelecionado"
                                                            @input="arr => selecionarCheck(arr, index)"
                                                            :value="escolheIndex(index)"
                                                            checkSemLabel
                                                        />
                                                    </unicom-coluna>

                                                    <unicom-coluna
                                                        style="padding-left: 8px; white-space: nowrap;"
                                                        md="5"
                                                    >
                                                        <div
                                                            class="coluna-texto-tooltip"
                                                            style="padding-left: 8px; white-space: nowrap;"
                                                            v-if="
                                                                !col.qtdMaxCaracteres ||
                                                                    col.texto.length <= col.qtdMaxCaracteres
                                                            "
                                                        >
                                                            {{ col.texto }}
                                                        </div>

                                                        <unicom-tooltip
                                                            class="coluna-texto"
                                                            style="padding-left: 8px; white-space: nowrap;"
                                                            v-else
                                                            :texto="substringValores(col.texto, col.qtdMaxCaracteres)"
                                                            :textoTooltip="col.texto"
                                                            tooltipLocalizacao="right-bottom"
                                                        />
                                                    </unicom-coluna>
                                                </unicom-linha>
                                            </div>
                                            <unicom-tooltip
                                                class="coluna-texto"
                                                v-else
                                                :texto="substringValores(col.texto, col.qtdMaxCaracteres)"
                                                :textoTooltip="col.texto"
                                                tooltipLocalizacao="right-bottom"
                                            />
                                        </div>
                                    </td>

                                    <td
                                        v-if="icones.length > 0"
                                        style="text-align:center; vertical-align:middle; padding-right:20px"
                                    >
                                        <unicom-tooltip
                                            v-for="(icone, indexIcone) in icones"
                                            :key="indexIcone"
                                            :textoTooltip="icone.tooltipNome"
                                            tooltipLocalizacao="top"
                                            :visualizando="true"
                                        >
                                            <div slot="itemTooltip">
                                                <div class="icone-acao" @click="acaoIcone(index, indexIcone)">
                                                    <unicom-icone :nome="icone.nome" :tamanho="15" />
                                                </div>
                                            </div>
                                        </unicom-tooltip>
                                        <ModalPapeis
                                            v-model="mostrarModal"
                                            @fechar="fecharModal"
                                            v-if="index === indexModal"
                                        />
                                    </td>
                                </tr>
                            </template>

                            <template v-else>
                                <slot name="rows"></slot>
                            </template>
                        </tbody>

                        <div class="aplicacoes">
                            <p class="negrito fonte-secundaria">
                                Aplicações selecionadas:
                                <a class="selecionadas">{{ checkBoxSelecionadoLength }}/{{ mostrandoLength }}</a>
                            </p>
                        </div>
                    </table>
                </div>

                <unicom-loading v-model="carregamento" style="margin-top:15px;" mensagem="Carregando dados..." />
            </unicom-coluna>
        </unicom-linha>
    </div>
</template>

<script>
import ModalPapeis from "../ModalPapeis";

export default {
    components: {
        ModalPapeis
    },

    props: {
        estruturaPadrao: {
            type: Boolean,
            required: false,
            default: true,
            note: "Define a estrutura se será padrão ou por slot"
        },
        qtResultados: {
            type: [String, Number],
            required: false,
            default: 0,
            note: "Define quantos itens estão sendo exibidos na página"
        },
        ordem: {
            type: String,
            required: false,
            default: "asc",
            note: "Define se a ordenação será crescente ou decrescente"
        },
        icones: {
            type: [Array, Object],
            required: false,
            default: () => [],
            note: "Define os ícones de ação"
        },
        cabecalho: {
            type: Array,
            required: true,
            note: "Define as colunas do cabecalho"
        },
        rows: {
            type: Array,
            required: false,
            note: "Define o conteúdo da tabela"
        },
        carregamento: {
            type: Boolean,
            default: false,
            required: false,
            note: "Valor v-model do loading"
        },
        checkSelecionadosIndex: {
            type: Boolean,
            default: true,
            required: false,
            note: "Check selecionados vim com index"
        },
        valoresSelecionados: {
            type: Array,
            default: () => [],
            required: false
        },
        desativarCheckBox: {
            type: Boolean,
            default: false,
            required: false,
            note: "Check desativados"
        },
        valorCheckboxTodos: {
            type: Boolean,
            default: false,
            required: false,
            note: "Valor checkbox para selecionar todos"
        },
        novoIndex: {
            type: String,
            default: null
        },
        rowsSemFiltro: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            mostrarModal: false,
            indexModal: null,
            paginaAtual: this.pagina,
            selectAll: false,
            selected: [],
            mostrarBotao: false,
            hoverIndex: -1,
            checkboxTodos: this.valorCheckboxTodos,
            checkBoxSelecionado: this.valoresSelecionados,
            checkBoxSelecionadoModal: []
        };
    },
    computed: {
        mostrandoLength() {
            return this.rows.length < 10 ? `0${this.rows.length}` : this.rows.length;
        },

        checkBoxSelecionadoLength() {
            if (this.valorCheckboxTodos) return this.rows.length < 10 ? `0${this.rows.length}` : this.rows.length;
            return this.checkBoxSelecionado.length < 10
                ? `0${this.checkBoxSelecionado.length}`
                : this.checkBoxSelecionado.length;
        }
    },

    methods: {
        escolheIndex(index) {
            return this.novoIndex !== null ? this.rows[index].naoMostrar[this.novoIndex] : index;
        },
        rowAtual(index) {
            let arrObj = this.resetArray(Object.values(this.rows[index]));
            return arrObj.filter(el => {
                return !el.naoMostrar;
            });
        },
        resetArray: function(arr) {
            for (var i = 0, l = arr.length; i < l; i++) {
                if (arr[i] === undefined) arr[i] = " - ";
            }
            return arr;
        },
        larguraColuna(index) {
            return this.cabecalho[index].largura ? `width: ${this.cabecalho[index].largura}` : "";
        },
        estiloLinhaTabela(i) {
            let checkLinha = this.checkBoxSelecionado.includes(
                this.checkSelecionadosIndex ? parseInt(i) : this.rows[i].naoMostrar.id
            );
            return {
                backgroundColor: checkLinha ? "#F1F2F1" : ""
            };
        },
        ordenaTabela(index) {
            if (this.cabecalho[index].hasOwnProperty("icone")) {
                let ordenacao = this.ordem === "asc" ? "desc" : "asc";
                this.$emit("ordena", { index: index, ordem: ordenacao });
                this.$emit("indexHeader", index);
            }
        },
        hoverRow(index) {
            this.mostrarBotao = true;
            this.hoverIndex = index;
        },
        downRow() {
            this.hoverIndex = -100;
            this.mostrarBotao = false;
        },
        acaoIcone(index, indexIcone) {
            this.indexModal = index;
            this.mostrarModal = true;
            this.$emit("acao", { id: index, acao: indexIcone });
        },
        fecharModal() {
            this.indexModal = 0;
            this.mostrarModal = false;
        },
        verificarColuna(col) {
            return typeof col === "object" ? false : true;
        },

        selecionarTodos() {
            if (!this.checkboxTodos) {
                for (let i in this.rows) {
                    if (
                        !this.verificaIndexOuIdTemCheckBoxSelecionado(
                            this.checkSelecionadosIndex ? parseInt(i) : this.rows[i].naoMostrar.id
                        )
                    ) {
                        this.checkBoxSelecionado.push(
                            this.checkSelecionadosIndex ? parseInt(i) : this.rows[i].naoMostrar.id
                        );
                    }
                }
            } else {
                this.checkBoxSelecionado = [];
            }
            this.$emit("aplicacoes-selecionadas", this.checkBoxSelecionado);
        },
        selecionarCheck(arr) {
            this.checkBoxSelecionado = [...arr];
            this.checkboxTodos = this.checkBoxSelecionado === this.rowsSemFiltro.length;
            this.$emit("aplicacoes-selecionadas", arr);
        },
        verificaIndexOuIdTemCheckBoxSelecionado(item) {
            return this.checkBoxSelecionado.includes(item);
        },

        substringValores(texto, qntCaracter) {
            return `${texto.substring(0, qntCaracter)}...`;
        }
    }
};
</script>

<style lang="scss">
.linha-cabecalho-busca {
    height: 40px;
    color: #60656c;
    margin-bottom: 8px;

    & small {
        display: block;
        margin: 11px 0 11px 11px;
        padding-left: 10px;
        padding-top: 3px;

        .select-itens {
            width: 38px;
            color: #00995d;
        }
    }

    & .slot-cabecalho {
        box-sizing: border-box;
        height: 40px;
    }
}

.corpo-texto-customizavel {
    width: 500px;
}

.container-tabela-busca {
    margin: 0 10px;
    box-sizing: border-box;
    width: calc(100% - 20px);
    color: #60656c;
    border: 1px solid #ebebeb;
    border-radius: 1%;

    & table {
        width: 100%;

        & thead,
        tbody {
            display: block;
        }

        & tbody {
            overflow-y: scroll;
            height: 252px;
        }

        & .aplicacoes {
            padding-top: 10px;
            padding-left: 12px;
            height: 26px;
            border-top: 1px solid #ebebeb;

            & .selecionadas {
                color: #00995d;
            }
        }

        th {
            box-sizing: border-box;
            height: 40px;
            padding-top: 6px;
            padding-left: 10px;
            border-bottom: 2px solid #ebebeb;
            text-align: left;

            & .cabecalho-texto {
                font-display: block;
                float: left;
                box-sizing: border-box;
                padding-top: 4px;
            }
            & .cabecalho-checkbox {
                font-display: block;
                box-sizing: border-box;
                display: block;
                padding-right: 8px;
                float: left;
            }

            & .cabecalho-icone {
                font-display: block;
                float: left;
                box-sizing: border-box;
                padding-top: 4px;

                .icone {
                    padding: 0 5px;
                    cursor: pointer;
                    transform: rotate(135deg);
                }
            }
        }

        td {
            box-sizing: border-box;
            height: 40px;
            padding-top: 6px;
            padding-left: 10px;
            border-bottom: 1px solid #ebebeb;
            text-align: left;
            color: #60656c;
            width: 1%;

            .coluna-texto {
                padding-top: 10px;
                display: inline-block;
                vertical-align: middle;
            }

            .coluna-texto-tooltip {
                padding-top: 10px;
                display: inline-block;
                vertical-align: middle;
            }

            .corpo-status {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #cccccc;

                &.verdadeiro {
                    background-color: #00995d;
                }

                &.falso {
                    background-color: #ed1651;
                }
            }

            .icone-acao {
                display: block;
                box-sizing: border-box;
                width: 26px;
                height: 26px;
                border: 2px solid #dee2e6;
                border-radius: 20px;
                color: #dee2e6;
                padding: 3px 4px;
                cursor: pointer;

                &:hover,
                &:active {
                    border-color: #00995d;
                    color: #00995d;
                }
            }
        }
    }
}

.sem-registro-busca {
    margin: 20px 0;
    color: #60656c;
    text-align: center;
}

.linha-rodape-busca {
    height: 40px;
    color: #60656c;
    margin-top: 10px;

    & .coluna-exibicao {
        height: 40px;

        & small {
            display: block;
            margin-top: 10px;
            padding-left: 20px;
        }
    }

    & .coluna-paginacao {
        height: 40px;
        padding-right: 11px;

        .linha-paginacao {
            display: block;
            float: right;

            .botao-paginacao {
                display: block;
                float: left;
                box-sizing: border-box;
                width: 30px;
                height: 30px;
                margin: 2.5px;
                border: 1px solid #bebebe;
                border-radius: 8px;
                padding-top: 2px;
                padding-right: 2px;
                background-color: #ffffff;
                color: #bebebe;
                cursor: pointer;
                text-align: center;

                & small {
                    display: inline-block;
                    margin-top: 4px;
                    margin-left: 1px;
                }

                &:disabled {
                    border: 1px solid #dee2e6;
                    color: #dee2e6;
                    cursor: not-allowed;
                }

                &.ativo {
                    color: #fff;
                    background-color: #00995d;
                    border: 1px solid #00995d;
                }
            }
        }
    }
}
</style>
