<template>
    <div :style="customStyle">
        <div
            class="negrito fonte-secundaria cartao"
            :class="{ hoverCartao: !mostrarCard }"
            @click="mostrarCard = !mostrarCard"
        >
            <unicom-icone :nome="mostrarCard ? 'chevron-down' : 'chevron-up'" :tamanho="12" textoComIcone />
            <a>{{ textoHeaderPrimeiraLetraMaiscula }}</a>
        </div>

        <div class="textoCartao fonte-secundaria" v-if="mostrarCard">
            {{ texto }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        textoHeader: {
            type: String,
            required: true,
            note: "Define a texto da header do card"
        },
        largura: {
            type: Number,
            required: false,
            default: 500,
            note: "Define a largura da modal"
        },
        texto: {
            type: String,
            required: false,
            note: "Define a texto do card"
        }
    },

    data() {
        return {
            mostrarCard: false
        };
    },

    computed: {
        customStyle() {
            return {
                width: this.largura + "px"
            };
        },

        textoHeaderPrimeiraLetraMaiscula() {
            return this.textoHeader.charAt(0).toUpperCase() + this.textoHeader.slice(1).toLowerCase();
        }
    }
};
</script>

<style lang="scss" scoped>
a {
    margin-left: 5px;
}
.cartao {
    background-color: #fff;
    border: 1px solid #f1f2f1;
    height: 14px;
    border-radius: 5px;
    padding-bottom: 18px;
    padding-top: 18px;
    padding-left: 24px;
    cursor: pointer;
}

.hoverCartao {
    &:hover,
    &:active {
        background-color: #00995d;
        color: #fff;
    }
}

.textoCartao {
    background: #f1f2f1 0% 0% no-repeat padding-box;
    max-height: 260px;
    border-radius: 0px 0px 5px 5px;
    opacity: 1;
    padding: 24px;
    overflow: auto;
    word-break: break-all;
}

.inline {
    display: inline;
}
</style>
