<template>
    <div>
        <unicom-linha espacamento>
            <unicom-coluna>
                <h3 class="fonte-primaria titulo negrito">
                    DADOS BÁSICOS <span class="texto">(BASE DE PRESTADORES)</span>
                </h3>
            </unicom-coluna>
        </unicom-linha>
        <unicom-linha :espacamento="false" class="linha-conteudo">
            <unicom-coluna :md="4">
                <div class="container-imagem">
                    <p class="fonte-secundario corSecundaria textoImagem negrito">IMAGEM DO USUÁRIO</p>
                    <div class="center">
                        <div class="perfil">
                            <figure>
                                <img class="imagem-perfil" :src="foto" v-if="foto" />
                                <img class="imagem-perfil" src="../../../../../public/assets/img-vazia.png" v-else />
                                <div class="incluirFoto" v-if="!somenteVisualizar">
                                    <input
                                        type="file"
                                        @input="inserirFoto($event)"
                                        accept=".jpg, .jpeg, .png"
                                        class="input-text"
                                        :value="inputArquivo"
                                    />
                                    <unicom-icone nome="camera" :tamanho="18" style="color:white;" />
                                    <div class="texto fonte-secundaria">enviar foto</div>
                                </div>
                            </figure>
                        </div>
                    </div>
                    <p class="fonte-secundario removerFoto" v-if="isFoto" @click="removerFoto">remover foto</p>
                </div>
            </unicom-coluna>
            <unicom-coluna :md="8">
                <ValidationObserver ref="observer">
                    <unicom-linha class="linha-dados primeira-linha">
                        <unicom-coluna :md="5" v-if="isColaborador">
                            <unicom-campo-texto
                                v-model="dadosUsuario.nome"
                                :titulo="'Nome completo'"
                                :placeholder="'Insira o nome do colaborador'"
                                :desativado="disableFields()"
                            />
                        </unicom-coluna>
                        <unicom-coluna :md="5" v-else>
                            <unicom-campo-texto
                                v-model="dadosUsuario.razaoSocial"
                                :titulo="'Nome completo'"
                                :placeholder="'Insira o nome da pessoa júrifica'"
                                :desativado="disableFields()"
                            />
                        </unicom-coluna>
                        <unicom-coluna :md="4">
                            <unicom-campo-texto
                                v-model="dadosUsuario.documento.numeroDocumento"
                                :titulo="'Documento do usuário'"
                                :mask="['A######-A', '###.###.###-##', '##.###.###/####-##']"
                                :placeholder="'Insira o documento do colaborador'"
                                :desativado="true"
                            />
                        </unicom-coluna>
                        <unicom-coluna :md="3">
                            <div class="fonte-secundaria texto label-status">
                                Status da conta
                            </div>
                            <div class="fonte-secundaria texto campo-status">
                                <div v-if="isDiffPendente() && !somenteVisualizar">
                                    <unicom-switch
                                        :value="getStatus(dadosUsuario.status)"
                                        @input="inputStatus()"
                                        textoVerdadeiro="ATIVA"
                                        textoFalso="INATIVA"
                                    />
                                </div>
                                <div v-else>
                                    <div :class="`status ${classStatusUsuario(dadosUsuario.status)}`" />
                                    <span>{{ dadosUsuario.status }}</span>
                                </div>
                            </div>
                        </unicom-coluna>
                    </unicom-linha>
                    <unicom-linha class="linha-dados">
                        <unicom-coluna :md="6">
                            <unicom-campo-texto
                                v-model="dadosUsuario.email"
                                :titulo="'Email'"
                                :placeholder="'Insira o email do colaborador'"
                                :desativado="somenteVisualizar"
                            />
                        </unicom-coluna>
                        <unicom-coluna :md="3">
                            <validation-provider rules="required|telRequired" mode="eager" v-slot="{ errors }">
                                <unicom-campo-texto
                                    v-model="dadosUsuario.telefone"
                                    :titulo="'Telefone'"
                                    :mask="['(##) ####-####', '(##) #####-####']"
                                    :placeholder="'Insira o telefone do colaborador'"
                                    :desativado="somenteVisualizar"
                                    :mensagemErro="errors[0]"
                                />
                            </validation-provider>
                        </unicom-coluna>
                    </unicom-linha>
                    <unicom-linha espacamento class="linha-dados" v-if="isColaborador">
                        <unicom-coluna :md="3">
                            <unicom-campo-texto
                                v-model="dadosUsuario.dataNascimento"
                                :titulo="'Data de nascimento'"
                                :mask="['##/##/####']"
                                :placeholder="'Insira a data de nascimento do colaborador'"
                                :desativado="disableFields()"
                            />
                        </unicom-coluna>
                        <unicom-coluna :md="5">
                            <unicom-linha :espacamento="false">
                                <unicom-coluna>
                                    <p class="fonte-secundaria texto">Gênero do usuário</p>
                                </unicom-coluna>
                                <unicom-coluna :md="6" class="genero-checkbox">
                                    <unicom-checkbox
                                        v-model="genero"
                                        :texto="'Masculino'"
                                        unico
                                        :inativo="disableFields()"
                                        value="M"
                                        :arredondado="true"
                                    />
                                </unicom-coluna>
                                <unicom-coluna :md="6" class="genero-checkbox">
                                    <unicom-checkbox
                                        v-model="genero"
                                        :texto="'Feminino'"
                                        unico
                                        :inativo="disableFields()"
                                        value="F"
                                        :arredondado="true"
                                    />
                                </unicom-coluna>
                            </unicom-linha>
                        </unicom-coluna>
                    </unicom-linha>
                </ValidationObserver>
            </unicom-coluna>
        </unicom-linha>

        <unicom-modal-loading v-model="carregamento" />
    </div>
</template>

<script>
import ColaboradorServiceMixin from "../../../../mixins/services/gerenciarUsuarios/ColaboradorServiceMixin";
import PessoaJuridicaServiceMixin from "../services/PessoaJuridicaMixinService";
import UsuarioServiceMixin from "../../../../mixins/services/usuario/UsuarioServiceMixin";

import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [ColaboradorServiceMixin, PessoaJuridicaServiceMixin, UsuarioServiceMixin],

    props: {
        value: {
            type: Object,
            required: true
        },
        isColaborador: {
            type: Boolean,
            required: true,
            default: false
        },
        somenteVisualizar: {
            type: Boolean, 
            required: false,
            default: false
        }
    },

    data() {
        return {
            usuarioLogado: this.getSession(),
            dadosUsuario: this.value,
            carregamento: false,
            usuario: {},
            genero: [this.value.genero],
            status: null,

            foto: null,
            inputArquivo: null,
            arquivo: null
        };
    },

    async beforeMount() {
        await this.buscarFotoUsuario();
    },

    methods: {
        disableFields() {
            return this.usuarioLogado.usuarioUnimed.perfil.id !== -1 
        },
        /** Status Usuário */
        isDiffPendente() {
            return this.dadosUsuario.status !== "PENDENTE";
        },
        getStatus(status) {
            return status === "ATIVO";
        },
        inputStatus() {
            this.dadosUsuario.status = this.dadosUsuario.status === "ATIVO" ? "INATIVA" : "ATIVO";
        },
        classStatusUsuario(status) {
            return status === "ATIVO" ? 'ativo' : status === "INATIVO" ? "inativo" : "pendente";
        },

        /** Foto Usuário */
        buscarFotoUsuario() {
            if (this.dadosUsuario.hasOwnProperty("arquivoResponse")) {
                this.carregamento = true;
                this.getFoto(this.dadosUsuario.arquivoResponse.uuid)
                    .then(response => {
                        let reader = new FileReader();
                        reader.readAsDataURL(response.data);
                        reader.onload = () => {
                            reader.result;
                            this.foto = reader.result;
                        };
                    })
                    .finally(() => {
                        this.carregamento = false;
                    });
            }
        },
        async inserirFoto(e) {
            this.inputArquivo = null;
            this.arquivo = undefined;

            this.foto = null;
            let files = e.target.files || e.dataTransfer.files;
            this.arquivo = files[0];

            if (
                this.arquivo.type === "image/jpg" ||
                this.arquivo.type === "image/png" ||
                this.arquivo.type === "image/jpeg"
            ) {
                this.adicionarFoto();
            }
        },
        async adicionarFoto() {
            await this.removerFoto();
            await this.adicionarFotoUsuario(this.arquivo);
        },
        adicionarFotoUsuario(arquivo) {
            this.carregamento = true;
            this.patchAdicionarFoto(this.dadosUsuario.id, arquivo)
                .then(response => {
                    if (response.status === 200) {
                        this.foto = URL.createObjectURL(arquivo);
                        if(this.dadosUsuario.id === this.getSession().userId){
                            this.$emit('atualizar')
                        }
                    }
                })
                .finally(() => {
                    this.carregamento = false;
                });
        },
        removerFoto() {
            this.deletarFotoUsuario();
        },
        deletarFotoUsuario() {
            this.carregamento = true;
            this.deleteFoto(this.dadosUsuario.id)
                .then(response => {
                    if (response.status === 200) {
                        this.foto = null;
                        if(this.dadosUsuario.id === this.getSession().userId){
                            this.$emit('atualizar')
                        }
                    }
                })
                .finally(() => {
                    this.carregamento = false;
                });
        }
    },

    computed: {
        isFoto() {
            return this.foto !== null;
        }
    },

    watch: {
        value() {
            this.dadosUsuario = this.value;
            this.$emit("input", this.dadosUsuario);
        },
        genero() {
            this.dadosUsuario.genero = this.genero[0];
        }
    }
};
</script>

<style lang="scss" scoped>
h3 {
    margin-left: 12px;
    color: #00995d;

    span {
        color: #60656c;
    }
}

.corSecundaria {
    color: #00995d;
}

.perfil {
    figure {
        position: relative;
        width: 160px;
        height: 160px;
        border-radius: 50%;
        &:hover {
            background: #000000;
            & .incluirFoto {
                opacity: 1;
            }
        }
    }

    & .imagem-perfil {
        height: 100%;
        width: 100%;
        object-fit: cover;
        background-color: #dee2e6;
        border-radius: 50%;
        opacity: 1;
        transition: 0.5s ease;
        backface-visibility: hidden;
    }

    & .incluirFoto {
        transition: 0.5s ease;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
        cursor: pointer;
    }

    & .input-text {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        &:hover {
            cursor: pointer;
        }
    }

    & .texto {
        color: white;
    }
}

.perfil figure img {
    opacity: 1;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}
.perfil figure:hover img {
    opacity: 0.6;
}

.removerFoto {
    text-align: center;
    color: #1492e6;
    margin-top: 12px;
    cursor: pointer;
}

.textoImagem {
    margin-top: 16px;
    font-size: 12px;
    text-align: center;
    margin-bottom: 16px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.linha-conteudo {
    margin-top: 24px;

    .container-imagem {
        width: 100%;
        height: 268px;
        border: 1px solid #f1f2f1;
        border-radius: 8px;
        background-color: #f1f2f1;
    }

    .linha-dados {
        height: 90px;
        padding-top: 30px;
        padding-left: 12px;
        padding-bottom: 0;
        margin-left: 0px;

        &.primeira-linha {
            padding-top: 0;
            padding-bottom: 30px;
            border-bottom: 1px solid #dee2e6;
        }

        .label-status {
            box-sizing: border-box;
            padding-bottom: 6px;
            height: 24px;
        }

        .campo-status {
            box-sizing: border-box;
            padding-top: 8px;
            height: 35px;

            .status {
                float: left;
                margin-top: 3px;
                margin-right: 8px;
                width: 12px;
                height: 12px;
                border-radius: 20px;
                background-color: #cccccc;

                &.ativo {
                    background-color: #00995d;
                }

                &.inativo {
                    background-color: #ed1651;
                }

                &.pendente {
                    background-color: #cccccc;
                }
            }
        }

        .genero-checkbox {
            margin-top: 12px;
        }
    }
}
</style>
